<template>
  <div class="presets">
    <div @click.prevent="openModal" ref="trigger" v-if="$slots.trigger">
      <slot name="trigger"></slot>
    </div>
    <button v-else @click.prevent="openModal" class="btn btn-go">Presets</button>

    <modal-dialog :show="showModal" @close="showModal = false">
      <p>Presets are blocks filled with the content you currently have in the selected block.</p>
      <div class="flex items-end justify-between mb-4">
        <rw-text
          class="w-full mr-4"
          v-model="presetTitle"
          placeholder="Example: Variant 1"
          label="Preset"
        />
        <button class="flex-none btn btn-go" @click.prevent="savePreset">Save New Preset</button>
      </div>

      <hr class="divider" />

      <div class="w-full">
        <label class="block">
          Filter
          <span class="capitalize font-bold">{{ block.blockType }}</span> presets by title:
        </label>
        <input class="border p-1 w-full" v-model="filters.title.value" />
      </div>

      <VTable
        v-if="!$apollo.queries.blockPresets.loading"
        class="bare striped"
        :data="blockPresets"
        :filters="filters"
      >
        <template #head>
          <VTh sortKey="title" defaultSort="asc" class="w-full">Name</VTh>
          <th v-if="$can('delete', 'BlockPreset')" class="w-1">Delete</th>
        </template>
        <template #body="{ rows }">
          <tr v-for="preset in rows" :key="preset.id">
            <td>
              <button @click.prevent="loadPreset(preset)">{{ preset.title }}</button>
            </td>
            <td v-if="$can('delete', 'BlockPreset')">
              <delete-item
                v-if="$can('delete', preset)"
                :mutation="deleteItem"
                :id="preset.id"
                @item-deleted="refreshTable"
              />
            </td>
          </tr>
        </template>
      </VTable>
    </modal-dialog>
  </div>
</template>

<script>
import { BLOCK_PRESETS, SAVE_BLOCK_PRESET, DELETE_BLOCK_PRESET } from '@/graphql/blocks'

import ModalDialog from '@/components/utils/ModalDialog'
let uniqid = require('uniqid')

export default {
  name: 'BlockSaver',
  props: ['block'],
  components: { ModalDialog },
  data() {
    return {
      deleteItem: DELETE_BLOCK_PRESET,
      filters: {
        title: { value: '', keys: ['title'] },
      },
      showModal: false,
      presetTitle: '',
    }
  },
  apollo: {
    blockPresets: {
      query: BLOCK_PRESETS,
      variables() {
        return {
          blockType: this.block.blockType,
        }
      },
    },
  },
  methods: {
    refreshTable() {
      this.$apollo.queries.blockPresets.refetch()
    },
    openModal() {
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },
    loadPreset(preset) {
      console.log('blocksaver.vue loadPreset')
      this.$emit('load-block', preset)
    },
    savePreset() {
      let preset = Object.assign({}, this.block, { _id: uniqid() })
      // delete preset stuff in case we're "forking" a preset
      delete preset.blockBaseId
      delete preset.override
      this.$apollo
        .mutate({
          mutation: SAVE_BLOCK_PRESET,
          variables: {
            input: {
              title: this.presetTitle,
              block_type: preset.blockType,
              content: preset,
            },
          },
        })
        .then(data => {
          this.refreshTable()
          this.presetTitle = ''
        })
        .catch(error => {
          this.error = error.message
        })
    },
  },
}
</script>