<template>
  <div>
    <draggable :list="people" item-key="id" :group="{ name: 'people' }" handle=".person-handle" @end="peopleOrder">
      <template #item="{ element, index }">
        <div class="mb-4 pb-6 border-b border-gray-400 flex items-start">
          {{ index }}
          <fa-icon v-if="editable" class="person-handle" icon="grip-vertical" />
          <person :editable="editable" class="flex-1 mx-4" v-model="people[index]" />
          <button v-if="deletable" @click="deletePerson(element, index)">
            <fa-icon icon="minus-circle">Trash</fa-icon>
          </button>
        </div>
      </template>
      <template #footer>
        <button v-if="editable" class="btn" @click="addPerson">Add Person</button>
      </template>
    </draggable>
  </div>
</template>

<script>
export default {
  name: 'PeopleForm',
  props: ['modelValue', 'peopleToDelete', 'editable', 'deletable'],
  computed: {
    people() {
      return this.modelValue || []
    },
  },
  watch: {
    people: {
      deep: true,
      handler(newVal) {
        newVal.forEach((element, idx) => {
          element.order = idx
        })
        this.$emit('update:modelValue', newVal)
      },
    },
  },
  methods: {
    deletePerson(person, idx) {
      person.id ? this.peopleToDelete.push(person.id) : null
      this.people.splice(idx, 1)
    },
    peopleOrder(evt) {
      this.people.forEach((element, idx) => {
        element.order = idx
      })
    },
    addPerson() {
      const person = {
        first_name: '',
        last_name: '',
        prefix: '',
        suffix: '',
        job_title: '',
        short_bio: '',
        full_bio: [],
        image: {},
        social_media: [],
        contact: [],
        order: this.people.length,
      }
      this.people.push(person)
    },
  },
}
</script>

<style>
</style>