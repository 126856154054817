<template>
  <AdvancedImage v-if="hasImage" :cldImg="imageUrl" :width="getWidth" :height="getHeight" />
</template>

<script>
// For my sanity later. URL for all the transformations: https://cloudinary.com/documentation/javascript_image_transformations

import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage } from '@cloudinary/vue';
import {fill, fit, pad, scale} from "@cloudinary/url-gen/actions/resize";

export default {
  name: 'CloudinaryImage',
  components: {AdvancedImage},
  props: {
    publicId: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    crop: {
      type: String,
      default: 'fill'
    },
    transforms: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      cld: new Cloudinary({
        cloud: { cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD }
      }),
    };
  },
  computed: {
    hasImage() {
      return (this.publicId == '' || this.publicId == null) ? false : true;
    },
    imageUrl() {
      if (this.hasImage) {
        const img = this.cld.image(this.publicId);

        if (this.transforms.length) {
          this.transforms.forEach((tran) => {
            img.resize(this.getTheChain(tran.crop, tran.width, tran.height));
          });
        } else {
          img.resize(this.getTheChain(this.crop, this.width, this.height));
        }

        return img;
      }

      return '';
    },
    getWidth() {
      if (this.width) {
        return this.width;
      } else if (this.transforms.length && this.transforms[0].width) {
        return this.transforms[0].width;
      } else {
        return 384;
      }
    },
    getHeight() {
      if (this.height) {
        return this.height;
      } else if (this.transforms.length && this.transforms[0].height) {
        return this.transforms[0].height;
      } else {
        return 384;
      }
    },
  }, 
  methods: {
    /**
     * Note from Magical Unicorn Lady. 
     * With the Vue 3 upgrade, Cloudinary totally Forged up their SDK. 
     * Transformations are now methods (wtf...literally). I couldn't figure
     * out the best way to handle the logic with optional props and chaining
     * all the methods. I'd LOVE any suggestions other than one massive #$)#(#% 
     * if statement. 
     */
    getTheChain(crop, width = null, height = null) {
      if (width && height) { // All the options.
          return this.getCropped(crop).width(width).height(height);
        } else if (this.width) {
          return this.getCropped(crop).width(width);
        } else if (this.height) {
          return this.getCropped(crop).height(height)
        } else {
          return this.getCropped();
        }
    },
    // Return the right cropped method based on the crop.. Stupid SDK update.
    getCropped(crop) {
      let method;
      switch (crop) {
        case 'pad':
          method = pad();
          break;
        case 'fill':
          method = fill();
          break;
        case 'fit':
          method = fit();
          break;
        default: 
          // Just a catch in case they put in a wrong one.
          method = scale();
      }

      return method;
    }
  } 
}
</script>