<template>
  <popover class="block-picker" ref="popper" placement="auto">
    <template #trigger>
      <button slot="trigger" class="add-block w-8 h-8 -mt-4">
        <fa-icon icon="plus" />
        <span class="sr-only">Add Block</span>
      </button>
    </template>
    <p class="border-b p-2 pb-1 font-bold text-lg mt-0 mb-0 text-center">Add Content</p>
    <div v-if="sectionTypes.length">
      <p class="font-bold bg-gray-200 px-3 py-1">Sections</p>
      <div class="p-3 flex flex-wrap items-center">
        <button
          v-for="(section, name) in sectionTypes"
          :key="name"
          class="block-item w-1/5 flex-none"
          @click.prevent="addSection(section.blockType)"
        >
          <div class="block-item-inner">
            <fa-icon v-if="section.faIcon" :icon="section.faIcon" size="lg" />
            <img v-show="section.blockIcon" :src="section.blockIcon" class="w-12 h-12 mx-auto" />
            <span class="block text-sm">{{ section.blockName }}</span>
          </div>
        </button>
      </div>
    </div>
    <p class="font-bold bg-gray-200 px-3 py-1">Blocks</p>
    <div class="p-3 flex flex-wrap items-center">
      <button
        class="block-item w-1/5 flex-none"
        v-for="(block, name) in blockTypes"
        :key="name"
        @click.prevent="addBlock(name)"
      >
        <div class="block-item-inner">
          <fa-icon v-if="block.faIcon" :icon="block.faIcon" size="lg" />
          <img v-show="block.blockIcon" :src="block.blockIcon" class="w-12 h-12 mx-auto" />
          <span class="block text-sm">{{ block.blockName }}</span>
        </div>
      </button>
    </div>
  </popover>
</template>

<script>
let uniqid = require('uniqid')
import cloneDeep from 'lodash/cloneDeep'
import { blocks, simpleBlocks, productBlocks, contentBlocks, container } from '@/settings/blocks'
import omit from 'lodash/omit'

const sectionTypes = {
  productSection: {
    blockType: 'productSection',
    blockName: 'Section',
    blockWidth: null,
    blockLayout: 'flex',
    faIcon: 'cubes',
    children: [],
  },
  contentSection: {
    blockType: 'contentSection',
    blockName: 'Content Section',
    faIcon: 'cubes',
    children: [],
  },
  contentContainer: {
    ...container,
    blockType: 'contentContainer',
    faIcon: 'cubes',
  },
}

export default {
  name: 'ContentBlockPicker',
  props: {
    contentType: String,
    blockOptions: {
      type: String,
      default: 'simpleBlocks',
    },
    sectionOptions: {
      type: String,
      default: 'simpleBlocks',
    },
  },
  computed: {
    sectionTypes() {
      if (this.contentType == 'product' || this.sectionOptions == 'productSections') {
        return [sectionTypes.productSection]
      }
      if (this.sectionOptions == 'contentContainer') {
        return [sectionTypes.contentContainer]
      }
      if (this.sectionOptions == 'contentSection') {
        return [sectionTypes.contentSection]
      }
      return []
    },
    blockTypes() {
      if (this.blockOptions == 'allBlocks') {
        return blocks
      }
      if (this.blockOptions == 'productBlocks') {
        return productBlocks
      }
      if (this.blockOptions == 'contentBlocks') {
        return contentBlocks
      }
      return simpleBlocks
    },
  },
  methods: {
    addSection(type, idx) {
      let newSection = cloneDeep(omit(sectionTypes[type], ['blockIcon', 'blockDescription', 'faIcon']))
      newSection._id = uniqid()
      this.$refs.popper.close()
      this.$emit('add-block', newSection)
    },
    addBlock(type, idx) {
      let newBlock = cloneDeep(omit(this.blockTypes[type], ['blockIcon', 'blockDescription', 'faIcon']))
      newBlock._id = uniqid()
      this.$refs.popper.close()
      this.$emit('add-block', newBlock)
    },
  },
}
</script>

<style>
</style>