<template>
  <div v-if="createable">
    <button :class="btnClass" @click.prevent="showModal = true">{{ btnText }}</button>
    <modal-dialog :show="showModal" @close="closeModal">
      <template #header><h3 slot="header">Create Element</h3></template>

      <alert-message v-bind="alert" />

      <form id="create-item-form" @keyup.enter="createItem" class="space-y-2">
        <rw-select v-model="type" label="Type" :options="elementTypes" :includeNull="false" />
        <rw-text v-model="title" label="Admin Label" placeholder="Enter the admin label..." />
        <rw-text v-model="slug" label="Admin Slug" placeholder="Enter the admin slug..." />
        <template v-if="type == 'page'">
          <rw-text
            v-model="menu_label"
            label="Menu Label"
            placeholder="Enter the menu label..."
          />
          <rw-text v-model="menu_slug" label="Menu Slug" placeholder="Enter the menu slug..." />
        </template>
        <div v-if="siteID" class="field checkbox">
          <label>
            <input type="checkbox" v-model="assignToSite" class="mr-2" />
            Assign entry to site ID: {{ siteID }}
          </label>
        </div>
      </form>
      <template #footer>
        <div slot="footer" class="flex justify-end">
          <button class="btn btn-go" @click.prevent="createItem">Create Element</button>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from '@/components/utils/ModalDialog'
import slugify from '@sindresorhus/slugify'
import { CREATE_ELEMENT } from '@/graphql/elements'
import { elementTypes } from '@/settings/enums'

export default {
  name: 'ElementCreate',
  props: {
    siteID: String,
    btnText: {
      type: String,
      default: 'Create Element',
    },
    btnClass: {
      type: String,
      default: 'btn btn-go',
    },
    createable: {
      type: Boolean,
      default: false,
    },
  },
  components: { ModalDialog },
  data() {
    return {
      elementTypes,
      assignToSite: this.siteID ? true : false,
      showModal: false,
      title: '',
      menu_label: null,
      menu_slug: null,
      type: null,
      alert: {
        type: null,
        message: null,
      },
    }
  },
  computed: {
    slug: {
      get() {
        return slugify(this.title)
      },
      set(val) {
        return val
      },
    },
  },
  methods: {
    reset() {
      this.title = ''
      this.menu_label = null
      this.menu_slug = null
      // Don't reset the type because the rw-select can't see the update.
    },
    closeModal() {
      this.reset()
      this.alert.type = null
      this.alert.message = null
      this.showModal = false
    },
    createItem() {
      this.error = null
      this.$apollo
        .mutate({
          mutation: CREATE_ELEMENT,
          variables: {
            input: {
              slug: this.slug,
              type: this.type,
              menu_label: this.menu_label,
              menu_slug: this.menu_slug,
              site_id: this.assignToSite && this.siteID ? this.siteID : null,
              content: {
                upsert: {
                  title: this.title,
                },
              },
            },
          },
        })
        .then(({ data: { createElement } }) => {
          this.alert.type = 'success'
          this.alert.message = `Element created with the title: ${createElement.content.title}`
          this.reset()
          this.$emit('item-created')
          document.querySelector('#create-item-form input[type="text"]').focus()
        })
        .catch(error => {
          this.alert.type = 'error'
          this.alert.message = error.message
        })
    },
  },
}
</script>

<style lang="scss">
</style>