<template>
  <div v-if="context == 'preview'">
    <p v-if="!block.elementId">Choose an element</p>
    <template v-if="hasElement && elementMods.content.content">
      <component
        v-for="(item, index) in elementMods.content.content"
        :key="item.id"
        v-model="elementMods.content.content[index]"
        :is="getType(item.blockType)"
        context="preview"
      />
    </template>
  </div>

  <div v-else>
    <div v-if="hasElement" class="mb-4">
      <p class="font-bold mb-0">Current Selection:</p>
      <div class="flex">
        <p class="mb-0 mr-2">{{ elementMods.content.title }}</p>
        <button @click="removeElement">
          <fa-icon icon="minus-circle" />
        </button>
      </div>
    </div>
    <content-picker
      :text="hasElement ? 'Replace element' : 'Choose element'"
      contentType="elements"
      @insert-items="insertElement"
    />
  </div>
</template>

<script>
import { ELEMENT } from '@/graphql/elements'

export default {
  name: 'DynamicElement',
  mixins: [BlockMixin],
  data() {
    return {
      element: {},
      elementMods: {},
    }
  },
  watch: {
    'block.elementId': function(val) {
      if (!val) {
        this.elementMods = {}
      }
    },
  },
  computed: {
    hasElement() {
      return Object.keys(this.elementMods).length
    },
  },
  apollo: {
    element: {
      query: ELEMENT,
      variables() {
        return {
          id: this.modelValue.elementId,
        }
      },
      result() {
        this.elementMods = structuredClone(this.element)
      },
      skip() {
        return !this.modelValue.elementId
      },
    },
  },
  methods: {
    removeElement() {
      this.block.elementId = null
    },
    insertElement($event) {
      if ($event.data.length) {
        this.block.elementId = $event.data[0].id
      } else {
        this.removeElement
      }
    },
  },
}
</script>
