<template>
  <div class="field light-switch" :class="{ 'field-inline': layout == 'inline' }">
    <Toggle 
      v-model="localValue"
      :disabled="disabled"
      @change="updateToggle"
      on-label="On"
      off-label="Off"
    />
    <span class="ml-2"><slot name="label"></slot></span>
  </div>
</template>


<script>
// All the options here: https://github.com/vueform/toggle
import Toggle from '@vueform/toggle'

export default {
  name: 'LightSwitch',
  components: {Toggle,},
  props: {
    modelValue: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    layout: {
      type: String,
      default: 'default',
    }
  },
  data() {
    return {
      localValue: this.modelValue || false
    };
  },
  methods: {
    updateToggle(newVal) {
      this.$emit('update:modelValue', newVal);
    }
  }
}
</script>
<style lang="scss">
/* 
 I copied this from the vueform default theme. I needed it more scopped because we are using
 more toggle classes and this component was interferring. 
*/
.field-inline.light-switch .toggle-container {
  width: 125px;
}

.toggle-container {
  display: inline-block;

  &:focus {
    outline: none;
    box-shadow: 0;
  }

  .toggle {
  display: flex;
  width: var(--toggle-width, 3rem);
  height: var(--toggle-height, 1.25rem);
  border-radius: 999px;
  position: relative;
  cursor: pointer;
  transition: .3s all;
  align-items: center;
  box-sizing: content-box;
  border: var(--toggle-border, 0.125rem) solid;
  font-size: var(--toggle-font-size, 0.75rem);
  line-height: 1;
}

.toggle-on {
  background: var(--toggle-bg-on, #38a169);
  border-color: var(--toggle-border-on, #38a169);
  justify-content: flex-start;
  color: var(--toggle-text-on, #ffffff);
}

.toggle-off {
  background: var(--toggle-bg-off, #e5e7eb);
  border-color: var(--toggle-border-off, #e5e7eb);
  justify-content: flex-end;
  color: var(--toggle-text-off, #374151);
}

.toggle-on-disabled {
  background: var(--toggle-bg-on-disabled, #d1d5db);
  border-color: var(--toggle-border-on-disabled, #d1d5db);
  justify-content: flex-start;
  color: var(--toggle-text-on-disabled, #9ca3af);
  cursor: not-allowed;
}

.toggle-off-disabled {
  background: var(--toggle-bg-off-disabled, #e5e7eb);
  border-color: var(--toggle-border-off-disabled, #e5e7eb);
  justify-content: flex-end;
  color: var(--toggle-text-off-disabled, #9ca3af);
  cursor: not-allowed;
}

.toggle-handle {
  display: inline-block;
  background: var(--toggle-handle-enabled, #ffffff);
  width: var(--toggle-height, 1.25rem);
  height: var(--toggle-height, 1.25rem);
  top: 0;
  border-radius: 50%;
  position: absolute;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: var(--toggle-duration, 150ms);
}

.toggle-handle-on {
  left: 100%;
  transform: translateX(-100%);
}

.toggle-handle-off {
  left: 0%;
}

.toggle-handle-on-disabled {
  left: 100%;
  transform: translateX(-100%);
  background: var(--toggle-handle-disabled, #f3f4f6);
}

.toggle-handle-off-disabled {
  left: 0%;
  background: var(--toggle-handle-disabled, #f3f4f6);
}

.toggle-label {
  text-align: center;
  width: calc(var(--toggle-width, 3.25rem) - var(--toggle-height, 1.25rem));
  box-sizing: border-box;
  white-space: nowrap;
  user-select: none;
}
}
</style>