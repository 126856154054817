<template>
  <div v-if="context == 'preview'" :class="previewClasses">
    <div v-if="hasTitles" class="mb-12">
      <h3 v-show="block.title" v-html="block.title"></h3>
    </div>
    <component
      v-for="item in blocks"
      :class="childClasses"
      :key="item.id"
      :value="item"
      :is="getType(item.blockType)"
      context="preview"
    />
  </div>

  <div v-else>
    <div class="mb-4 flex items-end">
      <rw-text class="mr-2 w-1/2" v-model="block.title" placeholder="Add a title" label="Title" />
      <rw-select
        class="mr-2"
        v-model="block.blockLayout"
        label="Layout"
        :options="{ columns: 'Equal Columns', grid: 'Grid (items wrap)' }"
        :includeNull="true"
      />
      <rw-select
        class="mr-2"
        v-model="block.bgStyle"
        label="BG Style"
        :options="{ 'bg-subtle': 'BG Subtle' }"
        :includeNull="true"
      />

      <light-switch class="mb-1" v-model="block.breakout"><template #label>Breakout?</template></light-switch>
    </div>
    <div class="mb-4">
      <rw-image context="tinyPreview" v-model="block.titleImage" />
    </div>

    <p v-if="!block.children.length" class="m-0">Drag a block here to get started</p>
    <draggable 
      :list="block.children" 
      handle=".block-handle" 
      class="pb-6" 
      item-key="_id"
      :group="{ name: 'section', put: true }">
      <template #item="{ element, index }">
        <content-builder-item
          v-model="block.children[index]"
          :includePresets="true"
          @update:modelValue="watchBlock($event, index)"
          @load-block="loadBlock($event, index)"
          @remove-item="block.children.splice(index, 1)"
        />
      </template>
    </draggable>

    <content-block-picker blockOptions="productBlocks" @add-block="block.children.push($event)" />
  </div>
</template>

<script>
import { reactive } from 'vue'
export default {
  name: 'ProductSection',
  props: ['modelValue', 'context'],
  data() {
    return {
      block: reactive(this.modelValue),
      blocks: reactive(this.modelValue.children),
    }
  },
  computed: {
    hasTitles() {
      return this.block.preTitle || this.block.title || this.block.subTitle
    },
    previewClasses() {
      return [this.block.blockLayout == 'columns' ? 'flex -mx-2' : null]
    },
    childClasses() {
      return [this.block.blockLayout == 'columns' ? 'flex-1 px-2' : null]
    },
  },
  methods: {
    watchBlock($event, idx) {
      this.block.children[idx] = $event;
      //this.$set(this.block.children, idx, $event)
    },
    loadBlock($event, idx) {
      this.blocks[idx] = $event.content;
      //this.$set(this.blocks, idx, $event.content)
    },
    getType(blockType) {
      let blockName = blockType
        .match(/\w+/)[0]
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\s+/g, '-')
        .toLowerCase()
      return 'rw-' + blockName
    },
  },
}
</script>