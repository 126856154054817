<template>
  <div>
    <button :class="classes" @click="openModal">{{text}}</button>
    <modal-dialog
      :show="showModal"
      @close="closeModal"
      :height="includePages ? 'min-h-1/3' : null "
    >
      <template #header><h3>Create Site</h3></template>

      <div v-if="error" class="inline-alert error" role="alert">
        <strong class="font-bold">Something bad happened:</strong>
        <span class="block sm:inline">{{ error }}</span>
      </div>

      <div v-if="success" class="inline-alert success" role="alert">
        <strong class="font-bold">Success!</strong>
        <p class="m-0 flex items-center">
          <span>{{ success }}</span>
          <button class="btn btn-sm ml-2" @click="goToSite(siteID)">Edit Site</button>
        </p>
      </div>
      <form class="space-y-2">
        <rw-text v-model="title" label="Site Name" placeholder="Enter a site name..." />

        <div class="field checkbox">
          <label>
            <input type="checkbox" v-model="includePages" class="mr-2" />
            Add default pages?
          </label>
        </div>

        <site-select @update:value="siteImportId = $event.id" v-if="includePages" />
      </form>
      <template #footer>
        <div class="flex justify-end">
          <button class="btn btn-go" @click.prevent="createSite">Add Site</button>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from '@/components/utils/ModalDialog'
import { CREATE_SITE } from '@/graphql/sites'

export default {
  name: 'SiteCreate',
  props: ['text', 'classes'],
  components: { ModalDialog },
  data() {
    return {
      showModal: false,
      title: '',
      includeProducts: false,
      includePages: false,
      siteImportId: null,
      error: null,
      success: null,
      siteID: null,
    }
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
      this.title = ''
      this.success = null
      this.siteID = null
      this.siteImportId = null
    },
    goToSite(siteID) {
      this.$router.push({ name: 'SiteDashboard', params: { siteID: siteID } })
      this.closeModal()
    },
    createSite() {
      this.error = null
      this.siteID = null
      this.$apollo
        .mutate({
          mutation: CREATE_SITE,
          variables: {
            input: {
              title: this.title,
              includeProducts: this.includeProducts,
              includePages: this.includePages,
              siteImportId: this.siteImportId,
            },
          },
        })
        .then(({ data: { createSite } }) => {
          this.success = `Site created with the key: ${createSite.key}.`
          this.siteID = createSite.id
          this.title = ''
          this.includeProducts = false
          this.includePages = false
          this.siteImportId = null
          this.$emit('site-created')
        })
        .catch((error) => {
          console.log(error)
          this.error = error.message
        })
    },
  },
}
</script>

<style lang="scss">
</style>