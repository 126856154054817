<template>
  <div class="content-preview">
    <div v-if="content.hero" class="mb-8">
      <rw-hero context="preview" v-model="content.hero" />
    </div>

    <div v-for="(block, idx) in blocks" :key="block._id">
      <template v-if="block.blockType == 'tipTap'">
        <div v-html="block.htmlContent"></div>
      </template>

      <template v-else-if="block.blockType == 'image'">
        <figure class="w-1/3" :class="[ block.position ]">
          <cloudinary-image 
            v-if="block.cloudinaryId"
            width="384"
            height="384"
            crop="fit"
            :public-id="block.cloudinaryId" />
        </figure>
      </template>      
      <component v-else :is="getType(block.blockType)" v-model="blocks[idx]" context="preview" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContentBuilderPreview',
  props: ['content'],
  computed: {
    blocks() {
      if (this.content.blockType) {
        return [this.content]
      } else {
        return this.content.content || []
      }
    },
  },
  methods: {
    getType(blockType) {
      let blockName = blockType
        .match(/\w+/)[0]
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\s+/g, '-')
        .toLowerCase()
      return 'rw-' + blockName
    },
  },
}
</script>

<style>
.cld-image img {
  margin: 0 auto;
}
</style>