<template>
  <div v-if="context == 'preview'">
    <img v-if="block.image" :src="block.image.src" class="mb-4" />
  </div>

  <div v-else>
    <rw-select
      :options="layouts"
      v-model="block.blockLayout"
      label="Layout"
      :includeNull="true"
    />
    <rw-select :options="styles" v-model="block.blockStyle" label="Style" :includeNull="true" />

    <div class="mt-4">
      <rw-image v-model="block.image" context="settings" @update:value="updateImage" />
    </div>
  </div>
</template>

<script>
import { blockStyles, blockLayouts } from '@/settings/blocks'

export default {
  name: 'Banner',
  mixins: [BlockMixin],
  data() {
    return {
      layouts: blockLayouts.banner,
      styles: blockStyles.banner,
    }
  },
  methods: {
    updateImage($event) {
      this.block = $event
      //this.$set(this.block, 'image', $event)
    },
  },
}
</script>