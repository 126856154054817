<template>
  <div class="flex-1" v-if="modelValue">
    <div v-if="context == 'preview'">
      <cloudinary-image 
        v-if="block.cloudinaryId" 
        :transforms="block.transforms"
        :public-id="block.cloudinaryId" />
    </div>

    <!--  This is used on site settings pages for favicons and logos -->
    <div v-if="context == 'inline-preview'" class="relative flex items-center">
      <div v-if="block.src" class="mr-2 p-2 flex-none border border-dashed relative">
        <cloudinary-image 
          v-if="block.cloudinaryId"
          width="150"
          height="75"
          crop="pad"
          :public-id="block.cloudinaryId" />
        <button
          v-if="isEditable"
          @click.prevent="showModal = true"
          class="absolute top-0 right-0 bg-white p-1 pl-2 rounded-bl"
        >
          <fa-icon icon="cog" />
        </button>
      </div>

      <div class="flex">
        <cloudinary :fieldUid="$.uid" :hasImage="hasImage" v-if="isEditable" />
        <button class="btn btn-stop ml-2 px-2" @click.prevent="removeImage" v-if="block.src && isEditable">
          <fa-icon icon="trash" />
        </button>
        <button class="btn btn-info ml-2 px-2" @click.prevent="downloadImage" v-if="block.src && $can('Image', 'download')">
          <fa-icon icon="download" />
        </button>
      </div>
    </div>

    <div v-if="context == 'teaser'" class="mb-1">
      <div class="relative">
        <div
          v-if="block.src && includePosition && block.position"
          class="absolute top-0 right-0 bg-white p-2"
        >{{ block.position }}</div>
        <cloudinary-image 
          class="mb-2" 
          v-if="block.cloudinaryId"
          :public-id="block.cloudinaryId"
          :transforms="block.transforms" />
      </div>

      <div class="relative flex items-center justify-between">
        <div>
          <cloudinary :fieldUid="$.uid" :hasImage="hasImage" />
          <button @click.prevent="showModal = true" class="ml-2 btn px-2" v-if="block.src && isEditable">
            <fa-icon icon="cog" />
          </button>
        </div>
        <button class="btn btn-stop ml-2 px-2" @click.prevent="removeImage" v-if="block.src && isEditable">
          <fa-icon icon="trash" />
        </button>
      </div>
    </div>

    <div v-if="context == 'tinyPreview'">
      <p class="font-bold">{{ label }}</p>

      <div class="relative flex items-center">
        <cloudinary-image 
          v-if="block.cloudinaryId"
          height="75"
          crop="fit"
          class="border border-dashed p-2 mr-2 cld-image cld-image-loaded"
          :public-id="block.cloudinaryId" />
        <div>
          <cloudinary :fieldUid="$.uid" :hasImage="hasImage" class="btn-sm" />
          <button @click.prevent="showModal = true" class="ml-2 btn btn-sm px-2" v-if="block.src && isEditable">
            <fa-icon icon="cog" />
          </button>
        </div>
        <button class="btn btn-sm btn-stop ml-2 px-2" @click.prevent="removeImage" v-if="block.src && isEditable">
          <fa-icon icon="trash" />
        </button>
      </div>
    </div>

    <div v-if="context == 'settings'">
      <p class="font-bold text-lg">{{ label }}</p>

      <div class="relative flex items-center justify-between">
        <div class="flex items-center space-x-2">
          <cloudinary-image 
            v-if="tinyPreview && block.cloudinaryId" 
            width="75"
            height="75"
            crop="fit"
            :public-id="block.cloudinaryId" />
          <cloudinary :fieldUid="$.uid" :hasImage="hasImage" />
          <button @click.prevent="showModal = true" class="btn px-2" v-if="block.src && isEditable">
            <fa-icon icon="cog" />
          </button>
        </div>
        <button class="btn btn-stop ml-2 px-2" @click.prevent="removeImage" v-if="block.src && isEditable">
          <fa-icon icon="trash" />
        </button>
      </div>
    </div>

    <modal-dialog :show="showModal" @close="showModal = false" size="small">
      <h3 slot="header">{{ label }} Details</h3>
      <div class="space-y-4">
        <rw-text v-if="includeAlt" v-model="block.alt" label="Alt" />
        <rw-text
          v-if="includeCaption"
          v-model="block.caption"
          placeholder="Enter the caption"
          label="Caption"
        />

        <rw-text v-if="includeWidth" v-model="block.maxWidth" label="Max Width" />

        <div v-if="includeStyle" class="field field-inline">
          <label for="imageStyle" class="mr-2">Style:</label>
          <select name="imageStyle" id="imageStyle" v-model="block.style">
            <option :value="null">Default</option>
            <option value="shadow">Shadow</option>
            <!-- <option value="box">Box</option> -->
          </select>
        </div>

        <!-- This field only shows when on the page builder area -->
        <div v-if="context == 'settings'" class="field field-inline">
          <label class="mr-2">CSS Classes:</label>
          <rw-text
              v-model="block.classes"
              class="w-2/5"
              placeholder="Separate using a space."
            />
        </div>

        <div v-if="includePosition" class="field field-inline">
          <label for="imagePosition" class="mr-2">Position:</label>
          <select name="imagePosition" id="imagePosition" v-model="block.position">
            <option value="full">Full</option>
            <option value="float-left">Left</option>
            <option value="float-right">Right</option>
          </select>
        </div>

        <div class="field field-inline flex">
          <label class="w-1/3">Image Manipulation:</label>
          <light-switch v-model="block.grayscale" class="my-4 w-1/3"><template #label>Grayscale</template></light-switch>
          <light-switch v-model="block.rounded" class="my-4 w-1/3"><template #label>Rounded</template></light-switch>
        </div>

        <div v-if="$can('Image', 'transform')">
          <div class="flex items-center mb-2">
            <p class="mb-0 mr-2 font-bold">Transforms:</p>
            <button class="btn btn-sm" @click="addTransform">Add transform</button>
          </div>
          <div class="flex mb-2 space-x-2" v-for="(transform, idx) in block.transforms" :key="idx">
            <rw-text
              class="w-1/5"
              v-model="transform.ar"
              label="Aspect Ratio"
              placeholder="1:1, 16:9"
            />
            <rw-text
              class="w-1/5"
              v-model="transform.width"
              label="Width"
              placeholder="Ex. 160"
            />
            <rw-text
              class="w-1/5"
              v-model="transform.height"
              label="Height"
              placeholder="Ex. 160"
            />
            <rw-text
              class="w-1/5"
              v-model="transform.crop"
              label="Crop"
              placeholder="Ex. fill, pad, fit"
            />
            <button class="mt-4" @click="block.transforms.splice(idx, 1)">
              <fa-icon icon="minus-circle" />
            </button>
          </div>
        </div>

        <div v-if="includeOverlay">
          <label for="imageOverlay" class="mr-2">Overlay:</label>
          <select
            name="imageOverlay"
            id="imageOverlay"
            v-model="block.overlay"
            class="inline-block p-0 h-auto"
          >
            <option :value="null">None</option>
            <option value="subtle">Subtle (15%)</option>
            <option value="dark">Dark (25%)</option>
            <option value="darker">Darker (45%)</option>
            <option value="darkest">Darkest (65%)</option>
            <option value="white">White (25%)</option>
            <option value="whiter">Whiter (45%)</option>
            <option value="whitest">Whitest (65%)</option>
          </select>
        </div>

        <div v-if="includeCrop">
          <light-switch v-model="block.noCrop"><template #label>Don't Crop Image</template></light-switch>
        </div>
      </div>

      <p class="font-bold mt-4" v-if="block.cloudinaryId">
        Cloudinary Details
        <button
          class="font-bold px-2"
          @click.prevent="showCloudinary = !showCloudinary"
          v-html="showCloudinary ? '-' : '+'"
        ></button>
      </p>
      <table v-show="showCloudinary">
        <tbody>
          <tr v-for="(attribute, id) in block.cloudinary" :key="id">
            <td class="py-1 px-2 font-bold">{{ id }}</td>
            <td class="py-1 px-2 break-all">{{ attribute }}</td>
          </tr>
        </tbody>
      </table>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from '@/components/utils/ModalDialog'
import defaultsDeep from 'lodash/defaultsDeep'
import cloneDeep from 'lodash/cloneDeep'
import { image } from '@/settings/blocks'
import EventBus from '@/bootstrap/EventBus'
import { reactive } from 'vue'

export default {
  name: 'RWImage',
  components: { ModalDialog },
  props: {
    modelValue: {
      type: [Array, Object],
    },
    label: {
      type: String,
      default: 'Image',
    },
    context: {
      type: String,
      default: 'preview',
    },
    tinyPreview: {
      type: Boolean,
      default: false,
    },
    includeTransforms: {
      type: Boolean,
      default: true,
    },
    includeSrc: {
      type: Boolean,
      default: true,
    },
    includeStyle: {
      type: Boolean,
      default: true,
    },
    includePosition: {
      type: Boolean,
      default: false,
    },
    includeAlt: {
      type: Boolean,
      default: true,
    },
    includeCaption: {
      type: Boolean,
      default: false,
    },
    includeWidth: {
      type: Boolean,
      default: false,
    },
    includePreview: {
      type: Boolean,
      default: false,
    },
    includeOverlay: {
      type: Boolean,
      default: false,
    },
    includeCrop: {
      type: Boolean,
      default: false,
    },
  },
  
  data() {
    return {
      isEditable: true,
      imageKey: 0,
      showCloudinary: false,
      showModal: false,
    }
  },
  computed: {
    hasImage() {
      return this.block.src && this.block.src.length
    },
    block: {
      get() {
        return defaultsDeep(reactive(this.modelValue), cloneDeep(image));
      },
      set(newVal) {
        this.$emit('update:modelValue', newVal);
      }
    }
  },
  mounted() {
    if (this.modelValue === undefined || this.modelValue === null) {
      this.$emit('update:modelValue', this.block)
    }
    EventBus.on('asset-chosen', this.updateImage);
    
    const perm = this.$route.name;
    this.isEditable = this.$can(perm, 'update');
  },
  beforeUnmounted() {
    EventBus.off('asset-chosen')
  },
  methods: {
    updateImage(data) {
      if (this.$.uid === data.uid) {
        console.log('Image.vue - updateImage', data, this.$.uid, data.uid)
        this.block = Object.assign({}, this.block, {
          src: data.asset.secure_url,
          cloudinaryId: data.asset.public_id,
          cloudinary: data.asset,
        })
      }
    },
    removeImage() {
      this.block = cloneDeep(image)
    },

    downloadImage() {
      window.open(this.block.src);
    },
    addTransform() {
      let transform = Object.assign(
        {},
        {
          ar: null,
          width: null,
          height: null,
          crop: null,
        }
      )
      this.block.transforms.push(transform)
    },
  },
}
</script>

<style>
.cloudinary-image img {
  margin: 0 auto;
}
</style>