<template>
  <div class="flex-1" v-if="modelValue">
    <div v-if="context == 'preview'">
      <p>Asset preview</p>
    </div>

    <div v-if="context == 'settings'" class="space-y-4">
      <rw-text
        class="mr-2 w-64"
        v-if="block.src"
        v-model="block.btnText"
        label="Button Text"
        placeholder="Download the eBook"
      />
      <div class="flex space-x-2">
        <cloudinary :fieldUid="$.uid" btnText="Browse Cloudinary" />
        <button @click.prevent="showModal = true" class="btn btn-sm" v-if="block.src">
          <fa-icon icon="cog" />
        </button>
        <button class="btn btn-sm btn-stop" @click.prevent="removeAsset" v-if="block.src">
          <fa-icon icon="trash" />
        </button>
      </div>

      <p v-if="block.cloudinary">
        <strong>Current Selection:</strong>
        {{ block.cloudinary.public_id }}
      </p>
    </div>

    <modal-dialog :show="showModal" @close="showModal = false" size="small">
      <template #header><h3 slot="header">Details</h3></template>

      <p v-if="block.cloudinary">
        <strong>Current Selection:</strong>
        {{ block.cloudinary.public_id }}
      </p>

      <p class="font-bold mt-4" v-if="block.cloudinaryId">
        Cloudinary Details
        <button
          class="font-bold px-2"
          @click.prevent="showCloudinary = !showCloudinary"
          v-html="showCloudinary ? '-' : '+'"
        ></button>
      </p>
      <table v-show="showCloudinary">
        <tbody>
          <tr v-for="(attribute, id) in block.cloudinary" :key="id">
            <td class="py-1 px-2 font-bold">{{ id }}</td>
            <td class="py-1 px-2 break-all">{{ attribute }}</td>
          </tr>
        </tbody>
      </table>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from '@/components/utils/ModalDialog'
import BlockMixin from '@/components/mixins/block'
import EventBus from '@/bootstrap/EventBus'
import { reactive } from 'vue'

export default {
  name: 'RWDownload',
  components: { ModalDialog },
  mixins: [BlockMixin],
  watch: {
    block: {
      deep: true,
      immediate: true,
      handler(newVal) {
        console.log('Download.vue block watcher', newVal)
        this.$emit('update:modelValue', newVal)
      },
    },
  },
  
  data() {
    return {
      showCloudinary: false,
      showModal: false,
      block: reactive(this.modelValue) || Object.assign({}, { src: null, cloudinaryId: null, cloudinary: {} }),
    }
  },
  mounted() {
    EventBus.on('asset-chosen', this.updateAsset)
  },
  beforeUnmounted() {
    EventBus.off('asset-chosen')
  },
  methods: {
    updateAsset(data) {
      if (this.$.uid === data.uid) {
        console.log('Download.vue - updateAsset', data, this.$.uid, data.uid)
        this.block = Object.assign({}, this.block, {
          src: data.asset.secure_url,
          cloudinaryId: data.asset.public_id,
          cloudinary: data.asset,
        })
      }
    },
    removeAsset() {
      this.block = {}
    },
  },
}
</script>