import gql from 'graphql-tag'

let ContentPkgDetails = gql`
  fragment ContentPackageDetails on ContentPackage {
    id
    title
    type
    social_content
    published_at
    magazine {
      id
      title
      type
      teaser_image
    }
    entries {
      id
      type
      content {
        id
        title
        status
      }
    }
    created_at
    updated_at
    deleted_at
  }
`

export const CONTENT_PKG_DETAILS = ContentPkgDetails

export const CONTENT_PACKAGE = gql`
  query ContentPackage($id: ID!) {
    contentPackage(id: $id) {
      ...ContentPackageDetails
    }
  }
  ${ContentPkgDetails}
`

export const ALL_CONTENT_PACKAGES = gql`
  query ContentPackages($type: String) {
    contentPackages(type: $type) {
      ...ContentPackageDetails
    }
  }
  ${ContentPkgDetails}
`

export const CREATE_CONTENT_PACKAGE = gql`
  mutation CreateContentPackage($input: CreateContentPackageInput!) {
    createContentPackage(input: $input) {
      ...ContentPackageDetails
    }
  }
  ${ContentPkgDetails}
`

export const UPDATE_CONTENT_PACKAGE = gql`
  mutation UpdateContentPackage($input: UpdateContentPackageInput!) {
    updateContentPackage(input: $input) {
      ...ContentPackageDetails
    }
  }
  ${ContentPkgDetails}
`

export const DELETE_CONTENT_PACKAGE = gql`
  mutation DeleteContentPackage($input: DeletePackageInput) {
    deleteContentPackage(input: $input) {
      ...ContentPackageDetails
    }
  }
  ${ContentPkgDetails}
`
