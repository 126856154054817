<template>
  <div class="mb-4" v-if="context == 'preview'">
    <p v-if="block.preTitle" v-html="block.preTitle" class="m-0"></p>
    <h2 v-if="block.title" v-html="block.title" class="m-0 h4"></h2>
    <p v-if="block.subTitle" v-html="block.subTitle" class="m-0"></p>
    <ul v-if="block.children.length" :class="block.variant">
      <li v-for="(child, idx) in block.children" :key="idx">
        {{ child.text }}

        <ul v-if="Object.hasOwn(child, 'children') && child.children.length" class="ml-4">
          <li v-for="(grand, idx) in child.children" :key="idx">{{ grand.text }}</li>
        </ul>
      </li>
    </ul>
  </div>

  <div v-else @click.stop class="space-y-2">
    <rw-select v-model="block.blockStyle" :options="options" label="Style" />
    <rw-text v-model="block.preTitle" label="PreTitle" />
    <rw-text v-model="block.title" label="Title" />
    <rw-text v-model="block.subTitle" label="SubTitle" />

    <Draggable v-model="block.children" :max-level="2" class="mtl-tree" treeLine ref="tree" triggerClass="item-handle">
      <template #default="{node, stat}">
        <div class="item-row flex flex-grow border-b items-center space-x-2">
          <fa-icon icon="grip-vertical" class="item-handle mr-2" />
          <input type="text" class="w-full" v-model="node.text" ref="listItem" @keydown.enter="addItem" />
          <button @click.stop="removeItem(stat)">
            <fa-icon icon="minus-circle">Trash</fa-icon>
          </button>
        </div>
      </template>
    </Draggable>

    <button class="btn btn-icon" slot="footer" @click="addItem"><fa-icon icon="plus" />Add List Item</button>
  </div>
</template>

<script>
import { variants } from '@/settings/blocks'
import '@he-tree/vue/style/default.css'
import '@he-tree/vue/style/material-design.css';
import { Draggable } from '@he-tree/vue'

export default {
  name: 'ItemList',
  $label: 'Item List',
  mixins: [BlockMixin],
  components: { Draggable },
  data() {
    return {
      options: variants.itemList,
    }
  },
  created() {
    this.defaults()
  },
  
  methods: {
    addItem() {
      this.$refs.tree.add({ text: '', children: [] })

      this.$nextTick(() => {
        let index = this.block.children.length - 1
        let input = this.$refs.listItem[index]
        input && input.focus()
      })
    },
    defaults() {
      const recursiveDefaults = function (data) {
        data.forEach(function (item) {
          item.children = item.children ? item.children : []

          // Run this recursively.
          if (item.children) recursiveDefaults(item.children)
        })

        return data
      }

      recursiveDefaults(this.block.children)
    },
    removeItem(stat) {
      if (confirm('Are you sure you want to delete this item?')) {
        this.$refs.tree.remove(stat);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.item-row:last-of-type {
  border-bottom: 0;
}

.checkmarks,
.dashes {
  padding-left: 20px;
}

.checkmarks li::before {
  content: '\2713';
  width: 10px;
  margin-left: -20px;
  margin-right: 5px;
}

.dashes li::before {
  content: '\2013';
  width: 10px;
  margin-left: -20px;
  margin-right: 5px;
}
</style>