<template>
  <div class="field" :class="{ 'field-inline': layout == 'inline' }">
    <label v-if="label" :for="'text-' + $.uid" class="capitalize">{{ label }}</label>
    <input
      v-if="lines === 1"
      type="text"
      :class="cssclass"
      :id="'text-' + $.uid"
      :value="modelValue"
      :placeholder="holder"
      :disabled="disabled"
      :maxlength="maxLength ?? 366"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <textarea
      v-else
      :rows="lines"
      :class="cssclass"
      :id="'text-' + $.uid"
      :value="modelValue"
      :placeholder="holder"
      :disabled="disabled"
      @input="$emit('update:modelValue', $event.target.value)"
    ></textarea>
    <p v-if="help" class="help">{{ help }}</p>
  </div>
</template>

<script>
export default {
  name: 'RWText',
  props: {
    // field: {
    //   required: true,
    // },
    label: {
      required: false,
    },
    placeholder: {
      required: false,
    },
    disabled: {
      required: false,
      default: false,
    },
    help: {
      required: false,
    },
    layout: {
      required: false,
    },
    prefix: {
      required: false,
    },
    cssclass: {
      required: false,
    },
    lines: {
      required: false,
      default: 1,
    },
    maxLength: {
      required: false,
    },
    modelValue: {
      required: true,
    }
  },
  computed: {
    holder() {
      return this.placeholder || 'Enter ' + this.label + '...'
    },
  },
}
</script>