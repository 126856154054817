let uniqid = require('uniqid')

export default {
  methods: {
    makeUnique(data, key = '_id') {
      let recursiveIds = function (data, key) {
        // This should probably never happen
        if (typeof data == 'undefined') return;

        if (!Array.isArray(data)) {
          data = [data]
        }

        data.forEach(function (item) {
          item[key] = item[key] ? item[key] : uniqid()
          // run this recursively
          if (item.children) recursiveIds(item.children, key)
        })
        return data
      }
      return recursiveIds(data, key)
    },
  },
}
