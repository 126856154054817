<template>
  <div>
    <button @click="showModal = true">
      <fa-icon icon="eye" />
    </button>
    <modal-dialog :show="showModal" @close="showModal = false" :size="size">
      <template #header><h3>Preview</h3></template>
      <content-builder-preview :content="previewContent" />
    </modal-dialog>
  </div>
</template>

<script>
import ContentBuilderPreview from '@/components/builder/ContentBuilderPreview'

export default {
  name: 'ContentPreview',
  props: ['content'],
  components: {ContentBuilderPreview},
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    size() {
      if (
        this.content.__typename == 'BlockPreset' &&
        this.content.block_type !== 'section' &&
        this.content.block_type !== 'container'
      ) {
        return 'small'
      }
      return 'medium'
    },
    previewContent() {
      return this.content.content
    },
  },
}
</script>

<style>
</style>