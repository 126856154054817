import gql from 'graphql-tag'

let ElementDetailsFragment = gql`
  fragment ElementDetails on Element {
    id
    site_id
    slug
    menu_slug
    menu_label
    type
    content {
      id
      element_id
      title
      status
      hero
      seo
      content
      created_at
      updated_at
    }
    created_at
    updated_at
  }
`

export const ELEMENT_DETAILS_FRAGMENT = ElementDetailsFragment

export const ALL_ELEMENTS = gql`
  query Elements($elementType: String, $ids: [String!]) {
    elements(type: $elementType, ids: $ids) {
      ...ElementDetails
    }
  }
  ${ElementDetailsFragment}
`

export const GLOBAL_ELEMENTS = gql`
  query GlobalElements($elementType: String) {
    elements(type: $elementType, where: { column: SITE_ID, operator: IS_NULL }) {
      ...ElementDetails
    }
  }
  ${ElementDetailsFragment}
`

export const SITE_ELEMENTS = gql`
  query SiteElements($siteID: Mixed!) {
    elements(where: { column: SITE_ID, operator: EQ, value: $siteID }) {
      ...ElementDetails
    }
  }
  ${ElementDetailsFragment}
`

export const ELEMENT = gql`
  query Element($id: ID!) {
    element(id: $id) {
      ...ElementDetails
    }
  }
  ${ElementDetailsFragment}
`

export const CREATE_ELEMENT = gql`
  mutation CreateElement($input: CreateElementInput!) {
    createElement(input: $input) {
      ...ElementDetails
    }
  }
  ${ElementDetailsFragment}
`

export const UPDATE_ELEMENT = gql`
  mutation UpdateElement($input: UpdateElementInput!) {
    updateElement(input: $input) {
      ...ElementDetails
    }
  }
  ${ElementDetailsFragment}
`

export const DELETE_ELEMENT = gql`
  mutation DeleteElement($id: ID!) {
    deleteElement(id: $id) {
      id
    }
  }
`
