import gql from 'graphql-tag'

let SiteContentSettings = gql`
  fragment ContentSettingsDetail on SiteContentSettings {
    id
    site_id
    blog_type
    subscription_type
    magazine_show
    blog_autopilot
    ebook_autopilot
    quickread_autopilot
    magazine_overrides
    ebook_overrides
    quickread_overrides
    blog_overrides
  }
`

export const SITE_CONTENT_SETTINGS_FRAGMENT = SiteContentSettings

export const SITE_CONTENT_SETTINGS = gql`
  query SiteContentSettings($site_id: ID!) {
    site(id: $site_id) {
      id
      title
      contentSettings {
        ...ContentSettingsDetail
      }
    }
  }
  ${SiteContentSettings}
`

export const SAVE_SITE_CONTENT_SETTINGS = gql`
  mutation UpdateSiteContentSettings($input: SiteContentSettingsInput!) {
    updateSiteContentSettings(input: $input) {
      id
      title
      contentSettings {
        ...ContentSettingsDetail
      }
    }
  }
  ${SiteContentSettings}
`

export const UPDATE_OVERRIDE = gql`
  mutation UpdateOverride($input: SiteContentOverrides!) {
    updateOverride(input: $input) {
      site_id
    }
  }
`

let SettingsDetail = gql`
  fragment SettingsDetail on SiteSettings {
    id
    site_id
    primary_color
    secondary_color
    logo
    favicon
    seo
    resources_page
    magazine
    magazine_link
    social_channels
    disclaimer
    show_mobile_disclaimer
    rw_branding
    is_svg
    connectors_label
    connection_btn_class
    pay_bill
    secondary_nav
    footer_logo
    include_footer_logo
    footer_version
    tagline
    membership_align
    extra_links
  }
`
export const SITE_SETTINGS_FRAGMENT = SettingsDetail

let ConnectorDetails = gql`
  fragment ConnectorDetails on SiteConnector {
    id
    site_id
    vendor_id
    order
    label
    url
  }
`

export const CONNECTOR_FRAGMENT = ConnectorDetails

let SiteMembershipDetails = gql`
  fragment SiteMembershipDetails on SiteMembership {
    id
    site_id
    url
    membership_id
    order
  }
`
export const SITE_MEMBERSHIP_FRAGMENT = SiteMembershipDetails
