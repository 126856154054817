<template>
  <div v-if="!$apollo.queries.vendors.loading">
    <draggable
      :list="connectors"
      :group="{ name: 'connectors' }"
      handle=".vendor-handle"
      item-key="index"
      @end="setOrder"
    >
      <template #item="{ element, index }">
        <div class="flex items-start mb-4 pb-4 border-b">
          <fa-icon class="vendor-handle mr-4 mt-1" icon="grip-vertical">Move</fa-icon>
          <div class="flex flex-1 mr-2">
            <div class="w-1/2 space-y-2">
              <div class="field">
                <label :for="'select-' + $.uid" class="text-sm">Vendor</label>
                <select :id="'select-' + $.uid" v-model="element.vendor_id">
                  <option
                    v-for="(vendor, vendorIdx) in vendors"
                    :key="vendorIdx"
                    :value="vendor.id"
                  >{{vendor.title}}</option>
                </select>
              </div>
              <rw-text
                v-model="element.label"
                label="Override UI Label"
                placeholder="Enter the label..."
              />
              <rw-text
                v-model="element.url"
                label="URL override"
                placeholder="Enter the full URL..."
              />
            </div>
          </div>
          <button class="ml-2" @click.stop="deleteConnector(element, index)">
            <fa-icon icon="minus-circle">Trash</fa-icon>
          </button>
        </div>
      </template>
    </draggable>
    <button v-if="editable" class="btn" @click="addConnector">Add Connector</button>
  </div>
</template>

<script>
import { VENDOR_LIST } from '@/graphql/vendors'
import set from 'lodash/set'

export default {
  name: 'ClientConnectorForm',
  props: ['connectors', 'connectorsToDelete', 'editable'],
  data() {
    return {
      vendors: {},
    }
  },
  apollo: {
    vendors: {
      query: VENDOR_LIST,
    },
  },
  methods: {
    addConnector() {
      const connector = Object.assign({}, { order: this.connectors.length })
      this.connectors.push(connector)
    },
    deleteConnector(connector, idx) {
      connector.id ? this.connectorsToDelete.push(connector.id) : null
      this.connectors.splice(idx, 1)
    },
    setOrder(evt) {
      this.connectors.forEach((element, idx) => {
        element.order = idx
      })
    },
  },
}
</script>