<template>
  <div v-if="!$apollo.loading">
    <div class="tab-controls mb-4" v-if="$route.params.siteID">
      <button
        :class="{ 'active': activeTab == 'siteContent' }"
        @click="activeTab = 'siteContent'"
      >Site Content</button>
      <button
        :class="{ 'active': activeTab == 'globalContent' }"
        @click="activeTab = 'globalContent'"
      >Global Content</button>
    </div>

    <entries-table
      v-if="$route.params.siteID && activeTab == 'siteContent'"
      scope="site"
      :siteID="$route.params.siteID"
      :selectable="true"
      :selectionMode="selectionMode"
      :allowedTypes="allowedTypes"
      @selection-changed="$emit('selection-changed', $event)"
    />

    <!-- Use v-if so that we can force Vue to load this component -->
    <entries-table
      v-if="activeTab == 'globalContent'"
      scope="global"
      :selectable="true"
      :selectionMode="selectionMode"
      :allowedTypes="allowedTypes"
      @selection-changed="$emit('selection-changed', $event)"
    />
  </div>
</template>

<script>
import EntriesTable from '@/components/tables/EntriesTable'
import { ALL_ENTRIES } from '@/graphql/entries'
import { entryTypes } from '@/settings/enums'

export default {
  name: 'EntryPicker',
  props: ['selectionMode', 'allowedTypes', 'initialTab'],
  components: { EntriesTable },
  data() {
    return {
      entryTypes,
      activeTab: this.initialTab ? this.initialTab : 'globalContent',
    }
  },
}
</script>