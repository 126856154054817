<template>
  <div class="flex items-start mb-2 relative">
    <div class="flex flex-col items-center mr-2 mt-1">
      <fa-icon icon="grip-vertical" class="block-handle mb-2" />
    </div>

    <div class="flex-1 border">
      <div class="flex border-b-1 p-1 text-sm bg-gray-200 font-bold items-center">
        <button @click.prevent="toggleBlock" class="mx-1 text-left">
          <fa-icon icon="angle-up" :class="{ hidden: block.collapsed }" />
          <fa-icon icon="angle-down" :class="{ hidden: !block.collapsed }" />
          <span class="mx-1 font-bold">{{ block.blockName }}</span>
        </button>

        <div class="mr-2">
          <rw-text v-model="block.blockLabel" placeholder="Admin label" />
        </div>

        <block-saver @load-block="$emit('load-block', $event)" :block="block" v-if="includePresets">
          <button slot="trigger" title="Import block preset">
            <fa-icon icon="file-import" />
          </button>
        </block-saver>

        <div class="ml-auto -my-1">
          <!-- <button class="ml-auto" @click="localPreview = !localPreview">
            <fa-icon icon="eye" />
          </button>-->
          <template v-if="tabs">
            <button
              @click="activeTab = 'content'"
              :class="{ 'bg-white': activeTab == 'content' }"
              class="py-2 px-2 mx-1"
            >
              Content
            </button>
            <button @click="activeTab = 'settings'" :class="{ 'bg-white': activeTab == 'settings' }" class="py-2 px-2">
              Settings
            </button>
          </template>
        </div>
      </div>

      <div class="inner p-2" :class="{ hidden: block.collapsed }">
        <rw-textarea
          v-if="block.blockType && block.blockType == 'text'"
          class="w-full"
          cols="30"
          rows="10"
          v-model="block.text"
        />

        <rw-image
          class="w-64 mx-auto"
          v-else-if="block.blockType && block.blockType == 'image'"
          v-model="block"
          context="teaser"
          :includePosition="true"
        />
        
        <component
          v-else-if="block.blockType"
          :is="getType(block.blockType)"
          v-model="block"
          :context="localPreview ? 'preview' : 'settings'"
          :activeTab="activeTab"
        />
      </div>
    </div>
    <button class="text-sm ml-2" @click="$emit('remove-item')">
      <fa-icon icon="trash-alt" />
    </button>
  </div>
</template>

<script>
import { reactive } from 'vue';
import defaultsDeep from 'lodash/defaultsDeep'

export default {
  name: 'ContentBuilderItem',
  props: ['modelValue', 'includePresets', 'tabs'],
  data() {
    return {
      //block: reactive(this.modelValue),
      activeTab: 'content',
      localPreview: false,
    }
  },
  computed: {
    block: {
      get() {
        return defaultsDeep(reactive(this.modelValue), {collapsed: false});
      },
      set(newVal) {
        this.$emit('update:modelValue', newVal);
      }
    }
  },
  // watch: {
  //   block: {
  //     deep: true,
  //     handler(val) {
  //       this.$emit('update:modelValue', val)
  //     },
  //   },
  // },
  methods: {
    toggleBlock() {
      this.block.collapsed = !this.block.collapsed
      //this.$set(this.block, 'collapsed', !this.block.collapsed)
    },
    updateImage($event, idx) {
      this.block[idx] = $event;
      //this.$set(this.block, idx, $event)
    },
    getType(blockType) {
      let blockName = blockType
        .match(/\w+/)[0]
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\s+/g, '-')
        .toLowerCase()
      return 'rw-' + blockName
    },
  },
}
</script>

<style>
</style>