import cloneDeep from 'lodash/cloneDeep'

// This file is structured from smallest to largest build blocks
// 1. Fields
// 2. Blocks
// 3. Containers
// 4. Sections

// Fields
export const link = {
  blockType: 'link',
  blockName: 'Link',
  blockLabel: '',
  blockDescription: 'Standard link',
  faIcon: 'link',
  type: 'custom',
  text: null,
  url: null,
  class: null,
  entryId: null,
  formAction: null,
  encrypt: null,
  formFields: []
}

export const image = {
  blockType: 'image',
  blockName: 'Image',
  blockLabel: '',
  blockDescription: 'Images from Cloudinary',
  // blockIcon: '/icons/image.png',
  faIcon: 'image',
  src: '',
  alt: '',
  caption: '',
  cloudinary: null,
  cloudinaryId: null,
  transforms: []
}

// Blocks
export const heading = {
  blockType: 'heading',
  blockName: 'Heading',
  faIcon: 'heading',
  level: 'h2',
  text: '',
}

export const text = {
  blockType: 'text',
  blockName: 'Text',
  faIcon: 'paragraph',
  text: '',
}

export const tiptap = {
  blockType: 'tipTap',
  blockName: 'TipTap',
  faIcon: 'newspaper',
  jsonContent: '',
  htmlContent: '',
}

export const tiptaptable = {
  blockType: 'tipTapTable',
  blockName: 'TipTapTable',
  faIcon: 'table',
  jsonContent: '',
  htmlContent: '',
}

export const entry = {
  type: '',
  text: null,
  url: null,
  class: null,
  entryId: null,
}

export const card = {
  blockType: 'card',
  blockName: 'Card',
  blockLabel: '',
  blockIcon: '/icons/card.png',
  blockDescription: 'Icon + Headline + Text',
  variant: 'standard',
  title: 'Title',
  text: 'Body text goes here',
  icon: null,
  link: cloneDeep(link),
}

export const content = {
  blockType: 'content',
  blockName: 'Content',
  blockLabel: '',
  blockIcon: '/icons/content.png',
  blockDescription: 'Just basic text - no formatting',
  text: '',
}

export const contentList = {
  blockType: 'contentList',
  blockName: 'Content List',
  blockLabel: '',
  blockIcon: '/icons/content-list.png',
  blockDescription: 'Headline + Text (repeating pairs)',
  title: '',
  children: [{ title: '', text: '' }],
}

export const cta = {
  blockType: 'cta',
  blockName: 'CTA',
  blockLabel: '',
  // blockIcon: "/icons/link-list.png",
  blockDescription: 'Headline + Text + Link',
  preTitle: '',
  title: '',
  subTitle: '',
  text: '',
  includeImage: false,
  image: cloneDeep(image),
  includeLink: false,
  link: cloneDeep(link),
}

export const galleryItem = {
  blockType: 'galleryItem',
  blockName: 'Gallery Item',
  blockLabel: '',
  blockIcon: '/icons/gallery-item.png',
  blockDescription: 'Image + Headline + Text',
  title: '',
  text: 'Add a role here',
  image: cloneDeep(image),
}

export const gradeUs = {
  blockType: 'gradeUs',
  blockName: 'Grade Us',
  blockLabel: '',
  blockIcon: null,
  blockDescription: 'Grade.us reviews or testimonials',
  variant: null,
  gradeUsKey: null,
  title: 'Help Us. Help Others.',
  subTitle: 'Tell us about your experience with our firm.',
  btnLabel: 'Review Us',
  reviewLimit: '10',
}

export const itemList = {
  blockType: 'itemList',
  blockName: 'Item List',
  blockLabel: '',
  blockIcon: '/icons/item-list.png',
  blockDescription: 'Lists of items (checked or dashed)',
  blockStyle: 'checkmarks',
  preTitle: '',
  title: '',
  subTitle: '',
  children: [],
}

export const listCard = {
  blockType: 'listCard',
  blockName: 'List Card',
  blockLabel: '',
  blockIcon: '/icons/list-card.png',
  blockDescription: 'Image + Headline + ItemList',
  preTitle: '',
  title: '',
  subTitle: '',
  children: [{ ...itemList }],
}

export const linkList = {
  blockType: 'linkList',
  blockName: 'Link List',
  blockLabel: '',
  blockIcon: '/icons/link-list.png',
  blockDescription: 'Headline + Link & Description',
  variant: null,
  title: '',
  text: '',
  children: [],
}

export const markdown = {
  blockType: 'markdown',
  blockName: 'Markdown',
  blockLabel: null,
  blockIcon: '/icons/markdown.png',
  blockDescription:
    'Styled content with <a href="https://www.markdownguide.org/cheat-sheet/" target="_blank">these options</a>',
  text: '',
}

export const html = {
  blockType: 'html',
  blockName: 'HTML "Ross" Block',
  blockLabel: null,
  blockIcon: '/icons/code-solid.png',
  blockDescription:
    'Use HTML to make customed blocks. <small>(Note: JS will be stripped)</small>',
  code: '',
}

export const video = {
  blockType: 'video',
  blockName: 'Video',
  blockLabel: null,
  blockIcon: null,
  faIcon: 'video',
  blockDescription: 'Embed videos',
  title: null,
  subTitle: null,
  caption: null,
  aspectRatio: 'ratio-16-9',
  videoUrl: null,
  video: {
    id: null,
    provider: null,
    mediaType: null,
    params: {},
  },
}

export const iframe = {
  blockType: 'iframe',
  blockName: 'IFrame',
  blockLabel: null,
  blockIcon: null,
  faIcon: 'external-link-square-alt',
  blockDescription: 'Embed IFrame',
  aspectRatio: 'none',
  iframeurl: null,
  iframe: {
    width: null,
    height: null,
  },
}

export const people = {
  blockType: 'people',
  blockName: 'People',
  blockLabel: null,
  blockIcon: null,
  faIcon: 'users',
  blockDescription: 'Add a group of people',
  groupId: 1,
}

export const dynamicElementBlock = {
  variant: null,
  blockType: 'dynamicElement',
  blockName: 'Dynamic Element',
  blockLabel: '',
  blockDescription: 'Add an element within a section',
  elementId: null,
}

export const blocks = [
  content,
  markdown,
  html,
  tiptap,
  image,
  link,
  card,
  contentList,
  galleryItem,
  gradeUs,
  itemList,
  linkList,
  listCard,
  cta,
  video,
  iframe,
  people,
  dynamicElementBlock,
]

export const simpleBlocks = {
  heading,
  // text, we don't want people to keep adding these
  tiptap,
  tiptaptable,
  image,
  cta,
  link,
}

export const productBlocks = {
  heading,
  // text, we don't want people to keep adding these
  tiptap,
  image,
  link,
  listCard,
  itemList,
  cta,
  video
}

export const contentBlocks = {
  heading,
  // text, we don't want people to keep adding these
  tiptap,
  image,
  listCard,
  linkList,
  video,
  cta,
  link,
}

// Containers
export const container = {
  blockType: 'container',
  blockName: 'Container',
  blockLabel: '',
  blockLayout: null,
  blockStyle: null,
  desktopWidth: null,
  childDesktopWidth: null,
  alignment: null,
  children: [],
}

const column = {
  blockType: 'column',
  blockName: 'Column',
  blockLabel: '',
  blockLayout: null,
  blockStyle: null,
  childDesktopWidth: null,
  alignment: null,
  children: [],
}

const banner = {
  blockType: 'banner',
  blockName: 'Banner',
  blockLabel: '',
  blockLayout: 'full-bleed',
  blockStyle: null,
  blockDescription: 'Big Images',
  image: cloneDeep(image),
}

export const containers = [container, banner]

// Sections
export const hero = {
  blockType: 'hero',
  blockName: 'Hero',
  blockLabel: '',
  variant: null,
  blockLayout: null,
  blockStyle: null,
  includeHero: null,
  bgStyle: 'bg-subtle',
  bgImage: cloneDeep(image),
  textAlign: 'center',
  preTitle: '',
  title: '',
  subTitle: '',
  text: '',
  link: cloneDeep(link),
}

export const section = {
  variant: null,
  blockType: 'section',
  blockName: 'Section',
  blockLabel: '',
  blockLayout: null,
  blockStyle: null,
  bgStyle: null,
  bgPosition: null,
  title: '',
  preTitle: '',
  subTitle: '',
  children: [cloneDeep(container)],
}

export const embed = {
  variant: null,
  blockType: 'embed',
  blockName: 'Content Package Embed - Deprecated',
  blockLabel: '',
  blockLayout: null,
  blockStyle: null,
  bgStyle: null,
  bgPosition: null,
  embedType: null,
  embedId: null,
  embedAutopilot: false,
  link: cloneDeep(link),
}

export const accountContentEmbed = {
  variant: null,
  blockType: 'accountContentEmbed',
  blockName: 'Account Content Package Embed',
  blockLabel: '',
  blockLayout: null,
  blockStyle: null,
  bgStyle: null,
  bgPosition: null,
  embedType: null,
  embedId: null,
  link: cloneDeep(link),
}

export const dynamicElement = {
  variant: null,
  blockType: 'dynamicElement',
  blockName: 'Dynamic Element (Section)',
  blockLabel: '',
  elementId: null,
}

export const contentSet = {
  variant: null,
  blockType: 'contentSet',
  blockName: 'Content Set',
  blockLabel: '',
  contentSetId: null,
  prepend: [],
  append: [],
}

// export const sections = [section, hero, embed, products, contentSet, dynamicElement]
export const sections = [section, embed, accountContentEmbed, contentSet, dynamicElement]

export const sectionTemplates = [
  {
    ...section,
    blockLabel: 'Section > Cards',
    children: [
      {
        ...container,
        blockLabel: 'Cards',
        blockLayout: 'columns',
        blockStyle: 'cards',
        children: [
          { card: cloneDeep(card), link: cloneDeep(link) },
          { card: cloneDeep(card), link: cloneDeep(link) },
          { card: cloneDeep(card), link: cloneDeep(link) },
          { card: cloneDeep(card), link: cloneDeep(link) },
        ],
      },
    ],
  },
  {
    ...section,
    blockLabel: 'Section > Teasers',
    children: [
      {
        ...container,
        blockLabel: 'Cards',
        blockLayout: 'columns',
        blockStyle: 'cards',
        children: [
          { card: cloneDeep(card), variant: 'teaser', link: cloneDeep(link) },
          { card: cloneDeep(card), variant: 'teaser', link: cloneDeep(link) },
          { card: cloneDeep(card), variant: 'teaser', link: cloneDeep(link) },
          { card: cloneDeep(card), variant: 'teaser', link: cloneDeep(link) },
        ],
      },
    ],
  },
  {
    ...section,
    blockLabel: 'Section > Gallery',
    children: [
      {
        ...container,
        blockLayout: 'grid',
        childDesktopWidth: 'quarters',
        children: [cloneDeep(galleryItem), cloneDeep(galleryItem), cloneDeep(galleryItem)],
      },
    ],
  },
]

export const customBlocks = [
  { blockType: 'formEmbed', blockName: 'Form Embed' },
  { blockType: 'resourceLibrary', blockName: 'Resource Library', elementTypes: [], itemCount: '3' },
  { blockType: 'offices', blockName: 'Offices' },
  {
    blockType: 'linkSelector',
    blockName: 'Link Selector',
    title: null,
    text: null,
    defaultOption: 'Select an option',
    selectLabel: null,
  },
  {
    blockType: 'vendors',
    blockName: 'Vendors',
    blockLayout: 'logos',
    children: [],
  },
]

export const variants = {
  listCard: { '': 'None', punch: 'Punch' },
  itemList: { '': 'None', checkmarks: 'Checkmarks', dashes: 'Dashes' },
  vendors: { logos: 'Logos', goMobile: 'GoMobile' },
}

export const blockLayouts = {
  banner: {
    'full-bleed': 'Full Bleed',
    container: 'Contained',
  },
  container: {
    grid: 'Grid',
    columns: 'Equal columns (flex)',
    columnsCustom: 'Custom columns',
  },
}

export const blockStyles = {
  section: {
    'bg-subtle': 'Background - Subtle',
    'bg-primary': 'Background - Primary',
  },
  banner: {
    'btm-pull': 'Bottom - Pull',
  },
  container: { cards: 'Cards' },
  listCard: { card: 'Card' },
}
