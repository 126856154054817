export default {
  methods: {
    // this is called when we "insert" from the preset modal
    insertPreset($event, idx) {
      console.log(`${this.$options.name} insertPreset mixin`)
      let newBlock = Object.assign({}, structuredClone($event))
      this.block.children[idx] = newBlock
      //this.$set(this.block.children, idx, newBlock)

      // Activate this block in Block Settings
      this.$nextTick(function() {
        console.log('insert');
        let ref = `childBlock:${idx}`
        
        this.$refs[ref].editBlockContent()
      })
    },
  },
}
