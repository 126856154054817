import gql from 'graphql-tag'

let GlobalLinksDetails = gql`
  fragment GlobalLinksDetails on GlobalLink {
    id
    slug
    link_type
    value_pairs
    created_at
    updated_at
  }
`

export const GLOBAL_LINK_DETAILS = GlobalLinksDetails

export const GLOBAL_LINK = gql`
  query GlobalLink($id: ID!) {
    globalLink(id: $id) {
      ...GlobalLinksDetails
    }
  }
  ${GlobalLinksDetails}
`

export const ALL_GLOBAL_LINKS = gql`
  query GlobalLinks {
    globalLinks {
      ...GlobalLinksDetails
    }
  }
  ${GlobalLinksDetails}
`

export const CREATE_GLOBAL_LINK = gql`
  mutation CreateGlobalLink($input: CreateGlobalLinkInput!) {
    createGlobalLink(input: $input) {
      ...GlobalLinksDetails
    }
  }
  ${GlobalLinksDetails}
`

export const UPDATE_GLOBAL_LINK = gql`
  mutation UpdateGlobalLink($input: UpdateGlobalLinkInput!) {
    updateGlobalLink(input: $input) {
      ...GlobalLinksDetails
    }
  }
  ${GlobalLinksDetails}
`

export const DELETE_GLOBAL_LINK = gql`
  mutation DeleteGlobalLink($id: ID!) {
    deleteGlobalLink(id: $id) {
      id
    }
  }
`
