<template>
  <div v-if="type == 'error'" class="inline-alert error" role="alert">
    <strong>Something bad happened:</strong>
    {{ message }}
  </div>

  <div v-else-if="type == 'success'" class="inline-alert success" role="alert">
    <strong>Success!</strong>
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'AlertMessage',
  props: {
    type: String,
    message: String,
    link: Object,
  },
}
</script>

<style>
</style>