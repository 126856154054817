<template>
  <button @click="publishEntry" :disabled="loading">
    <spinner v-if="loading" class="mr-2" />
    <span>{{btnText}}</span>
  </button>
</template>

<script>
export default {
  name: "EntryPublish",
  props: ["text", "entryID"],
  data() {
    return {
      loading: false,
      btnText: this.text
    };
  },
  created() {
    let self = this;
    Echo.private('entry.' + this.entryID)
      .listenForWhisper('publishing', evt => {
        self.loading = true;
      })
      .listen('.publish.success', evt => {
        self.loading = false
      })
      .listen(".publish.failed", evt => {
        self.loading = false
      });
  },
  methods: {
    publishEntry() {
      this.loading = true;
      Echo.private("entry." + this.entryID).whisper("publishing");
      this.$api
        .post("/entries/publish-entry", {
          issue_id: this.entryID,
          remote: this.publishToRemote
        })
        .then(function(response) {
          console.log("Publish button clicked");
          console.log(response);
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.rotate-180 {
  transform: rotate(180deg);
}
.btn-go :disabled,
.btn-go .spinner {
  color: #fff;
}
</style>