<template>
  <div>
    <button :class="btnClass" @click.prevent="prepModal">{{ btnText }}</button>
    <div class="my-2">
      <strong>Currently selected:</strong><br/>
      <ul class="list-disc list-inside">
          <li v-for="(val, key) in getEntryList" class="my-1">
            {{ val }} 
          </li>
        </ul>
      </div>
    <modal-dialog :show="showModal" @close="closeModal">
      <h3 slot="header">{{ btnText }}</h3>
      <div>
        <rw-select 
          v-model="newEntry" 
          label="Select Entry" 
          :includeNull="true" 
          :options="getEntries"
          :disabled="maxReached" />
      </div>
      <div class="flex justify-end">
        <button 
        @click="addEntry" 
        :disabled="maxReached || newEntry == null"
        class="btn btn-go mt-3">
          Add To List
        </button>
      </div>
      <p v-if="maxReached" class="my-3">
        You have reached the max amount of entries you can select. Please remove an
        entry before adding a new one.
      </p>
      <p v-else class="my-3 font-bold">Please make sure you select {{ maxEntries }} entries.</p>
      <div v-if="!this.$apollo.loading">
        <strong>Current Entries</strong>
        <ul>
          <li v-for="(val, key) in getEntryList" class="flex justify-between my-3">
            {{ val }} 
            <button 
              class="btn btn-stop btn-sm ml-3" 
              @click="removeEntry(key)">Remove
            </button>
          </li>
        </ul>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import { ALL_ENTRIES } from '@/graphql/entries'
import ModalDialog from "@/components/utils/ModalDialog"

export default {
  name: "ContentPackageEntryOverride",
  props: {
    btnText: String,
    btnClass: String,
    entryType: String,
    currentEntries: Array,
    siteId: String,
    maxEntries: {
      type: Number,
      default: 1
    },
  },
  components: { ModalDialog },
  data() {
    return {
      showModal: false,
      entries: [],
      newEntry: null,
    }
  },
  apollo: {
    allEntries: {
      fetchPolicy: 'no-cache',
      query: ALL_ENTRIES,
      variables() {
        return {
          type: this.entryType,
          site_id: this.siteId,
        }
      },
      result({data: {allEntries}}) {
        this.entries = allEntries;

        this.entries.sort((a, b) => (a.content.title > b.content.title) ? 1 : -1);
      }
    },
  },
  computed: {
    // Complete list in an easier form.
    getEntries() {
      if (this.entries.length) {
        let list = {};
        this.entries.forEach(element => {
          list[element.id] = element.content.title;
        });

        return list;
      }

      return {};
    },
    maxReached() {
      const currentCount = this.currentEntries == null ? 0 : this.currentEntries.length;

      return currentCount >= this.maxEntries ? true : false;
    },
    // Selected entries.
    getEntryList() {
      let titleArray = {};
      this.currentEntries.map((index, val) => {
        if (this.getEntries[index] !== undefined) {
          titleArray[index] = this.getEntries[index];
        } else {
          titleArray[index] = "No longer part of this package.";
        }
      });
      
      return titleArray;
    }
  },
  methods: {
    prepModal() {
      this.$apollo.queries.allEntries.refetch()
      this.showModal = !this.showModal;
    },
    closeModal() {
      this.showModal = false;
    },
    addEntry() {
      if (this.currentEntries.indexOf(this.newEntry) == -1) {
        this.currentEntries.push(this.newEntry);
      }
    },
    removeEntry(entryId) {
      const entryKey = this.currentEntries.findIndex(el => el === entryId);
      this.currentEntries.splice(entryKey, 1);
    }
  }
}
</script>