<template>
  <div class="flex py-3 text-sm" v-if="totalPages > 1">
    <div class="w-1/2 pr-4">
      View
      <select class="mx-2" v-model="fields.selectedPerPage" @change="resetPage">
        <option 
          v-for="ppo in perPageOptions" 
          :value="ppo" 
          :key="ppo">{{ ppo }}</option>
      </select>
      per page
    </div>
    <div class="w-1/2 pl-4 text-right">
      <button v-if="fields.selectedPage > 1" @click="pageUpdate(currentPage - 1)"
        class="text-link p-2 page-link">
        <fa-icon icon="less-than" class="fa-lg" />
      </button>
      <div class="inline-block mx-2">
        <span v-for="pageNum in pageDisplay" :key="pageNum">
          <button @click="pageUpdate(pageNum)" v-if="!isNaN(pageNum) && pageNum != null"
            class="text-link p-2 page-link"
            :class="{'active': pageNum == currentPage}">
            {{ pageNum }}
          </button>
          <span class="inline-block p-2" v-else-if="pageNum !== null">{{ pageNum }}</span>
        </span>
      </div>
      <button v-if="fields.selectedPage != totalPages" 
        @click="pageUpdate(currentPage + 1)"
        class="text-link p-2 page-link">
          <fa-icon icon="greater-than" class="fa-lg" />
        </button>
      <p>Showing <strong>{{ first }}-{{ last }} of {{ maxCount }}</strong> {{ typeTitle }}{{ typeTitle ? '.' : '' }}</p>
    </div>
  </div>
</template>

<script>
import {numberRange, numberSort} from '@/helpers.js'

export default {
  name: 'AcorenPagination',
  props: {
    typeTitle: {
      type: String,
      default: null
    },
    totalPages: {
      type: Number,
      default: 1
    },
    maxCount: {
      type: Number,
      default: 1
    },
    currentPage: {
      type: Number,
      default: 1
    },
    perPage: {
      type: Number,
      default: 25
    },
  },
  data() {
    return {
      perPageOptions: [25, 50, 100],
      fields: {
        selectedPage: this.currentPage,
        selectedPerPage: this.perPage
      },
    }
  },
  computed: {
    first() {
      const start = Math.max(((this.fields.selectedPage-1) * this.fields.selectedPerPage), 1)

      return start === 1 ? start : start+1;
    },
    last() {
      const last = this.fields.selectedPage * this.fields.selectedPerPage;
      return last > this.maxCount ? this.maxCount : last;
    },
    pageDisplay() {
      let pageList = [];

      if (this.totalPages <= 5) { // If total page is 5, just list all the pages.
        pageList = [...numberRange(1, this.totalPages)]
      } else {
        let start = this.currentPage;
        if (start === 1) { // List 1 - 4.
          pageList = [...numberRange(2, 4), '...'];
        } else if (start == this.totalPages) {
          // At the last bit of pages.
          pageList = ['...', ...numberRange(start-3, start-1)];
        } else {
          const last = start + 1;
          const lastTrail = last <= (this.totalPages - 2) ? '...' : null;
          --start;
          const startTrail = start <= 2 ? null : '...';
          pageList = [startTrail, ...numberRange(start, last), lastTrail];
        }

        !pageList.includes(1) ? pageList.unshift(1) : null;
        !pageList.includes(this.totalPages) ? pageList.push(this.totalPages) : null;
      }   

      return pageList;
    },
  },
  methods: {
    resetPage($event) {
      this.fields.selectedPage = 1;
      this.handleChange();
    },
    pageUpdate(page) {
      this.fields.selectedPage = page;
      this.handleChange();
    },
    handleChange() {
      this.$emit('dataUpdate', this.fields);
    },
  },
}
</script>

<style scoped>
.active,
.page-link:hover {
  font-weight: 700;
}
</style>