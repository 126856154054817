<template>
  <div class="flex -mx-2 items-end">
    <div class="flex-1 w-1/2 px-2">
      <label for="from-site">Load data from:</label>
      <select name="from-site" id="from-site" v-model="copyFrom" class="w-full">
        <option v-for="site in sites" :key="site.id" :value="site.id">{{ site.title }}</option>
      </select>
    </div>
    <div class="flex-1 px-2">
      <button class="btn btn-go" @click="loadDefaultContent">Load Content</button>
    </div>
  </div>
</template>

<script>
import { SITE_LIST } from '@/graphql/sites'

export default {
  name: 'SiteLoadContent',
  props: ['siteID'],
  data() {
    return {
      copyFrom: null,
    }
  },
  apollo: {
    sites: {
      fetchPolicy: 'no-cache',
      query: SITE_LIST,
      result({ data: { sites } }) {
        let self = this
        let filteredSites = sites.filter(item => {
          return item.nested_set && this.siteID !== item.id
        })
        this.sites = filteredSites
      },
    },
  },
  methods: {
    loadDefaultContent() {
      let self = this

      if (confirm('Loading content will append new pages to this site. Are you sure?')) {
        this.$api
          .post('site', {
            action: 'duplicatePages',
            site_id: self.siteID,
            from_site_id: self.copyFrom,
          })
          .then(response => {
            self.$emit('content-loaded')
            const found = self.sites.find(site => {
              return site.id === self.copyFrom
            })
            const title = found ? found.title : 'Error'
            self.$toasted.success(`Content Loaded from ${title}`)
          })
      }
    },
  },
}
</script>