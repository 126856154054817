<template>
  <div v-if="!$apollo.loading">
    <div v-if="showUsage" class="my-2 bg-gray-300 p-3 rounded-md">
      <p class="font-bold">Usage</p>
      <p v-if="entry.site_id" class="font-bold">Site: {{ entry.site_id }}</p>
      <ul v-if="entry.magazineIssues.length">
        <li
          class="flex justify-between mb-1"
          v-for="magazine in entry.magazineIssues"
          :key="magazine.id"
        >
          <span class="capitalize">{{magazine.type}}:&nbsp;</span>
          {{ formatDate(magazine.published_at, 'MMMM YYYY') }}
          <router-link
            v-if="editable"
            class="btn btn-sm btn-go ml-auto"
            :to="{ 'name': 'IssueEdit', params: {'magazineID': magazine.id }}"
          >Edit</router-link>
        </li>
      </ul>

      <!-- Content Packages -->
      <template v-if="entry.contentPackages.length">
        <p class="m-0">
          <strong>Content Packages:</strong>
        </p>
        <ul>
          <li
            class="flex justify-between items-center mb-1"
            v-for="contentPkg in entry.contentPackages"
            :key="contentPkg.id"
          >
            <span>{{ contentPkg.title }} - {{ contentPkg.type }}</span>
            <router-link
              v-if="editable"
              class="btn btn-go btn-sm"
              :to="{ name: 'ContentPkgEdit', params: { packageID: contentPkg.id }}"
            >Edit</router-link>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import { ENTRY_USAGE } from '@/graphql/entries'
export default {
  name: 'EntryUsage',
  props: ['entryID', 'editable'],
  apollo: {
    entry: {
      query: ENTRY_USAGE,
      variables() {
        return {
          entryID: this.entryID,
        }
      },
    },
  },
  computed: {
    showUsage() {
      return (
        !this.$apollo.loading &&
        (this.entry.site_id || this.entry.magazineIssues.length || this.entry.contentPackages.length)
      )
    },
  },
}
</script>