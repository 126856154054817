<template>
  <div>
    <button :class="btnClasses" @click.prevent="showModal = true">{{ btnLabel }}</button>
    <modal-dialog :show="showModal" @close="closeModal">
      <template #header><h3 slot="header">Create Item</h3></template>

      <alert-message v-bind="alert" />

      <form id="create-item" class="space-y-2" @keyup.enter="createItem">
        <rw-text v-model="first_name" label="First Name" />
        <rw-text v-model="last_name" label="Last Name" />
        <rw-text v-model="slug" label="Slug" />
      </form>

      <template #footer>
        <div class="flex justify-end">
          <button class="btn btn-go" @click.prevent="createItem">Create Person</button>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import slugify from '@sindresorhus/slugify'
import { UPSERT_PERSON } from '@/graphql/people'

export default {
  name: 'PersonCreate',
  props: ['btnText', 'btnClass', 'contentSetID'],
  data() {
    return {
      showModal: false,
      alert: {
        type: null,
        message: null,
      },
      first_name: '',
      last_name: '',
    }
  },
  computed: {
    btnClasses() {
      return this.btnClass || 'btn btn-go'
    },
    btnLabel() {
      return this.btnText || 'Create Person'
    },
    slug: {
      get() {
        return slugify(`${this.first_name} ${this.last_name}`)
      },
      set(val) {
        return val
      },
    },
  },
  methods: {
    closeModal() {
      this.showModal = false
      this.alert.type = null
      this.alert.message = null
    },
    createItem() {
      this.alert.type = null
      this.alert.message = null
      this.$apollo
        .mutate({
          mutation: UPSERT_PERSON,
          variables: {
            input: {
              personable_id: this.contentSetID,
              personable_type: 'content-set',
              first_name: this.first_name,
              last_name: this.last_name,
              slug: this.slug,
            },
          },
        })
        .then(({ data: { createPerson } }) => {
          this.alert.type = 'success'
          this.alert.message = `Person created: ${createPerson.first_name} ${createPerson.last_name}`
          this.$emit('created', createPerson)
          this.first_name = ''
          this.last_name = ''
          this.slug = ''
          document.querySelector('#create-item input[type="text"]').focus()
        })
        .catch((error) => {
          this.alert.type = 'error'
          this.alert.message = error.message
        })
    },
  },
}
</script>

<style lang="scss">
</style>