<template>
  <div v-if="context == 'preview'">
    <div class="bg-gray-200">
      <div class="responsive-container w-full" data-placeholder="People Block">
        <div class="w-full flex flex-wrap" :class="peopleAlignClass" v-if="block.layout == 'with-vertical-images'">
          <div :class="block.columnSize" class="my-3 p-3" v-for="(person, id) in getCurrentGroupPeople" :key="id">
            <cloudinary-image
              v-if="person.image !== null && person.image.cloudinaryId" 
              :publicId="person.image.cloudinaryId" 
              :key="person.image.cloudinaryId"
            />
            <!-- <cld-image 
              v-if="person.image !== null && person.image.cloudinaryId" 
              :publicId="person.image.cloudinaryId" 
              :key="person.image.cloudinaryId">
              <template v-if="Object.keys(person.image.transforms).length">
                <cld-transformation
                  v-for="(transform, idx) in person.image.transforms"
                  :key="idx"
                  v-bind="transform"
                />
              </template>
              <cld-transformation v-else width="384" height="384" crop="fit" />
            </cld-image> -->
            <div v-else class="cld-image">
              <img src="/images/grumpy-placeholder.jpeg" />
            </div>
            <div :class="block.textAlign">
              <p class="h4 mb-0">{{ getFullName(person) }}</p>
              <p class="italic ">{{ person.job_title }}</p>
            </div>
          </div>
        </div>
        <div v-else class="flex-grow">
          <div class="max-width">
            <ul class="">
              <li class="my-3 p-3" v-for="(person, id) in getCurrentGroupPeople" :key="id">
                <div class="space-y-12 sm:grid sm:grid-cols-3">
                  <div class="relative pb-2/3 pr-4">
                    <cloudinary-image
                      v-if="person.image !== null && person.image.cloudinaryId" 
                      :publicId="person.image.cloudinaryId" 
                      :key="person.image.cloudinaryId"
                    />
                    <!-- <cld-image 
                      v-if="person.image !== null && person.image.cloudinaryId" 
                      :publicId="person.image.cloudinaryId" 
                      :key="person.image.cloudinaryId">
                      <template v-if="Object.keys(person.image.transforms).length">
                        <cld-transformation
                          v-for="(transform, idx) in person.image.transforms"
                          :key="idx"
                          v-bind="transform"
                        />
                      </template>
                      <cld-transformation v-else width="384" height="384" crop="fit" />
                    </cld-image> -->
                    <div v-else class="cld-image">
                      <img src="/images/grumpy-placeholder.jpeg" />
                    </div>
                  </div>
                  <div class="col-span-2">
                    <h3 class="h4">{{ getFullName(person) }}</h3>
                    <p class="text-primary font-medium text-lg">{{ person.job_title }}</p>
                    <div class="prose lg:prose-lg">
                      {{ person.short_bio }}
                    </div>
                  </div>                 
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="space-y-2">
    <div class="flex space-x-2">
      <rw-select 
        v-model="block.groupId" 
        :includeNull="false" 
        layout="inline" 
        :options="groupSelects" 
        label="Group ID" 
      />
    </div>
    <div class="flex space-x-2">
      <rw-select 
        v-model="block.layout" 
        :includeNull="false" 
        layout="inline" 
        :options="layoutSelects" 
        label="Layout" 
      />
    </div>
    <div class="flex space-x-2">
      <rw-select 
        v-if="block.layout == 'with-vertical-images'"
        v-model="block.columnSize" 
        :includeNull="false" 
        layout="inline" 
        :options="columnSizeSelects" 
        label="Amt. per a row" 
      />
    </div>
    <div class="flex space-x-2">
      <rw-select 
        v-if="block.layout == 'with-vertical-images'"
        v-model="block.textAlign" 
        :includeNull="false" 
        layout="inline" 
        :options="textAlign" 
        label="Text Align" 
      />
    </div>
    <div class="flex space-x-2">
      <rw-select 
        v-if="block.layout == 'with-vertical-images'"
        v-model="block.peopleAlign" 
        :includeNull="false" 
        layout="inline" 
        :options="peopleAlign" 
        label="People Align" 
      />
    </div>
  </div>
</template>
<script>
import { SITE_CONTENT_SETS_PEOPLE } from '@/graphql/content-sets'
import { maxPeopleGroup } from '@/settings/enums'
import CloudinaryImage from '../utils/CloudinaryImage.vue'

export default {
  components: { CloudinaryImage },
  name: 'People',
  mixins: [BlockMixin],
  data() {
    return {
      maxPeopleGroup: maxPeopleGroup,
      siteID: null,
      people: null,
    }
  },
  apollo: {
    contentSets: {
      fetchPolicy: 'no-cache',
      query: SITE_CONTENT_SETS_PEOPLE,
      variables() {
        return {
          types: ['people'],
          siteID: this.siteID,
        }
      },
      result({data: {contentSets}}) {
        // Takes the results of the content sets and digs deep to pull
        // the people out. 3 degrees of seperation baby!
        const peopleSet = contentSets[0].items;

        this.people = peopleSet.map((details) => {
          if (details.person.first_name == null) {
            return ;
          }

          return {
            id: details.person.id,
            name: details.person.first_name+' '+details.person.last_name,
            short_bio: details.person.short_bio,
            is_enabled: details.enabled,
            group_id: details.person.group_id,
            job_title: details.person.job_title,
            suffix: details.person.suffix,
            image: details.person.image,
            _lft: details._lft,
          }
        });

        // Filter out the people who dont' have a people object and only a content item.
        this.people = this.people.filter(person => person !== undefined);
        this.people.sort((a, b) => (a._lft > b._lft) ? 1 : -1);
      }
    },
  },
  watch: {
    block: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  mounted() {
    this.siteID = this.$route.params.siteID;
  },
  computed : {
    groupSelects() {
      let opts = Array.from(Array(this.maxPeopleGroup).keys(), n => n + 1);

      // Setting the custom ID.
      opts = opts.reduce((prev, curr) => ({... prev, [curr]: curr}), {});

      return {... opts};
    },
    columnSizeSelects() {
      return {
          'md:w-1/5': '5 Person Row',
          'md:w-1/4': '4 Person Row',
          'md:w-1/3': '3 Person Row',
          'md:w-1/2': '2 Person Row',
      };
    },
    layoutSelects() {
      return {
        "with-vertical-images": "With Vertical Images",
        "with-image-and-paragraph": "With Image and Paragraph",
      };
    },
    textAlign() {
      return {
        "text-left": "Left Align",
        "text-center": "Center Align",
        "text-right": "Right Align",
      };
    },
    peopleAlign() {
      return {
        "people-left": "Left Align",
        "people-center": "Center Align",
        "people-right": "Right Align",
      };
    },
    getCurrentGroupPeople() {
      if (this.people == null) {
        return [];
      }

      let filtered = this.people.filter(person => (person.group_id == this.block.groupId && person.is_enabled));

      return filtered.sort((a, b) => (a._lft > b._lft) ? 1 : -1);
    },
    peopleAlignClass() {
      if (this.block.peopleAlign) {
        if (this.block.peopleAlign == 'people-center') {
          return 'justify-center';
        } else if(this.block.peopleAlign == 'people-right') {
          return 'justify-end';
        }
      }

      return '';
    }
  },
  methods: {
    getFullName(person) {
      let name = person.name;

      name += (person.suffix ? ', ' + person.suffix: '')

      return name;
    },
  },
}
</script>