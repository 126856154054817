<template>
  <div>
    <button :class="btnClasses"  v-if="canCreate" @click.prevent="showModal = true">{{btnLabel}}</button>
    <modal-dialog :show="showModal" @close="closeModal">
      <template #header><h3 slot="header">Create Item</h3></template>

      <alert-message v-bind="alert" />

      <form id="create-item" class="space-y-2" @keyup.enter="createItem">
        <rw-text v-model="menu_label" label="Title" placeholder="Enter the entry title..." />
        <rw-text v-model="menu_slug" label="Slug" placeholder="Enter the entry slug..." />
      </form>

      <template #footer>
        <div class="flex justify-end">
          <button class="btn btn-go"  @click.prevent="createItem">Create Item</button>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import slugify from '@sindresorhus/slugify'
import { CREATE_CONTENT_SET_ITEM } from '@/graphql/content-sets'

export default {
  name: 'ContentSetItemCreate',
  props: ['btnText', 'btnClass', 'contentSetID', 'canCreate'],
  data() {
    return {
      showModal: false,
      menu_label: '',
      alert: {
        type: null,
        message: null,
      },
    }
  },
  computed: {
    btnClasses() {
      return this.btnClass || 'btn btn-go'
    },
    btnLabel() {
      return this.btnText || 'Create Item'
    },
    menu_slug: {
      get() {
        return slugify(this.menu_label)
      },
      set(val) {
        return val
      },
    },
  },
  methods: {
    closeModal() {
      this.showModal = false
      this.alert.type = null
      this.alert.message = null
    },
    createItem() {
      this.alert.type = null
      this.alert.message = null
      this.$apollo
        .mutate({
          mutation: CREATE_CONTENT_SET_ITEM,
          variables: {
            input: {
              content_set_id: this.contentSetID,
              menu_label: this.menu_label,
              menu_slug: this.menu_slug,
            },
          },
        })
        .then(({ data: { createContentSetItem } }) => {
          this.alert.type = 'success'
          this.alert.message = `Item created with the title: ${createContentSetItem.menu_label}`
          this.$emit('created', createContentSetItem)
          this.menu_label = ''
          this.menu_slug = ''
          document.querySelector('#create-item input[type="text"]').focus()
        })
        .catch((error) => {
          this.alert.type = 'error'
          this.alert.message = error.message
        })
    },
  },
}
</script>

<style lang="scss">
</style>