<template>
  <div v-if="context == 'preview'">
    <div class="bg-gray-200">
      <div
        class="placeholder responsive-container w-full"
        :class="block.aspectRatio"
        data-placeholder="IFrame"
      >
        <fa-icon icon="external-link-square-alt" class="text-6xl text-gray-600" />
      </div>
    </div>
  </div>

  <div v-else class="space-y-2">
    <div class="flex space-x-2">
      <rw-text
        class="w-1/2"
        v-model="block.iframeurl"
        placeholder="Enter the full url"
        label="URL"
      />
      <rw-select v-model="block.aspectRatio" :options="aspectRatios" label="Aspect Ratio" />
    </div>
    <div class="flex space-x-2">
      <rw-text
        class="w-1/2"
        v-model="block.iframe.width"
        placeholder="(ie. 100% or 300)"
        label="Width"
      />
      <rw-text
        class="w-1/2"
        v-model="block.iframe.height"
        placeholder="(ie. 100% or 300)"
        label="Height"
      />
    </div>
    <div class="pt-5"> 
      <light-switch v-model="block.useCta"><template #label>Use CTA button on mobile</template></light-switch>
      <div v-if="block.useCta" class="space-y-2">
        <h6>CTA Button Settings</h6>
        <rw-text v-model="block.ctaUrl" label="CTA Button URL" />
        <rw-text v-model="block.ctaButtonText" label="CTA Button Text" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Iframe',
  mixins: [BlockMixin],
  data() {
    return {
      aspectRatios: {
        'none': 'None',
        'ratio-16-9': '16:9',
        'ratio-8-5': '8:5',
        'ratio-4-3': '4:3',
        'ratio-3-2': '3:2',
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.placeholder svg {
  filter: blur(2px);
}

.placeholder::before {
  content: attr(data-placeholder) '\APlaceholder';
}

.responsive-container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  max-height: 300px;

  &.ratio-16-9 {
    padding-bottom: 56.25%;
  }

  &.ratio-4-3 {
    padding-bottom: 75%;
  }

  &.w-full {
    padding-bottom: 25%;
  }

  .fa-external-link-square-alt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
</style>