import gql from 'graphql-tag'

import { OFFICE_DETAILS_FRAGMENT } from './offices'
import { PEOPLE_DETAILS_FRAGMENT } from './people'

let ContentSetItemDetails = gql`
  fragment ContentSetItemDetails on ContentSetItem {
    id
    element_id
    enabled
    include_detail
    menu_label
    menu_slug
    menu_redirect
    override_seo
    hero_override
    seo
    account_id
    description
    settings
    contentSet {
      id
      type
      settings
    }
  }
`

export const CONTENT_SET_ITEM_FRAGMENT = ContentSetItemDetails

let ContentSetDetails = gql`
  fragment ContentSetDetails on ContentSet {
    id
    title
    type
    settings
    hero_override
    created_at
    updated_at
    items {
      ...ContentSetItemDetails
      element {
        id
        slug
        type
        menu_slug
        menu_label
        content {
          id
          title
        }
      }
    }
  }
  ${ContentSetItemDetails}
`

let ContentSetDetailsPeople = gql`
  fragment ContentSetDetailsPeople on ContentSet {
    id
    title
    type
    settings
    created_at
    updated_at
    items {
      ...ContentSetItemDetails
      _lft
      person {
        ...PeopleDetails
      }
    }
  }
  ${ContentSetItemDetails}
  ${PEOPLE_DETAILS_FRAGMENT}
`

export const CONTENT_SET_DETAILS = ContentSetDetails

export const CONTENT_SET = gql`
  query ContentSet($id: ID!) {
    contentSet(id: $id) {
      ...ContentSetDetails
    }
  }
  ${ContentSetDetails}
`

export const ALL_CONTENT_SETS = gql`
  query AllContentSets($types: [String]) {
    contentSets(types: $types, where: { column: SITE_ID, operator: IS_NULL }) {
      ...ContentSetDetails
    }
  }
  ${ContentSetDetails}
`

export const GLOBAL_CONTENT_SETS = gql`
  query GlobalContentSets($types: [String]) {
    contentSets(types: $types, where: { column: SITE_ID, operator: IS_NULL }) {
      ...ContentSetDetails
    }
  }
  ${ContentSetDetails}
`

export const SITE_CONTENT_SETS = gql`
  query SiteContentSets($types: [String], $siteID: Mixed) {
    contentSets(types: $types, where: { column: SITE_ID, operator: EQ, value: $siteID }) {
      ...ContentSetDetails
    }
  }
  ${ContentSetDetails}
`

export const SITE_CONTENT_SETS_PEOPLE = gql`
  query SiteContentSets($types: [String], $siteID: Mixed) {
    contentSets(types: $types, where: { column: SITE_ID, operator: EQ, value: $siteID }) {
      ...ContentSetDetailsPeople
    }
  }
  ${ContentSetDetailsPeople}
`

export const CREATE_CONTENT_SET = gql`
  mutation CreateContentSet($input: CreateContentSetInput!) {
    createContentSet(input: $input) {
      ...ContentSetDetails
    }
  }
  ${ContentSetDetails}
`

export const UPDATE_CONTENT_SET = gql`
  mutation UpdateContentSet($input: UpdateContentSetInput!) {
    updateContentSet(input: $input) {
      ...ContentSetDetails
    }
  }
  ${ContentSetDetails}
`

export const DELETE_CONTENT_SET = gql`
  mutation DeleteContentSet($id: ID!) {
    deleteContentSet(id: $id) {
      id
    }
  }
`

export const CONTENT_SET_ITEM = gql`
  query ContentSetItem($id: ID!) {
    contentSetItem(id: $id) {
      ...ContentSetItemDetails
    }
  }
  ${ContentSetItemDetails}
`

export const CREATE_CONTENT_SET_ITEM = gql`
  mutation CreateContentSetItem($input: CreateContentSetItemInput!) {
    createContentSetItem(input: $input) {
      id
      menu_label
      menu_slug
      menu_redirect
      override_seo
      seo
    }
  }
`

export const UPDATE_CONTENT_SET_ITEM = gql`
  mutation UpdateContentSetItem($input: UpdateContentSetItemInput!) {
    updateContentSetItem(input: $input) {
      ...ContentSetItemDetails
    }
  }
  ${ContentSetItemDetails}
`

export const DELETE_CONTENT_SET_ITEM = gql`
  mutation DeleteContentSetItem($id: ID!) {
    deleteContentSetItem(id: $id) {
      id
    }
  }
`

let PeopleSetItemDetails = gql`
  fragment PeopleSetItemDetails on ContentSetItem {
    ...ContentSetItemDetails
    person {
      ...PeopleDetails
    }
  }
  ${ContentSetItemDetails}
  ${PEOPLE_DETAILS_FRAGMENT}
`

export const PEOPLE_SET_ITEM_FRAGMENT = PeopleSetItemDetails

export const PEOPLE_SET_ITEM = gql`
  query PeopleSetItem($id: ID!) {
    contentSetItem(id: $id) {
      ...PeopleSetItemDetails
    }
  }
  ${PeopleSetItemDetails}
`

export const UPDATE_PEOPLE_SET_ITEM = gql`
  mutation UpdatePeopleSetItem($input: UpdateContentSetItemInput!) {
    updateContentSetItem(input: $input) {
      ...PeopleSetItemDetails
    }
  }
  ${PeopleSetItemDetails}
`

let LocationSetItemDetails = gql`
  fragment LocationSetItemDetails on ContentSetItem {
    ...ContentSetItemDetails
    office {
      ...OfficeDetails
    }
    people {
      ...PeopleDetails
    }
  }
  ${ContentSetItemDetails}
  ${OFFICE_DETAILS_FRAGMENT}
  ${PEOPLE_DETAILS_FRAGMENT}
`

export const LOCATION_SET_ITEM_FRAGMENT = LocationSetItemDetails

export const LOCATION_SET_ITEM = gql`
  query LocationSetItem($id: ID!) {
    contentSetItem(id: $id) {
      ...LocationSetItemDetails
    }
  }
  ${LocationSetItemDetails}
`

export const UPDATE_LOCATION_SET_ITEM = gql`
  mutation UpdateLocationSetItem($input: UpdateContentSetItemInput!) {
    updateContentSetItem(input: $input) {
      ...LocationSetItemDetails
    }
  }
  ${LocationSetItemDetails}
`