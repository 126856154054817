<template>
  <div v-if="context == 'preview'">
    <h2 class="text-center">This block is deprecated. Use the Embed block instead.</h2>
  </div>

  <div v-else>
    <p>No settings for this block</p>
  </div>
</template>

<script>
export default {
  name: 'MagazineArticles',
  mixins: [BlockMixin],
}
</script>

<style>
</style>