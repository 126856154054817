import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { faSquare, faRectangleWide, faRectanglePortrait, faEllipsisV } from '@fortawesome/pro-regular-svg-icons'
import {
  faCommentAltDollar,
  faFileAlt,
  faFileInvoice,
  faGlobeAmericas,
  faHandHolding,
  faMoneyCheckEditAlt,
  faSearch,
  faUserFriends,
  faTable,
  faColumns
} from '@fortawesome/pro-light-svg-icons'

import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'
// import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

library.add(
  fas,
  faSquare,
  faRectangleWide,
  faRectanglePortrait,
  faEllipsisV,
  faTable,
  faColumns,

  faCommentAltDollar,
  faFileAlt,
  faFileInvoice,
  faGlobeAmericas,
  faHandHolding,
  faMoneyCheckEditAlt,
  faSearch,
  faUserFriends,
  faFacebook,
  faTwitter
)

export function setupIcons(app) {
  app.component('fa-icon', FontAwesomeIcon);
  app.component('fa-layers', FontAwesomeLayers);
}