<template>
  <div v-if="context == 'preview'">
    <rw-textarea v-model="localValue.code" placeholder="HTML" label="HTML" :tab-support="true" />
    <div v-if="localValue.code">
      <button @click="showTips = !showTips" class="btn btn-sm mb-3">Show/Hide Tips</button>
      <div v-if="showTips">
        <p class="mt-0 pt-0">A few tips:</p>
        <ol class="list-decimal ml-3 pl-4 mb-3">
          <li>
            If things aren't looking like you are expecting, make sure your quotes are closed right. Don't mix single and double quotes.
            For example: 'hello' and not "hello'. 
          </li>
          <li>Tags need to be closed right like p tags.</li>
          <li>Use Tailwind classes when you can.</li>
          <li>Psssst... new feature. You can use tab in the textarea to indent while coding.</li>
          <li>Like in true HTML fashion, new lines, double spaces and so on do not register. Use tags like div, or p tags to control spacing.</li>
        </ol>
      </div>
      <div>
        <label class=font-bold>Rendered HTML</label><hr/>
      </div>
      <div v-html="modelValue.code"></div>
    </div>
  </div>

  <div v-else>
    <p>No special settings for this block.</p>
  </div>
</template>

<script>
import { reactive } from 'vue';

export default {
  name: 'Html',
  props: {
    modelValue: {
      type: Object,
    },
    context: {
      type: String,
      default: 'preview',
    },
  },
  data() {
    return {
      showTips: false,
    }
  },
  computed: {
    localValue: {
      get() {
        return this.modifyHtml();
      },
      set(newVal) {
        this.$emit('update:modelValue', newVal);
      }
    }
  },
  methods: {
    modifyHtml() {
      // Strip scripts and new line tags.
      let block = reactive(this.modelValue);

      if (block.code) {
        const scriptPat = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script\s*>/gi;
        block.code = block.code.replace(scriptPat, '');
      }

      return block;
    }
  }
}
</script>