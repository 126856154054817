<template>
  <div v-if="context == 'preview'">
    <p class="font-bold text-lg text-center" v-if="block.embedType !== null">
      Type: {{ getTypeName() }}
    </p>
    <p class="font-bold text-lg text-center">
      This will pull the entry based on the type's setting on the account level.
    </p>
  </div>

  <!-- Settings  -->
  <div v-else>
    <rw-select
      class="mb-4"
      :options="titleColors"
      v-model="block.titleColor"
      :includeNull="true"
      label="Title &amp; Text Color"
    />

    <rw-select
      class="mb-4"
      :options="availabelBgStyles"
      v-model="block.bgStyle"
      :includeNull="true"
      label="Background Style"
    />

    <rw-select
      v-if="block.bgStyle == 'bg-primary'"
      class="mb-4"
      :options="availableBgPositions"
      v-model="block.bgPosition"
      :includeNull="true"
      label="Background Position"
    />
    
    <rw-select
      :options="entryTypes"
      v-model="block.embedType"
      :includeNull="true"
      label="Embed Type"
      @update:modelValue="updateEmbedType"
    />

    <p class="my-4">To update what gets pulled, view the account's Content Package setting.</p>
  </div>
</template>

<script>
import { entryTypes, titleColors } from '@/settings/enums'

export default {
  name: 'AccountContentEmbed',
  mixins: [BlockMixin],
  data() {
    return {
      block: this.modelValue,
      entryTypes: entryTypes,
      titleColors: titleColors,
    }
  },
  computed: {
    availabelBgStyles() {
      return {
        'bg-subtle': 'Background - Subtle',
        'bg-primary': 'Background - Brand Color',
      }
    },
    availableBgPositions() {
      if (this.block.bgStyle == 'bg-primary') {
        return {
          'bg-offset': 'Background Offset - Right',
          'bg-offset-left': 'Background Offset - Left',
        }
      }
    },
  },
  methods: {
    insertElement($event) {
      this.block.embedId = $event.data[0].id
    },
    updateEmbedType($event) {
      this.block.embedId = null;
      this.block.embedType = $event;
      //this.$set(this.block, 'embedId', null)
      //this.$set(this.block, 'embedType', $event)
    },
    getTypeName() {
      return this.entryTypes[this.block.embedType];
    },
  },
}
</script>