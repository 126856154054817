<template>
  <div v-if="context == 'preview'">
    <p v-if="!block.children.length" class="mx-2 my-0">Drag a block here to get started</p>
    <draggable
      :class="{ 'py-8 border border-gray-800 border-dashed': !block.children.length }"
      :list="block.children"
      :group="{ name: 'blocks', put: ['contentBlocks', 'blocks'] }"
      handle=".col-handle"
      item-key="_id"
    >
      <template #item="{ element, index }">
        <draggable-item
          class="-mx-2"
          handleClass="col-handle"
          v-model="block.children[index]"
          :ref="'childBlock:' + index"
          @remove-child="removeChild(index)"
          @update:value="updateBlock($event, index)"
        />
      </template>
    </draggable>
  </div>

  <div v-else>
    <div class="field">
      <label :for="`col-width-${$.uid}`">Desktop Width</label>
      <select name="width" :id="`col-width-${$.uid}`" v-model="block.blockWidth">
        <option value="md:w-1/4">One Fourth</option>
        <option value="md:w-2/5">Two Fifths</option>
        <option value="md:w-1/3">One Third</option>
        <option value="md:w-1/2">One Half</option>
        <option value="md:w-3/5">Three Fifths</option>
        <option value="md:w-2/3">Two Thirds</option>
      </select>
    </div>
  </div>
</template>

<script>
let uniqid = require('uniqid');
import { reactive } from 'vue';
import defaultsDeep from 'lodash/defaultsDeep';

export default {
  name: 'Column',
  mixins: [InsertPreset],
  props: {
    modelValue: {
      type: Object
    },
    context: {
      type: String,
      default: 'preview',
    },
  },
  computed: {
    block: {
      get() {
        return defaultsDeep(reactive(this.modelValue), {_id: uniqid()});
      },
      set(newVal) {
        this.$emit('update:modelValue', newVal);
      }
    }
  },
  methods: {
    updateBlock($event, idx) {
      console.log('Column.vue updateBlock')
      this.block.children[idx] = $event;
    },
    removeChild(idx) {
      this.block.children.splice(idx, 1)
    },
  },
}
</script>