<template>
  <div v-if="finished">
    <div class="perm-group" v-for="(permissions, page) in permissionGroups" :key="page">
      <h6>{{ page == 'null' ? 'No Page name' :  page }}</h6>
      <div class="row container">
        <div class="grid grid-cols-3 gap-4">
          <div v-for="permission in permissions" :key="permission.name">
            <label>
              <input 
                type="checkbox" 
                v-model="role.permissions"
                :value="permission.name" 
                :disabled="!editable"
                class="mr-1" />
              {{ permission.name }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PermissionsList',
  props: {
    permissions: {
      type: Array,
      required: true
    },
    role: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      permissionGroups: [],
      finished: false,
    }
  },
  mounted() {
    let groups = {};
    // Get all the pages and filter for duplicates. Replace nulls with just no name.
    let pages = this.permissions.map(perm => perm.page_name);
    pages = [...new Set(pages)];

    pages.forEach(page => {
      groups[page] = this.permissions.filter(perm => perm.page_name == page);
    });

    this.permissionGroups = groups;
    this.finished = true;
  },
}
</script>

<style scoped>
.perm-group {
  padding: 0.5em 1em;
}
</style>