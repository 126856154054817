<template>
  <button @click="runAction" :disabled="loading">
    <span class="flex items-center">
      {{ btnText }}
      <spinner v-if="loading" class="ml-2" />
    </span>
  </button>
</template>

<script>
import EventBus from '@/bootstrap/EventBus'
export default {
  name: "SiteDelete",
  props: ["text", "siteID"],
  data() {
    return {
      loading: false,
      btnText: this.text
    };
  },
  created() {
    let self = this;
    Echo.private("site." + this.siteID)
      .listenForWhisper("deleting", evt => {
        self.loading = true
      })
      .listen(".delete.success", evt => {
        self.$emit("site-deleted");
        self.loading = false
      });
  },
  beforeUnmounted() {
    Echo.leave("site.build." + this.siteID);
  },
  methods: {
    runAction() {
      const result = confirm(
        "Deleting the site will not actually delete any content. We're just cleaning up some files on the server.  Delete?"
      );
      if (!result) { return; }

      this.loading = true;
      Echo.private("site." + this.siteID).whisper("deleting");

      const params = {
        site_id: this.siteID,
        action: 'delete',
        local_only: false
      }

      this.$api.post(`/update-site/`, params)
        .then(response => {
          this.$toasted.success('Site Deleted!');
          EventBus.emit('site-updated', this.siteID);
          console.log(response);
        })
        .catch(error => {
          this.$acorenErrors(error.message);
          console.log(error);
        })
    }
  }
};
</script>
