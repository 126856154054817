<template>
  <div>
    <button :class="classes" @click.prevent="showModal = true">{{text}}</button>
    <modal-dialog :show="showModal" @close="closeModal">
      <template #header><h3 slot="header">Create Site Redirect</h3></template>
      <div
        v-if="error"
        class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-5"
        role="alert"
      >
        <strong class="font-bold">Something bad happened:</strong>
        {{ error }}
      </div>

      <div
        v-if="success"
        class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-5"
        role="alert"
      >
        <strong class="font-bold">Success!</strong>
        {{ success }}
      </div>

      <form id="redirect-entry-form" action @keyup.enter="createRedirect" class="space-y-2">
        <p>This is for internal pages only.</p>
        <rw-text v-model="from" label="From" placeholder="Enter the old url (path should start with a /)" />
        <rw-text v-model="to" label="To" placeholder="Enter the new url (path should start with a /)" />
      </form>
      <template #footer>
        <div class="flex justify-end">
          <button class="btn btn-go" @click.prevent="createRedirect" :disabled="loading">
            Create Site Redirect
            <spinner v-if="loading" class="ml-2" />
          </button>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from '@/components/utils/ModalDialog'

export default {
  name: 'SiteRedirectCreate',
  props: ['text', 'classes', 'siteID'],
  components: { ModalDialog },
  data() {
    return {
      loading: false,
      showModal: false,
      from: '',
      to: '',
      error: null,
      success: null,
    }
  },
  methods: {
    closeModal() {
      this.showModal = false
      this.success = ''
    },
    createRedirect() {
      let self = this

      self.loading = true

      let params = {
        site_id: self.siteID,
        action: 'addRedirect',
        from: self.from,
        to: self.to
      }

      self.$api
        .post(`/site/`, params)
        .then(response => {
          self.error = ''
          self.success = `Redirect created from ${self.from} to ${self.to}`
          self.$emit('redirect-created')
          self.loading = false
          self.from = ''
          self.to = ''
        })
        .catch(error => {
          self.error = "Redirect failed. Please check to see if the to and from fields are provided and start with a slash."
          self.loading = false
        })
    },
  },
}
</script>