<template>
  <div v-if="context == 'preview'">
    <div v-if="preview.includeAll" class="placeholder">
      <img src="/images/office-block.png" alt="Example form" />
    </div>
    <div v-if="!preview.includeAll" class="flex flex-wrap">
      <div class="placeholder" :class="itemWidth" v-for="office in preview.idsToShow" :key="office">
        <img src="/images/office-block.png" alt="Example form" />
      </div>
    </div>
  </div>

  <div v-else class="space-y-4">
    <light-switch v-model="block.includeAll"><template #label>Include all Locations</template></light-switch>

    <div class="field field-inline" v-if="!block.includeAll">
      <label>Child Item Widths</label>
      <select v-model="block.itemWidth">
        <option :value="null">Default (full)</option>
        <option value="halves">Halves</option>
        <option value="thirds">Thirds</option>
      </select>
    </div>

    <ul v-if="!block.includeAll" class="space-y-1">
      <li v-for="office in offices" :key="office.id">
        <label :for="`office-${office.id}`" class="cusor-pointer">
          <input :id="`office-${office.id}`" type="checkbox" v-model="block.idsToShow" :value="office.id" />
          {{ office.title }}
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import sortBy from 'lodash/sortBy'
const SITE_OFFICES = gql`
  query SiteOffices($officeable_id: ID!, $orderBy: [OrderByClause!]) {
    siteOffices(officeable_id: $officeable_id, orderBy: $orderBy) {
      id
      title
      order
    }
  }
`

export default {
  name: 'Offices',
  props: ['context', 'modelValue'],
  data() {
    let block = Object.assign(
      {},
      {
        includeAll: true,
        idsToShow: [],
        itemWidth: null,
      },
      this.modelValue
    )
    return {
      block,
      offices: [],
    }
  },
  computed: {
    preview() {
      return this.modelValue
    },
    itemWidth() {
      if (this.preview.itemWidth == 'thirds') return 'w-1/3 px-2'
      if (this.preview.itemWidth == 'halves') return 'w-1/2 px-2'
    },
  },
  watch: {
    block: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        this.$emit('update:modelValue', newVal)
      },
    },
  },
  apollo: {
    offices: {
      query: SITE_OFFICES,
      variables() {
        return {
          officeable_id: this.$route.params.siteID,
          orderBy: [{ field: 'order', order: 'ASC' }],
        }
      },
      update: (data) => {
        return sortBy(data.siteOffices, [
          function (o) {
            return o.order
          },
        ])
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.placeholder::before {
  content: 'Location\APlaceholder';
}
.placeholder::before {
  font-size: 2rem;
}
</style>