<template>
  <div>
    <cloudinary-image 
      v-if="block.cloudinaryId"
      width="600"
      height="314"
      crop="fill"
      :key="id"
      :public-id="block.cloudinaryId" />
    <button
      class="btn btn-stop px-2 absolute top-0 right-0 mr-2 mt-2"
      @click="removeImage"
      v-if="block.src"
    >
      <fa-icon icon="trash" />
    </button>

    <div class="w-3/4 py-4 mx-auto text-center" v-if="!block.src">
      <fa-icon icon="image" class="text-gray-300" size="6x" />
      <p class="font-bold text-2xl">Select an Image</p>
      <p>If you don't select an image, the default (specified in the Site SEO settings or in the Entry Teaser) will be used if available.</p>
      <cloudinary :fieldUid="$.uid" />
    </div>
  </div>
</template>


<script>
import cloneDeep from 'lodash/cloneDeep'
import { image } from '@/settings/blocks'
import EventBus from '@/bootstrap/EventBus'

export default {
  name: 'SeoImage',
  props: ['modelValue'],
  data() {
    return {
      block: this.modelValue || cloneDeep(image),
      id: 0,
    }
  },
  watch: {
    block: {
      deep: true,
      handler(newVal) {
        this.id++
        this.$emit('update:modelValue', newVal)
      },
    },
  },
  mounted() {
    EventBus.on('asset-chosen', this.updateImage)
  },
  beforeUnmounted() {
    EventBus.off('asset-chosen')
  },
  methods: {
    updateImage(data) {
      console.log('Image.vue - updateImage', data, this.$.uid, data.uid)
      if (this.$.uid === data.uid) {
        this.block = Object.assign({}, this.block, {
          src: data.asset.secure_url,
          cloudinaryId: data.asset.public_id,
          cloudinary: data.asset,
        })
      }
    },
    removeImage() {
      this.block = cloneDeep(image)
    },
  },
}
</script>