<template>
    <div v-if="context == 'preview'">
      <h2 class="text-center">Ebook Promo</h2>
    </div>

    <div v-else>
      <!-- <p>We only control the link since the magazine landing page and url can differ from site to site. The teaser and image are automatic.</p> -->
      <!-- <rw-link class="mt-2" :block="block.link" /> -->
    </div>
</template>

<script>
import BlockMixin from '../mixins/block';

export default {
  name: "EbookPromo",
  mixins: [ BlockMixin ]
}
</script>

<style>

</style>