<template>
  <div>
    <button :class="btnClass" @click.prevent="showModal = true">{{btnText}}</button>
    <modal-dialog :show="showModal" @close="closeModal">
      <template #header><h3 slot="header">Override Entry</h3></template>
      <div
        v-if="error"
        class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-5"
        role="alert"
      >
        <strong class="font-bold">Something bad happened:</strong> {{ error }}
      </div>

      <div
        v-if="success"
        class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-5"
        role="alert"
      >
        <strong class="font-bold">Success!</strong> {{ success }}
      </div>

      <form id="entry-override-form" action>
        <rw-text v-model="localOverrides.title" />
        <!-- <rw-text v-model="entry.title" label="Title" placeholder="Enter the article title..." /> -->
        <p class="mb-4"><span class="font-bold mr-2">Entry Publish Date:</span> {{ entry.published_at }}</p>

        <div class="flex items-center mb-2 pb-2">
          <label class="font-bold mr-2 flex-none">Override Date:</label><date-field v-model="published_at" class="flex-1" />
        </div>

        <div class="flex justify-end mt-4">
          <button class="btn btn-go" @click.prevent="overrideEntry">Override Entry</button>
        </div>
      </form>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from "@/components/utils/ModalDialog"

export default {
  name: "EntryOverride",
  props: ['btnText', 'btnClass', 'entry', 'overrides', 'siteID'],
  components: { ModalDialog },
  data() {
    return {
      showModal: false,
      error: null,
      success: null,
      published_at: this.overrides ? this.overrides.published_at : null,
      localOverrides: this.overrides ? Object.assign( {}, this.overrides.overrides) : {}
    }
  },
  methods: {
    overrideEntry() {
      this.$api.post('sites/site-entry-override', {
        'site_id': this.siteID,
        'entry_id': this.entry.id,
        'published_at': this.published_at,
        'overrides': this.localOverrides
      })
      .then( ({data}) => {
        this.$emit('entry-updated')
      })
    },
    closeModal() {
      this.showModal = false
      this.sucess = ''
    },
  }
};
</script>

<style lang="scss">
</style>