<template>
  <div class="field" :class="{ 'field-inline': layout == 'inline' }">
    <label>Content Set</label>
    <select v-model="localValue" :disabled="disabled">
      <option :value="null">Choose a content set</option>
      <option v-for="option in contentSets" :key="option.id" :value="option.id">{{ option.title }}</option>
    </select>
  </div>
</template>

<script>
import { ALL_CONTENT_SETS, SITE_CONTENT_SETS } from '@/graphql/content-sets'
import { reactive } from 'vue';

export default {
  name: 'ContentSetPicker',
  props: {
    modelValue: String,
    siteID: String,
    layout: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    allowedTypes: {
      type: Array,
      default() {
        return ['page']
      },
    },
  },
  data() {
    return {
      contentSets: [],
    }
  },
  computed: {
    localValue: {
      get() {
        return reactive(this.modelValue);
      },
      set(newVal) {
        console.log('emitting ' + newVal);
        this.$emit('update:modelValue', newVal);
      }
    }
  },
  apollo: {
    contentSets: {
      query() {
        return this.siteID ? SITE_CONTENT_SETS : ALL_CONTENT_SETS
      },
      variables() {
        return {
          types: this.allowedTypes,
          siteID: this.siteID ? this.siteID : null,
        }
      },
    },
  },
}
</script>

<style>
</style>