<template>
  <div class="content-builder">
    <!-- <button class="btn btn-sm ml-4 mb-2" @click="showAll = !showAll" v-if="blocks.length">Toggle All</button> -->

    <draggable 
      item-key="_id" 
      :list="blocks" 
      handle=".block-handle" 
      class="content-builder-blocks">
      <template #item="{ element, index }">
        <content-builder-item
          :key="element._id"
          v-model="blocks[index]" 
          :includePresets="includePresets"
          @update:modelValue="watchBlock($event, index)"
          @load-block="loadBlock($event, index)"
          @remove-item="blocks.splice(index, 1)"
        />
      </template>
    </draggable>

    <content-block-picker
      :contentType="contentType"
      :sectionOptions="sectionOptions"
      :blockOptions="blockOptions"
      @add-block="blocks.push($event)"
    />
  </div>
</template>

<script>
export default {
  name: 'ContentBuilder',
  props: {
    modelValue: Array,
    sectionOptions: String,
    contentType: {
      type: String,
      default: 'page',
    },
    blockOptions: {
      type: String,
      default: 'simpleBlocks',
    },
    includePresets: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showAll: true,
    }
  },
  computed: {
    blocks() {
      return this.modelValue || []
    },
  },
  watch: {
    blocks: {
      deep: true,
      immediate: true,
      handler(newVal) {
        console.log('ContentBuilder.vue watch.blocks')
        return this.$emit('update:modelValue', newVal)
      },
    },
  },
  methods: {
    watchBlock($event, idx) {
      this.blocks[idx] = $event;
      //this.$set(this.blocks, idx, $event)
    },
    loadBlock($event, idx) {
      this.blocks[idx] = $event.content;
      //this.$set(this.blocks, idx, $event.content)
    },
  },
}
</script>