<template>
  <div>
    <div class="mb-6">
      <p><strong>NOTE:</strong> Choose one office as the primary location. This will help with Google JSON-LD.</p>
    </div>
    <draggable :list="offices" item-key="id" :group="{ name: 'siteOffices' }" handle=".office-handle" @end="officeOrder">
      <template #item="{ element, index }">
        <div class="mb-4 pb-6 border-b border-gray-400 flex items-start">
          <fa-icon class="office-handle" icon="grip-vertical" v-if="editable" />
          <office :editable="editable" class="flex-1 mx-4" v-model="offices[index]" />
          <button @click="deleteOffice(element, index)" v-if="editable">
            <fa-icon icon="minus-circle">Trash</fa-icon>
          </button>
        </div>
      </template>
      <template #footer>
        <button v-if="editable" class="btn" @click="addOffice">Add Office</button>
      </template>
    </draggable>
  </div>
</template>

<script>
// https://developers.google.com/search/docs/data-types/local-business

export default {
  name: 'OfficeForm',
  props: ['offices', 'officesToDelete', 'editable'],
  watch: {
    offices: {
      deep: true,
      handler(newVal) {
        newVal.forEach((element, idx) => {
          element.order = idx
        })
      },
    },
  },
  methods: {
    deleteOffice(office, idx) {
      office.id ? this.officesToDelete.push(office.id) : null
      this.offices.splice(idx, 1)
    },
    officeOrder(evt) {
      this.offices.forEach((element, idx) => {
        element.order = idx
      })
    },
    addOffice() {
      const office = {
        officeable_id: this.$route.params.siteID,
        officeable_type: 'sites',
        title: '',
        address: '',
        address2: '',
        show_map: true,
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        display: [],
        hours: [],
        contact: [],
      }
      this.offices.push(office)
    },
  },
}
</script>

<style>
</style>