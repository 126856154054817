<template>
  <div v-if="context == 'preview'" :class="classes.container">
    <component
      v-for="item in block.children"
      :class="classes.item"
      :key="item.id"
      :value="item"
      :is="getType(item.blockType)"
      context="preview"
    />
  </div>

  <div v-else>
    <div v-if="activeTab == 'settings'">
      <div class="mb-4">
        <rw-text v-model="block.title" placeholder="Enter the title" label="Container Title" />
      </div>

      <div class="field">
        <label>Layout</label>
        <select v-model="block.blockLayout" @change="resetBlock">
          <option :value="null">Default</option>
          <option v-for="(name, value) in layouts" :value="value" :key="value">{{name}}</option>
        </select>
      </div>

      <div class="field" v-if="showStyles">
        <label>Style</label>
        <select v-model="block.blockStyle">
          <option :value="null">None</option>
          <option v-for="(name, value) in styles" :value="value" :key="value">{{name}}</option>
        </select>
      </div>

      <div class="field" v-if="block.blockLayout == 'grid'">
        <label>Child Item Widths</label>
        <select v-model="block.childDesktopWidth">
          <option :value="null">Select a width</option>
          <option value="quarters">Quarters</option>
          <option value="halves">Halves</option>
          <option value="thirds">Thirds</option>
        </select>
      </div>

      <div class="field" v-if="block.blockLayout == 'columns'">
        <label>Vertical Alignment</label>
        <select v-model="block.alignment">
          <option :value="null">Default</option>
          <option value="items-start">Items Top</option>
          <option value="items-center">Items Center</option>
          <!-- <option value="items-stretch">Items Stretch</option> -->
        </select>
      </div>
    </div>

    <p v-if="!block.children.length">Add some blocks</p>
    <div v-if="activeTab == 'content'">
      <draggable
        :list="block.children"
        handle=".block-handle"
        class="py-4"
        :group="{ name: 'section', put: true }"
        item-key="_id"
      >
        <template #item="{ element, index }">
          <content-builder-item
            :value="element"
            :includePresets="true"
            @update:value="updateItem($event, index)"
            @load-block="loadBlock($event, index)"
          />
        </template>
      </draggable>
    </div>

    <content-block-picker blockOptions="contentBlocks" @add-block="block.children.push($event)" />
  </div>
</template>

<script>
import { blockStyles } from '@/settings/blocks'
import omit from 'lodash/omit'

export default {
  name: 'ContentContainer',
  props: {
    modelValue: Object,
    context: String,
    activeTab: {
      type: String,
      default: 'content',
    },
  },
  data() {
    return {
      block: this.modelValue,
      layouts: {
        grid: 'Grid',
        columns: 'Equal columns (flex)',
      },
      styles: blockStyles.container,
    }
  },
  computed: {
    showStyles() {
      return this.block.blockLayout == 'grid' || this.block.blockLayout == 'columns'
    },
    classes() {
      let classes = { container: ['-mx-2'], item: ['px-3'] }

      classes.container.push(this.block.alignment)

      if (this.block.blockLayout == 'grid') {
        classes.container.push('flex')
        classes.container.push('flex-wrap')
        let itemWidth =
          this.block.childDesktopWidth == 'quarters'
            ? 'md:w-1/4'
            : this.block.childDesktopWidth == 'halves'
            ? 'md:w-1/2'
            : this.block.childDesktopWidth == 'thirds'
            ? 'md:w-1/3'
            : 'md:w-full'
        classes.item.push(itemWidth)
        classes.item.push('flex-none')
      }

      if (this.block.blockLayout == 'columns') {
        classes.container.push('flex')
        classes.item.push('flex-1')
      }

      if (this.block.blockLayout == 'columnsCustom') {
        classes.container.push('flex flex-wrap')
        classes.item.push('flex-none')
      }

      !this.block.children.length ? classes.container.push('py-8 px-3 border border-gray-600 border-dashed') : null

      return classes
    },
  },
  watch: {
    block: {
      deep: true,
      handler(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  methods: {
    resetBlock() {
      this.block.blockStyle = null
      this.block.alignment = null
      this.block.childDesktopWidth = null
    },
    updateItem($event, idx) {
      this.block.children[idx] = $event;
      //this.$set(this.block.children, idx, $event)
    },
    loadBlock($event, idx) {
      console.log($event, idx)
      this.blocks[idx] = $event.content;
      //this.$set(this.blocks, idx, $event.content)
    },
    getType(blockType) {
      let blockName = blockType
        .match(/\w+/)[0]
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\s+/g, '-')
        .toLowerCase()
      return 'rw-' + blockName
    },
  },
}
</script>