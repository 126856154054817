import gql from 'graphql-tag'

let ContentPackageFragment = gql`
  fragment ContentPackageFragment on ContentPackage {
    type
  }
`

let EntryDetailsFragment = gql`
  fragment EntryDetails on Entry {
    id
    site_id
    slug
    admin_note
    type
    content {
      id
      title
      status
    }
    contentPackages {
      ...ContentPackageFragment
    }
    sub_type
    published_at
    created_at
    updated_at
    deleted_at
  }
  ${ContentPackageFragment}
`

export const ENTRY_DETAILS_FRAGMENT = EntryDetailsFragment

let EntryContentDetailsFragment = gql`
  fragment EntryContentDetails on EntryContent {
    id
    title
    author
    status
    read_time
    hero
    teaser
    teaser_image
    content
    seo
    content
    gated
    gated_preview
    gated_asset
    social_content
    created_at
    updated_at
  }
`

export const ENTRY_CONTENT_DETAILS_FRAGMENT = EntryContentDetailsFragment

// This one doesn't hit the special query that takes in both site and global.
export const ALL_SITE_ENTRIES = gql`
  query Entries(
    $first: Int!, 
    $page: Int, 
    $type: String, 
    $site_id: ID, 
    $title: Mixed = null,
    $orderBy: [EntriesOrderByOrderByClause!]) {
    entries(
        first: $first, 
        page: $page, 
        type: $type, 
        site_id: $site_id, 
        hasContent: {column: TITLE, operator: LIKE, value: $title},
        orderBy: $orderBy
      ) {
      data {
        ...EntryDetails
        content{
          id
          title
          status
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
  ${EntryDetailsFragment}
`

export const SLUG_ENTRIES = gql`
  query SlugEntries($slug: String!, $type: String!, $site_id: String) {
    entriesBySlug(slug: $slug, type: $type, site_id: $site_id) {
      ...EntryDetails
      content {
        id
        title
        status
      }
    }
  }
  ${EntryDetailsFragment}
`

export const ALL_ENTRIES = gql`
  query Entries($type: String, $site_id: ID) {
    allEntries(type: $type, site_id: $site_id) {
      ...EntryDetails
      content {
        id
        title
        status
      }
    }
  }
  ${EntryDetailsFragment}
`

export const ENTRIES_AND_OVERRIDES = gql`
  query EntriesAndOverrides($type: String, $site_id: ID) {
    entries(type: $type, site_id: null) {
      ...EntryDetails
      content {
        id
        title
        status
      }
      siteEntryOverride(site_id: $site_id) {
        id
        site_id
        entry_id
        overrides
      }
    }
  }
  ${EntryDetailsFragment}
`

export const ENTRY = gql`
  query Entry($entryID: ID!) {
    entry(id: $entryID) {
      ...EntryDetails
      content {
        ...EntryContentDetails
      }
    }
  }
  ${EntryDetailsFragment}
  ${EntryContentDetailsFragment}
`

export const ENTRY_USAGE = gql`
  query EntryUsage($entryID: ID!) {
    entry(id: $entryID) {
      ...EntryDetails
      magazineIssues {
        id
        type
        title
        published_at
      }
      contentPackages {
        id
        title
        type
      }
    }
  }
  ${EntryDetailsFragment}
`

export const CREATE_ENTRY = gql`
  mutation CreateEntry($input: CreateEntryInput!) {
    createEntry(input: $input) {
      ...EntryDetails
      content {
        title
      }
    }
  }
  ${EntryDetailsFragment}
`

export const UPDATE_ENTRY = gql`
  mutation UpdateEntry($input: UpdateEntryInput!) {
    updateEntry(input: $input) {
      ...EntryDetails
      content {
        ...EntryContentDetails
      }
    }
  }
  ${EntryDetailsFragment}
  ${EntryContentDetailsFragment}
`

export const DELETE_ENTRY = gql`
  mutation DeleteEntry($id: ID!) {
    deleteEntry(id: $id) {
      id
    }
  }
`

export const TOGGLE_ENTRY_VISIBILITY = gql`
  mutation DeleteEntry($id: ID!) {
    deleteEntry(id: $id) {
      id
    }
  }
`
 