<template>
  <div>
    <button :class="btnClass" v-html="text" @click="showModal = true"></button>
    <modal-dialog :show="showModal" @close="closeModal" size="medium" height="min-h-1/3">
      <h3 slot="header">Import Content</h3>
      <p>This is extra tall in order to accomodate the site dropdown. YOLO!!!</p>

      <div class="space-y-2">
        <div class="field">
          <label for="from-site">Site</label>
          <site-select @update:value="site_id = $event.id" />
        </div>

        <div class="field">
          <label for="from-page">Page</label>
          <select name="from-page" id="from-page" v-model="page_id" class="w-full">
            <option :value="null">Choose a page</option>
            <option
              v-for="page in pages"
              :key="page.id"
              :value="page.id"
              :disabled="!pages.length"
              v-html="optionLabel(page)"
            ></option>
          </select>
        </div>
      </div>

      <div slot="footer">
        <div class="flex justify-end">
          <button
            class="btn btn-go"
            @click.prevent="importContent"
            :disabled="!page_id"
          >Import Content</button>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import { SITE_LIST } from '@/graphql/sites'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'SitePageImport',
  props: {
    text: {
      type: String,
      default: 'Import Content',
    },
    btnClass: {
      type: String,
      default: 'btn',
    },
  },
  data() {
    return {
      state: 'idle',
      showModal: false,
      pageContent: {},
      sites: {},
      pages: [],
      site_id: null,
      page_id: null,
    }
  },
  apollo: {
    sites: {
      fetchPolicy: 'no-cache',
      query: SITE_LIST,
    },
  },
  watch: {
    site_id(val) {
      if (val) {
        this.fetchPages()
      } else {
        this.pages = []
      }
    },
  },
  methods: {
    closeModal() {
      this.site_id = null
      this.page_id = null
      this.pages = []
      this.showModal = false
    },
    fetchPages() {
      this.state = 'loading'
      this.$api
        .post('site-pages', {
          action: 'picker',
          site_id: this.site_id,
        })
        .then(response => {
          this.pages = response.data.items
          this.state = 'idle'
        })
    },
    optionLabel(item) {
      return `${'-'.repeat(item.depth)} ${item.title}`
    },
    importContent() {
      let page = this.pages.find(item => item.id == this.page_id)
      // content should be an array
      let content = cloneDeep(page.content.content)
      if (confirm('Are you sure you want to replace this page content?')) {
        this.$emit('insert-content', { content: content })
        this.closeModal()
      }
    },
  },
}
</script>

<style>
</style>