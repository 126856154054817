<template>
  <div v-if="context == 'preview'" :class="block.textAlign">
    <rw-image v-if="block.variant == 'standard' || block.variant == 'image'" v-model="block.image" context="preview" />
    <h2 class="h3">
      <fa-icon class="mr-1" v-if="block.icon" :icon="block.icon" />
      {{ block.title }}
    </h2>
    <div v-html="formatted(block.text)"></div>
    <rw-link v-model="block.link" context="preview" />
  </div>

  <div v-else class="space-y-2">
    <rw-select
      layout="inline"
      v-model="block.variant"
      label="Card Type"
      :options="cardVariants"
    />

    <template v-if="block.variant == 'standard' || block.variant == 'image'">
      <rw-image v-model="block.image" context="settings" class="pb-4 border-b" />
      <rw-select
        v-model="block.textAlign"
        label="Text Alignment"
        :includeNull="true"
        :options="{ 'text-right': 'Right', 'text-center': 'Center' }"
        class="pt-2"
      />
    </template>

    <template v-if="block.variant == 'image'">
      <rw-select
        v-model="block.colorOverlay"
        label="Background Overlay"
        :options="{ 'white' : 'White (Default)', 'dark' : 'Dark' }"
        class="pt-2"
      />

      <light-switch v-model="block.includeBorder"><template #label>Include White Border?</template></light-switch>
    </template>

    <div class="field field-inline" v-if="block.variant == 'card' || block.variant == 'topIcon'">
      <label>Icon</label>
      <v-select
        class="bg-white"
        :options="iconOptions"
        v-model="block.icon"
        :reduce=" option => option.code "
      >
        <template v-slot:option="option">
          <fa-icon :icon="option.code" class="mr-2 w-3 text-center" />
          {{ option.label }}
        </template>
      </v-select>
    </div>

    <!-- <template v-if="block.variant == 'card' || block.variant == 'teaser'">
      <rw-select
        v-model="block.borderAnimation"
        label="Border Animation"
        :includeNull="true"
        :options="animationOptions"
        class="pt-2"
      />
    </template> -->

    <rw-text v-model="block.title" placeholder="Enter a title" label="Title" />
    <rw-textarea v-model="block.text" placeholder="Enter description..." label="Text" />
    <rw-link v-model="block.link" context="settings" />

    <div class="field" v-if="block.variant == 'teaser'">
      <light-switch v-model="block.ctaLink"><template #label>Enable CTA Link?</template></light-switch>
    </div>
  </div>
</template>

<script>
import iconOptions from '@/settings/icons'
import { marked } from 'marked';

marked.use({
  mangle: false,
  headerIds: false,
});

export default {
  name: 'Card',
  mixins: [BlockMixin],
  data() {
    return {
      cardVariants: {
        // default: 'Default',
        standard: 'Standard',
        card: 'Card',
        teaser: 'Teaser',
        image: 'Image',
        topIcon: 'Icon on Top',
      },
      iconOptions: iconOptions.default,
      animationOptions: {
        'border-chase': 'Border Chase',
      },
    }
  },
  methods: {
    formatted(text) {
      return text ? marked(text) : ''
    },
  },
}
</script>