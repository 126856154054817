<template>
  <div>
    <slot name="label" />
    <popover class="dropdown-list" :disabled="disabled" placement="bottom-end">
      <template #trigger>
        <slot name="trigger">
          <button class="rotating-dots-button">
            <svg class="rotating-dots fill-current" width="12" viewBox="0 0 24 24">
              <circle cx="3" cy="12" r="3" />
              <circle cx="12" cy="12" r="3" />
              <circle cx="21" cy="12" r="3" />
            </svg>
          </button>
        </slot>
      </template>
      <slot />
    </popover>
  </div>
</template>

<script>
export default {
  name: 'DropdownList',
  props: {
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>