const EntryRoutes = [
  {
    path: '/entries/:entryID',
    name: '',
    component: () => import(/* webpackChunkName: "entries" */ '@/views/entries/EntriesWrapper.vue'),
    children: [
      {
        name: 'EntryEdit',
        path: ':tabName?',
        props: true,
        meta: {
          breadcrumbs: [{ name: 'Manage Entries', link: 'EntriesIndex' }],
        },
        component: () => import(/* webpackChunkName: "editEntry" */ '@/views/entries/EntryEdit.vue'),
      },
    ],
  },
  {
    path: '/sites/:siteID/entries/:entryID',
    name: '',
    component: () => import(/* webpackChunkName: "entries" */ '@/views/entries/EntriesWrapper.vue'),
    children: [
      {
        name: 'SiteEntryEdit',
        path: ':tabName?',
        props: true,
        meta: {
          breadcrumbs: [{ name: 'Site Entries', link: 'SiteEntries' }],
        },
        component: () => import(/* webpackChunkName: "editEntry" */ '@/views/entries/EntryEdit.vue'),
      },
    ],
  },
]

export default EntryRoutes
