import { createRouter, createWebHistory } from 'vue-router'

import AdminRoutes from './admin-routes'
import SiteRoutes from './site-routes'
import EntryRoutes from './entry-routes'
import { onLogout } from '@/vue-apollo.js'

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: [
    { path: '/', redirect: '/dashboard' },
    {
      name: 'dashboard',
      path: '/dashboard',
      // route level code-splitting
      // this generates a separate chunk (home.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
    },
    // {
    //   path: '/testing',
    //   name: 'testing',
    //   component: () => import(/* webpackChunkName: "testing" */ '@/views/Testing.vue'),
    // },
    {
      name: 'login',
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
      meta: {
        public: true
      }
    },
    {
      name: 'logout',
      path: '/logout',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Logout.vue'),
      meta: {
        public: true
      }
    },
    {
      name: 'register',
      path: '/register',
      component: () => import(/* webpackChunkName: "register" */ '@/views/Register.vue'),
      meta: {
        public: true
      }
    },
    {
      name: 'forbidden',
      path: '/403',
      component: () => import(/* webpackChunkName: "register" */ '@/views/Forbidden.vue'),
      meta: {
        public: true
      }
    },
    {
      name: 'docs',
      path: '/docs',
      component: () => import(/* webpackChunkName: "docs" */ '@/views/docs/DocsWrapper.vue'),
    },
    ...AdminRoutes,
    ...SiteRoutes,
    ...EntryRoutes,
  ],
})

export default router
