<template>
  <div v-if="canCreate">
    <button :class="btnClass" @click.prevent="showModal = true">{{btnText}}</button>
    <modal-dialog :show="showModal" @close="closeModal">
      <h3 slot="header">Create Content Package</h3>
      <alert-message v-bind="alert" />

      <form id="create-item-form" class="space-y-2" @keyup.enter="createContentPkg">
        <rw-text v-model="title" label="Title" placeholder="Enter the title..." />
        <rw-select v-model="type" label="Entry Type" :options="contentPackageTypes" />
      </form>

      <div slot="footer" class="flex justify-end">
        <button class="btn btn-go" @click.prevent="createContentPkg">Create Content Package</button>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from '@/components/utils/ModalDialog'
import { CREATE_CONTENT_PACKAGE } from '@/graphql/content'
import { contentPackageTypes } from '@/settings/enums'

export default {
  name: 'ContentPkgCreate',
  props: {
    btnText: {
      type: String,
      default: 'Create Content Package',
    },
    btnClass: {
      type: String,
      default: 'btn btn-go btn-sm',
    },
    canCreate: {
      type: Boolean,
      default: false,
    }
  },
  components: { ModalDialog },
  data() {
    return {
      contentPackageTypes,
      showModal: false,
      alert: {
        type: null,
        message: null,
      },
      title: '',
      type: 'academy',
    }
  },
  methods: {
    reset() {
      this.title = ''
    },
    closeModal() {
      this.reset()
      this.alert.type = null
      this.alert.message = null
      this.showModal = false
    },
    createContentPkg() {
      this.alert.type = null
      this.alert.message = null
      this.$apollo
        .mutate({
          mutation: CREATE_CONTENT_PACKAGE,
          variables: {
            input: {
              title: this.title,
              type: this.type,
            },
          },
        })
        .then(({ data: { createContentPackage } }) => {
          this.alert.type = 'success'
          this.alert.message = `Content package created with the title: ${createContentPackage.title}`
          this.reset()
          this.$emit('item-created')
          document.querySelector('#create-item-form input[type="text"]').focus()
        })
        .catch(error => {
          this.alert.type = 'error'
          this.alert.message = error.message
        })
    },
  },
}
</script>

<style lang="scss">
</style>