<template>
  <div class="spinner">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <!-- <div class="rect4"></div>
    <div class="rect5"></div> -->
  </div>
</template>

<script>
  export default {
    name: 'Spinner'
  }
</script>

<style lang="scss">
.spinner {
  display: inline-block;
  height: 1rem;
  font-size: 1rem;
  color: inherit;
}

.spinner.large {
  height: 5rem;
  font-size: 5rem;
}

.spinner > div {
  background-color: currentColor;
  height: 100%;
  width: 0.375em; // default 6px -> 6/16
  margin: 0 0.0625em; // default 1px -> 1/16
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

/* .spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
} */

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
</style>