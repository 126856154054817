<template>
  <div v-if="context == 'preview'">
    <p v-if="!block.embedType" class="font-bold text-lg text-center">Choose an embed type.</p>

    <!-- Ebook Preview -->
    <div v-if="block.embedType === 'ebook' && ebook && ebook.id" class="my-4 flex items-center">
      <cld-image
        class="w-1/2 mx-8"
        v-if="ebook.content.teaser_image && ebook.content.teaser_image.cloudinaryId"
        :publicId="ebook.content.teaser_image.cloudinaryId"
      >
        <cld-transformation crop="fill" width="512" />
      </cld-image>
      <div class="w-1/2 mx-8">
        <p class="uppercase font-bold">{{ ebook.type }}</p>
        <h2>{{ ebook.content.title }}</h2>
        <p class="mb-4">{{ ebook.content.teaser.text }}</p>
        <a href="#" class="btn">Button Will Automatically Go Here</a>
      </div>
    </div>
    <p
      v-if="block.embedType === 'ebook' && !ebook"
      class="font-bold text-lg text-center"
    >Turn on AutoPilot or choose a featured eBook.</p>

    <!-- Magazine Issue Preview -->
    <div
      v-if="block.embedType == 'magazineIssue' && magazine && magazine.id"
      class="my-4 flex items-center"
    >
      <cloudinary-image 
        v-if="magazine.teaser_image && magazine.teaser_image.cloudinaryId"
        class="w-1/2 mx-8"
        width="512"
        crop="fill"
        :public-id="magazine.teaser_image.cloudinaryId" />
      <div class="w-1/2 mx-8">
        <h4>{{ magazine.title }}</h4>
        <template v-if="magazine.entries.length">
          <div v-for="entry in magazine.entries.slice(0, 3)" :key="entry.id">
            <h4 v-if="entry.content.title">{{ entry.content.title }}</h4>
            <p v-if="entry.content.teaser">{{ entry.content.teaser.text }}</p>
          </div>
        </template>
      </div>
    </div>
    <p
      v-if="block.embedType == 'magazineIssue' && !magazine"
      class="font-bold text-lg text-center"
    >Turn on AutoPilot or choose a featured magazine.</p>

    <!-- Entries Preview -->
    <div v-if="block.embedType == 'contentFeed' && entries" class="my-4 flex -mx-4">
      <template v-if="entriesToPreview.length">
        <div v-for="entry in entriesToPreview.slice(0, 3)" :key="entry.id" class="flex-1 px-4">
          <h4 v-if="entry.content.title">{{ entry.content.title }}</h4>
          <p v-if="entry.content.teaser">{{ entry.content.teaser.text }}</p>
        </div>
      </template>
    </div>
  </div>

  <!-- Settings  -->
  <div v-else>
    <rw-select
      :options="embedTypes"
      v-model="block.embedType"
      :includeNull="true"
      label="Embed Type"
      @update:modelValue="updateEmbedType"
    />
    <p class="my-2 font-bold">This block is deprecated. Please use the new "Account Content Package"</p>
    <light-switch
      v-if="block.embedType !== 'contentFeed'"
      v-model="block.embedAutopilot"
      class="my-4"
    ><template #label>Autopilot?</template></light-switch>

    <template v-if="!block.embedAutopilot">
      <div v-if="block.embedType == 'ebook'" class="my-4">
        <div v-if="ebook && ebook.content" class="flex items-start">
          <p class="mr-3">
            <strong>Current eBook</strong>
            <br />
            {{ ebook.content.title }}
          </p>
          <button @click="block.embedId = null">
            <fa-icon icon="minus-circle" />
          </button>
        </div>
        <p>
          <content-picker
            class="inline-block"
            @insert-items="insertElement"
            contentType="entries"
            allowedTypes="ebook"
            text="Choose Entry"
            btnLabel="Insert Entry"
          />
        </p>
      </div>

      <div v-if="block.embedType == 'magazineIssue'" class="my-4">
        <p v-if="magazine">{{ magazine.title }}</p>
        <p>
          <content-picker
            class="inline-block"
            @insert-items="insertElement"
            contentType="magazine"
            text="Choose Magazine"
          />
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { MAGAZINE_ISSUE_PREVIEW } from '@/graphql/magazines'
import { ENTRY } from '@/graphql/entries'

export default {
  name: 'ContentEmbed',
  mixins: [BlockMixin],
  data() {
    return {
      block: this.modelValue,
      currentContent: {},
      featuredEbook: {},
      featuredMagazine: {},
      embedTypes: {
        // 'entry': 'Entry',
        ebook: 'Ebook',
        magazineIssue: 'Magazine',
        contentFeed: 'Content Feed',
      },
    }
  },
  computed: {
    entriesToPreview() {
      return Object.keys(this.entries).map(key => {
        return this.entries[key]
      })
    },
    magazine() {
      let magazine = this.modelValue.embedAutopilot && this.currentContent ? this.currentContent.magazine : null
      return this.fetchMagazine ? this.featuredMagazine : magazine
    },
    ebook() {
      let ebook = this.modelValue.embedAutopilot && this.currentContent ? this.currentContent.ebook : null
      return this.fetchEbook ? this.featuredEbook : ebook
    },
    entries() {
      return this.currentContent && this.currentContent.entries ? this.currentContent.entries : null
    },
    fetchEbook() {
      return !this.modelValue.embedAutopilot && this.modelValue.embedId && this.modelValue.embedType == 'ebook'
    },
    fetchMagazine() {
      return !this.modelValue.embedAutopilot && this.modelValue.embedId && this.modelValue.embedType == 'ebook'
    },
  },
  apollo: {
    featuredEbook: {
      query: ENTRY,
      variables() {
        return {
          entryID: this.value.embedId,
        }
      },
      update: data => data.entry,
      skip() {
        return !this.fetchEbook
      },
    },
    featuredMagazine: {
      query: MAGAZINE_ISSUE_PREVIEW,
      variables() {
        return {
          magazineID: this.block.embedId,
        }
      },
      update: data => data.magazine,
      skip() {
        return !this.fetchMagazine
      },
    },
  },
  watch: {
    block: {
      deep: true,
      immediate: true,
      handler(val) {
        
        this.$emit('update:value', val)
        this.fetchCurrentContent()
      },
    },
  },
  methods: {
    fetchCurrentContent() {
      let scope = this
      let siteID = this.$route.params.siteID

      if (this.context !== 'preview' || !siteID) {
        return
      }

      if (Object.keys(scope.currentContent).length) {
        return
      } else {
        this.$api.post('/content-package', { site_id: siteID, latest: true }).then(({ data }) => {
          scope.currentContent = data
        })
      }
    },
    insertElement($event) {
      this.block.embedId = $event.data[0].id
    },
    updateEmbedType($event) {
      this.block.embedId = null;
      this.block.embedType = $event;
      //this.$set(this.block, 'embedId', null)
      //this.$set(this.block, 'embedType', $event)
    },
  },
}
</script>