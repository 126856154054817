import gql from 'graphql-tag'

export default gql`
  type Package {
    to: String!
    from: String!
  }
  type Page {
    id: String!
    name: String!
  }
  type Query {
    appName: String
    connected: Boolean!
    delivery: Package!
  }
  type Mutation {
    setConnected(value: Boolean!): Boolean
  }
`