<template>
  <v-select
    v-if="!$apollo.queries.sites.loading"
    class="bg-white site-select"
    @update:modelValue="onSelection"
    :modelValue="selected"
    placeholder="Choose a site"
    label="title"
    :options="sites"
  />
</template>

<script>
import { SITE_LIST } from '@/graphql/sites.js';

export default {
  name: 'SiteSelect',
  props: {
    useParams: {
      type: Boolean,
      default: false,
    },
    value: Object,
  },
  computed: {
    selected() {
      if (this.useParams && this.$route.params.siteID) {
        return this.sites.filter((el) => {
          return this.$route.params.siteID === el.id
        })
      } else {
        return this.value
      }
    },
  },
  apollo: {
    sites: {
      query: SITE_LIST,
      variables: { states: ['live', 'wip', 'tester'] },
    },
  },
  methods: {
    onSelection(selectedOption, id) {
      this.$emit('update:value', selectedOption)
    },
  },
}
</script>

<style lang="scss">
.vs__search, 
.vs__dropdown-menu {
  color: theme('colors.gray.800') !important;
}

#nav-bar {
  .site-select {
    width: 25rem;
    height: 28px;
    padding: 0;
  }
  .vs__search {
    margin-top: 0;
    height: 28px;
  }

  .vs__selected {
    margin-top: 0;
    margin-bottom: 0;
    height: 28px;
  }

  .vs__actions {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>