import axios from 'axios'

// Just a sidenote. The auth token doesn't fully get set if someone is logging in. There's 
// an additional setp in the Login.vue to set it.
export function setupApi (app) {
  const api = axios.create()
  api.defaults.baseURL = process.env.VUE_APP_API_PATH

  const token = localStorage.getItem('apollo-token')
  if (token) {
    api.defaults.headers.common['Authorization'] = 'Bearer ' + token
  }
  // Http is used for the site globals. Found that out the hard way. Whoops.
  app.config.globalProperties.$http = axios.create();
  app.config.globalProperties.$api = api;
}