import gql from 'graphql-tag'

let BlockContent = gql`
  fragment BlockContent on BlockPreset {
    id
    title
    block_type
    created_at
    content
  }
`

export const BLOCK_CONTENT_FRAGMENT = BlockContent;

export const BLOCK_PRESETS = gql`
  query BlockPresets($blockType: String, $orderBy: [OrderByClause!]) {
    blockPresets(block_type: $blockType, orderBy: $orderBy) {
      ...BlockContent
    }
  }
  ${BlockContent}
`

export const BLOCK = gql`
  query BlockPreset($blockID: ID!) {
    blockPreset(id: $blockID) {
      ...BlockContent
    }
  }
  ${BlockContent}
`


export const SAVE_BLOCK_PRESET = gql`
  mutation SaveBlockPreset($input: BlockPresetInput!) {
    saveBlockPreset(input: $input) {
      id
    }
  }
`

export const UPDATE_BLOCK_PRESET = gql`
  mutation UpdateBlockPreset($input: BlockUpdateInput!) {
    updateBlockPreset(input: $input) {
      id
      updated_at
    }
  }
`

export const DELETE_BLOCK_PRESET = gql`
  mutation DeleteBlockPreset($id: ID!) {
    deleteBlockPreset(id: $id) {
      id
    }
  }
`