<template>
  <div>
    <button :class="btnClass" v-html="text" @click="showModal = true"></button>
    <modal-dialog :show="showModal" @close="closeModal" size="medium">
      <template #header><h3 slot="header">Import Content</h3></template>

      <div class="space-y-2">
        <element-picker-table @selection-changed="updateSelection" :allowedTypes="[type]" />
      </div>

      <template #footer>
        <div class="flex justify-between">
          <div class="flex space-x-2">
            <span>Import:</span>
            <light-switch v-model="content"><template #label>Content?</template></light-switch>
            <light-switch v-model="seo"><template #label>SERP?</template></light-switch>
            <light-switch v-model="hero"><template #label>Hero?</template></light-switch>
          </div>
          <button
            class="ml-auto btn btn-go"
            :disabled="!element"
            @click.prevent="importContent"
          >Import Content</button>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import ElementPickerTable from '@/components/tables/ElementPickerTable'

export default {
  name: 'ElementImport',
  components: { ElementPickerTable },
  props: {
    type: {
      type: String,
      default: 'product',
    },
    text: {
      type: String,
      default: 'Import Content',
    },
    btnClass: {
      type: String,
      default: 'btn',
    },
  },
  data() {
    return {
      state: 'idle',
      showModal: false,
      element: {},
      content: true,
      seo: true,
      hero: true,
    }
  },
  methods: {
    closeModal() {
      this.showModal = false
    },
    updateSelection($event) {
      this.element = $event.length ? $event[0] : null
    },
    importContent() {
      let content = Object.assign({}, cloneDeep(this.element.content))
      let contentImport = {
        content: this.content ? content.content : null,
        seo: this.seo ? content.seo : null,
        hero: this.hero ? content.hero : null,
      }
      let options = [this.content ? 'Content' : null, this.seo ? 'SERP' : null, this.hero ? 'Hero' : null].join('\n')
      if (confirm(`Are you sure you want to overwrite:\n\n${options}`)) {
        this.$emit('import-content', contentImport)
        this.closeModal()
      }
    },
  },
}
</script>

<style>
</style>