<template>
  <div contenteditable="true" @input="update"></div>
</template>

<script>
export default {
  name: "Editable",
  props: [ 'modelValue' ],
  mounted() {
    this.$el.innerText = this.modelValue ? this.modelValue : null;
  },
  methods:{
    update: function(event) {
      this.$emit('update:modelValue', event.target.innerText);
    }
  }
}
</script>