const AdminRoutes = [
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminWrapper.vue'),
    children: [
      {
        path: '',
        name: 'AdminLand',
        component: () => import(/* webpackChunkName: "adminDashboard" */ '@/views/admin/AdminDashboard.vue'),
      },
      {
        path: '/globals',
        props: true,
        name: 'AdminGlobals',
        component: () => import(/* webpackChunkName: "adminGlobals" */ '@/views/admin/AdminGlobals.vue'),
      },
      {
        path: '/vendors',
        children: [
          {
            path: '/vendors',
            name: 'VendorsIndex',
            component: () => import(/* webpackChunkName: "vendorsIndex" */ '@/views/admin/VendorsIndex.vue'),
          },
          {
            path: ':vendorID',
            props: true,
            name: 'EditVendor',
            component: () => import(/* webpackChunkName: "vendorEdit" */ '@/views/admin/VendorEdit.vue'),
          }
        ]
      },
      {
        path: '/memberships',
        children: [
          {
            path: '/memberships',
            name: 'MembershipsIndex',
            component: () => import(/* webpackChunkName: "membershipsIndex" */ '@/views/admin/MembershipsIndex.vue'),
          },
          { 
            path: ':membershipID', 
            props: true, 
            name: 'MembershipEdit', 
            component: () => import(/* webpackChunkName: "membershipEdit" */ '@/views/admin/MembershipEdit.vue'),
          }
        ],
      },
      {
        path: '/blocks',
        children: [
          {
            path: '/blocks',
            name: 'BlocksIndex',
            component: () => import(/* webpackChunkName: "blocksIndex" */ '@/views/admin/BlocksIndex.vue'),
          },
          {
            path: ':blockID',
            props: true,
            name: 'BlockEdit',
            component: () => import(/* webpackChunkName: "blockEdit" */ '@/views/admin/BlockEdit.vue'),
          },
        ],
      },
      {
        path: '/sites',
        children: [
          {
            path: '/sites',
            name: 'SitesIndex',
            component: () => import(/* webpackChunkName: "siteIndex" */ '@/views/sites/SitesIndex.vue'),
          },
          {
            path: '/deleted',
            name: 'DeletedSites',
            component: () => import(/* webpackChunkName: "deletedSites" */ '@/views/sites/DeletedSites.vue'),
          },
        ],
      },
      {
        path: '/content',
        children: [
          {
            path: '/content',
            name: 'ContentPkgIndex',
            component: () => import(/* webpackChunkName: "contentPkgIndex" */ '@/views/content/ContentPkgIndex.vue'),
          },
          {
            path: ':packageID',
            props: true,
            name: 'ContentPkgEdit',
            component: () => import(/* webpackChunkName: "contentPkgEdit" */ '@/views/content/ContentPkg.vue'),
          },
        ],
      },
      {
        path: '/content-sets',
        children: [
          {
            path: '/content-sets',
            name: 'ContentSetIndex',
            component: () => import(/* webpackChunkName: "contentSetIndex" */ '@/views/content-sets/ContentSetIndex.vue'),
          },
          {
            path: ':contentSetID',
            props: true,
            children: [
              {
                path: '',
                props: true,
                name: 'ContentSet',
                component: () => import(/* webpackChunkName: "contentSetEdit" */ '@/views/content-sets/ContentSetEdit.vue'),
              },
              {
                path: ':itemID',
                props: true,
                name: 'ContentSetItem',
                component: () => import(/* webpackChunkName: "contentSetItem" */ '@/views/content-sets/ContentSetItem.vue'),
              },
            ]
          }
        ],
      },
      {
        path: '/elements',
        children: [
          {
            path: '/elements',
            name: 'ElementsIndex',
            component: () => import(/* webpackChunkName: "elementsIndex" */ '@/views/admin/ElementsIndex.vue'),
          },
          {
            path: ':elementID',
            props: true,
            name: 'ElementEdit',
            component: () => import(/* webpackChunkName: "elementEdit" */ '@/views/admin/ElementEdit.vue'),
          },
        ],
      },
      {
        path: '/magazines',
        children: [
          {
            path: '/magazines',
            name: 'MagazinesIndex',
            component: () => import(/* webpackChunkName: "magazinesIndex" */ '@/views/magazines/MagazinesIndex.vue'),
          },
          {
            path: ':magazineID/:tabName?',
            name: 'IssueEdit',
            props: true,
            meta: {
              breadcrumbs: [{ name: 'Magazine Issues', link: 'MagazinesIndex' }],
            },
            component: () => import(/* webpackChunkName: "editMagazineIssue" */ '@/views/magazines/IssueEdit.vue'),
          },
        ]
      },
      {
        path: '/entries',
        name: 'EntriesIndex',
        component: () => import(/* webpac kChunkName: "entriesIndex" */ '@/views/entries/EntriesIndex.vue'),
      },
      {
        path: '/permissions',
        children: [
          {
            path: '/permissions',
            name: 'PermissionsIndex',
            component: () => import(/* webpackChunkName: "entriesIndex" */ '@/views/admin/PermissionsIndex.vue'),
          },
          {
            path: 'role/:id', 
            name: 'RoleEdit', 
            props: true,
            component: () => import(/* webpackChunkName: "entriesIndex" */ '@/views/admin/RoleEdit.vue')
          },
        ]
      },
      {
        path: '/users',
        children: [
          {
            path: '/users',
            name: 'UsersIndex',
            component: () => import(/* webpackChunkName: "entriesIndex" */ '@/views/admin/UsersIndex.vue'),
          },
          {
            path: 'edit/:id', 
            name: 'UserEdit', 
            props: true,
            component: () => import(/* webpackChunkName: "entriesIndex" */ '@/views/admin/UserEdit.vue')
          },
        ]
      },

      {
        path: '/global-links',
        children: [
          {
            path: '/global-links',
            name: 'GlobalLinksIndex',
            component: () => import(/* webpackChunkName: "entriesIndex" */ '@/views/admin/GlobalLinks.vue'),
          },
          {
            path: 'edit/:id', 
            name: 'GlobalLinksEdit', 
            props: true,
            component: () => import(/* webpackChunkName: "entriesIndex" */ '@/views/admin/GlobalLinksEdit.vue')
          },
        ]
      },
      {
        path: '/oauth-table',
        name: 'OauthIndex',
        component: () => import(/* webpackChunkName: "entriesIndex" */ '@/views/admin/OauthIndex.vue'),
      },
    ],
  },
]

export default AdminRoutes
