<template>
  <div class="field" :class=" {'field-inline': layout == 'inline' }" v-if="items.length > 0">
    <label>{{ labelText }}</label>
    <select v-model="selected" :disabled="disabled" @change="$emit('update:modelValue', $event.target.value)">
      <option :value="null">Choose an item</option>
      <option
        v-for="option in items"
        :key="option.id"
        :value="option.id"
        v-html="optionLabel(option)"
      ></option>
    </select>
  </div>
</template>

<script>
import { CONTENT_SET } from '@/graphql/content-sets'
import { reactive } from 'vue';

export default {
  name: 'ContentSetItemPicker',
  props: {
    modelValue: String,
    contentSetID: String,
    label: String,
    layout: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contentSet: [],
      items: [],
      selected: reactive(this.modelValue) || null,
    }
  },
  computed: {
    labelText() {
      return this.label || 'Content Set Item'
    },
  },
  async mounted() {
    await this.fetchContentSet()
  },
  watch: {
    contentSetID: {
      handler(val) {
        if (!val) {
          this.items = []
        } else {
          this.fetchContentSet()
        }
      },
    },
  },
  methods: {
    async fetchContentSet() {
      this.state = 'loading'
      this.$api.post('content-sets', { content_set_id: this.contentSetID, action: 'fetch' }).then(({ data }) => {
        this.contentSet = data.contentSet
        this.items = data.items
      })
    },
    optionLabel(item) {
      return `${'-'.repeat(item.depth)} ${item.label}`
    },
  },
}
</script>

<style>
</style>