<template>
  <div v-if="context == 'preview'">
    <h3 class="mb-4" v-if="block.title">{{ block.title }}</h3>
    <p class="mb-4" v-if="block.subtitle">{{ block.subtitle }}</p>
    <div class="bg-gray-200">
      <div
        class="placeholder responsive-container w-full"
        :class="block.aspectRatio"
        data-placeholder="Video"
      >
        <fa-icon icon="video" class="text-6xl text-gray-600" />
      </div>
    </div>
    <p v-if="block.caption">{{ block.caption }}</p>
  </div>

  <div v-else class="space-y-2">
    <rw-text v-model="block.title" placeholder="Title" label="Title" />
    <rw-text v-model="block.subTitle" placeholder="SubTitle" label="SubTitle" />
    <div class="flex space-x-2">
      <rw-text
        class="w-1/2"
        v-model="block.videoUrl"
        placeholder="Enter the full url"
        label="URL"
      />
      <rw-select v-model="block.aspectRatio" :options="aspectRatios" label="Aspect Ratio" />
    </div>
    <div v-if="block.video && block.video.provider">
      <p class="mb-0">
        <strong>Provider:</strong>
        {{ block.video.provider }}
      </p>
      <p class="mb-0">
        <strong>Video ID:</strong>
        {{ block.video.id }}
      </p>
    </div>

    <rw-text v-model="block.caption" placeholder="Caption" label="Caption" />
  </div>
</template>

<script>
import urlParser from 'js-video-url-parser'

export default {
  name: 'Video',
  mixins: [BlockMixin],
  data() {
    return {
      aspectRatios: {
        'ratio-16-9': '16:9',
        'ratio-4-3': '4:3',
      },
    }
  },
  watch: {
    'block.videoUrl': {
      handler(newVal) {
        if (newVal) {
          let results = urlParser.parse(newVal)
          this.block.video = results
        } else {
          this.block.video = {}
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.placeholder svg {
  filter: blur(2px);
}

.placeholder::before {
  content: attr(data-placeholder) '\APlaceholder';
}

.responsive-container {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  &.ratio-16-9 {
    padding-bottom: 56.25%;
  }

  &.ratio-4-3 {
    padding-bottom: 75%;
  }

  .fa-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
</style>