<template>
  <div v-if="canAct">
    <button :class="btnClass" @click.prevent="showModal = true">Create Role</button>
    <modal-dialog :show="showModal" @close="closeModal">
      <template #header><h4 slot="header">{{ btnName }}</h4></template>

      <alert-message v-bind="alert" />

      <form id="create-item-form" class="space-y-2" @submit.prevent @keyup.enter="createRole">
        <rw-text v-model="roleMods.name" label="Name" placeholder="Role Name" />
      </form>

      <template #footer>
        <div class="flex justify-end">
          <button class="btn btn-go" @click.prevent="createRole">{{ btnName }}</button>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from '@/components/utils/ModalDialog'
import { CREATE_ROLE } from '@/graphql/roles-permissions'

export default {
  name: 'RoleCreate',
  props: {
    btnName: {
      type: String,
      default: 'Create Role'
    },
    btnClass: {
      type: String,
      default: 'btn btn-go btn-sm',
    },
    canAct: {
      type: Boolean,
      default: false,
    },
    role: {
      type: Object,
      default: () => {
        return {
          name: '',
        }
      }
    },
    mutate: {
      type: Object,
      default: () => {
        return CREATE_ROLE;
      },
    }
  },
  components: { ModalDialog },
  data() {
    return {
      showModal: false,
      name: '',
      roleMods: {},
      alert: {
        type: null,
        message: null,
      },
    }
  },
  mounted () {
    this.roleMods = structuredClone(this.role);
  },
  methods: {
    reset() {
      this.roleMods = {name: ''};
      
      this.type = null
    },
    closeModal() {
      this.reset()
      this.alert.type = null
      this.alert.message = null
      this.showModal = false
    },
    createRole() {
      this.error = null
      this.success = null
      let input = {
        name: this.roleMods.name
      }

      this.$apollo
        .mutate({
          mutation: this.mutate,
          variables: {
            input: input,
          },
        })
        .then(() => {
          this.$toasted.success('Role created!');
          this.reset()
          this.$emit('role-created');
          this.showModal = false;
        })
        .catch((error) => {
          this.alert.type = 'error'
          this.alert.message = error.message
        })
    },
  },
}
</script>