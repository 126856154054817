<template>
  <div
    class="draggable-item"
    :class="'type-' + block.blockType"
    :data-label="blockHoverName"
    @click.stop="editBlockContent"
    @mouseup.stop="editBlock = false"
  >
    <div class="actions flex justify-end items-center" v-if="icons == 'default'">
      <fa-icon :class="handleClass" icon="grip-vertical" />
      <button @click.stop="$emit('remove-child')" class="ml-2">
        <fa-icon icon="trash-alt" />
      </button>
    </div>
    <fa-icon v-if="icons == 'wrap'" :class="handleClass" icon="grip-vertical" class="mr-4 mt-2">Move</fa-icon>
    
    <component
      :class="block.blockType"
      v-model="block"
      :is="getType(block.blockType)"
      :context="context"
    />

    <portal to="blockSettings" name="blockItem" v-if="editBlock">
      <block-settings v-model="block" @insert-preset="insertPreset" context="settings" />
    </portal>

    <button v-if="icons == 'wrap'" @click.stop="$emit('remove-child')">
      <fa-icon class="ml-2 mt-2" icon="minus-circle">Trash</fa-icon>
    </button>
  </div>
</template>

<script>
import { useWormhole } from 'portal-vue';
import { isProxy, toRaw } from 'vue';

export default {
  name: 'DraggableItem',
  mixins: [BlockMixin],
  props: {
    handleClass: String,
    name: {
      type: Boolean,
      default: true,
    },
    icons: {
      type: String,
      default: 'default',
    },
    context: {
      type: String,
      default: 'preview',
    },
  },
  setup() {
    const wormhole = useWormhole();

    return {wormhole};
  },
  data() {
    return {
      editBlock: false,
      //wormhole: null,
    }
  },
  computed: {
    blockHoverName() {
      return this.block.blockLabel ? this.block.blockName + ': ' + this.block.blockLabel : this.block.blockName
    },
  },
  methods: {
    insertPreset($event) {
      let newBlock = Object.assign({}, { blockBaseId: $event.id, blockBaseName: $event.name }, $event.content)
      this.$emit('insert-preset', newBlock)
    },
    
    /**
     * Notes for the next crazy Vue upgrade (aka. Mana's been on this for weeks.)
     * 
     * Portal-vue vue 3 documentation is extremely lacking. Wormhole got updated but thanks
     * to a nice person in the forum (and Matt literally digging into the code), it took forever 
     * to figure out how Wormhole.close works. It needs to close to refresh the blocksettings portal
     * and the editBlock needs to be toggled to false and back to true. The best order I had was 
     * set editBlock to false on mouseout (thanks Matt), clear wormhole then set editBlock back to true. 
     * 
     * For some odd reason, setting the editBlock to false within the editBlockContent() wasn't cutting
     * it so it needed to be outside the click event. No idea why. 
     * 
     * You're welcome. Developing is hard. -_- 
     */
    editBlockContent() {
      this.wormhole.close({to: 'blockSettings', from: 'blockItem'});
      
      if (this.$el.closest('.content-settings')) {
        return
      }
      
      this.$pageBuilder.showTab('blockContent')
      this.$el.classList.add('active')
      this.editBlock = true;
    },
  },
}
</script>

<style lang="scss" scoped>
.draggable-item {
  min-height: 50px;
}

.draggable-item:hover {
  cursor: pointer;
}

[data-label]::before {
  content: attr(data-label);
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: auto;
  height: 20px;
  font-size: 14px;
  padding: 0 4px;
  background-color: theme('colors.orange.400');
  color: white;
}

[data-label]:hover::before {
  display: block;
}

.active {
  outline: 1px solid red;
}
</style>
