<template>
  <div class="breadcrumbs">
    <ul>
      <li
        v-for="(breadcrumb, idx) in breadcrumbList"
        :key="idx"
        :class="{'linked': !!breadcrumb.link}"
        @click="$router.push({ name: breadcrumb.link })"
        >
        {{ breadcrumb.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PageBreadcrumbs',
  data () {
    return {
      breadcrumbList: []
    }
  },
  mounted () { 
    this.updateList();
  },
  watch: {
    '$route' () { this.updateList() } },
  methods: {
    updateList () { this.breadcrumbList = this.$route.meta.breadcrumbs }
  }
}
</script>

<style scoped>
  .breadcrumbs {
    @apply text-lg;
  }

  ul {
    display: flex;
  }

  ul > li::before {
    content: '\203A';
    padding-right: 0.25em;
  }

  .linked {
    cursor: pointer;
    font-size: 1em;
    font-weight: normal;
  }
</style>