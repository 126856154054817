module.exports.default = [
  { family: 'fas', code: 'ambulance', label: 'ambulance' },
  { family: 'fas', code: 'angle-down', label: 'angle-down' },
  { family: 'fas', code: 'ambulance', label: 'ambulance' },
  { family: 'fas', code: 'baby', label: 'baby' },
  { family: 'fas', code: 'balance-scale', label: 'balance-scale' },
  { family: 'fas', code: 'band-aid', label: 'band-aid' },
  { family: 'fas', code: 'baseball-ball', label: 'baseball-ball' },
  { family: 'fas', code: 'basketball-ball', label: 'basketball-ball' },
  { family: 'fas', code: 'beer', label: 'beer' },
  { family: 'fas', code: 'briefcase', label: 'briefcase' },
  { family: 'fas', code: 'briefcase-medical', label: 'briefcase-medical' },
  { family: 'fas', code: 'building', label: 'building' },
  { family: 'fas', code: 'burn', label: 'burn' },
  { family: 'fas', code: 'calendar-alt', label: 'calendar-alt' },
  { family: 'fas', code: 'car', label: 'car' },
  { family: 'fas', code: 'car-mechanic', label: 'car-mechanic' },
  { family: 'fas', code: 'cat', label: 'cat' },
  { family: 'fas', code: 'child', label: 'child' },
  { family: 'fas', code: 'church', label: 'church' },
  { family: 'fas', code: 'clinic-medical', label: 'clinic-medical' },
  { family: 'fas', code: 'clipboard-check', label: 'clipboard-check' },
  { family: 'fas', code: 'clock', label: 'clock' },
  { family: 'fas', code: 'cocktail', label: 'cocktail' },
  { family: 'fas', code: 'code-branch', label: 'code-branch' },
  { family: 'fas', code: 'cogs', label: 'cogs' },
  { family: 'fas', code: 'concierge-bell', label: 'concierge-bell' },
  { family: 'fas', code: 'conveyor-belt-alt', label: 'conveyor-belt-alt' },
  { family: 'fas', code: 'cow', label: 'cow' },
  { family: 'fas', code: 'couch', label: 'couch' },
  { family: 'fas', code: 'cut', label: 'cut' },
  { family: 'fas', code: 'dog', label: 'dog' },
  { family: 'fas', code: 'dumbbell', label: 'dumbbell' },
  { family: 'fas', code: 'eye', label: 'eye' },
  { family: 'fas', code: 'external-link', label: 'external-link' },
  { family: 'fas', code: 'exclamation-circle', label: 'exclamation-circle' },
  { family: 'fas', code: 'exclamation-triangle', label: 'exclamation-triangle' },
  { family: 'fas', code: 'film', label: 'film' },
  { family: 'fas', code: 'fish', label: 'fish' },
  { family: 'fas', code: 'football-ball', label: 'football-ball' },
  { family: 'fas', code: 'gas-pump', label: 'gas-pump' },
  { family: 'fas', code: 'gavel', label: 'gavel' },
  { family: 'fas', code: 'glasses', label: 'glasses' },
  { family: 'fas', code: 'golf-ball', label: 'golf-ball' },
  { family: 'fas', code: 'hand-holding-heart', label: 'hand-holding-heart' },
  { family: 'fas', code: 'hand-holding-usd', label: 'hand-holding-usd' },
  { family: 'fas', code: 'handshake', label: 'handshake' },
  { family: 'fas', code: 'home', label: 'home' },
  { family: 'fas', code: 'home-lg-alt', label: 'home-lg-alt' },
  { family: 'fas', code: 'horse', label: 'horse' },
  { family: 'fas', code: 'hospital', label: 'hospital' },
  { family: 'fas', code: 'hotel', label: 'hotel' },
  { family: 'fas', code: 'industry-alt', label: 'industry-alt' },
  { family: 'fas', code: 'info-circle', label: 'info-circle' },
  { family: 'fas', code: 'laptop', label: 'laptop' },
  { family: 'fas', code: 'lightbulb', label: 'lightbulb' },
  { family: 'fas', code: 'lightbulb-dollar', label: 'lightbulb-dollar' },
  { family: 'fas', code: 'medkit', label: 'medkit' },
  { family: 'fas', code: 'microphone', label: 'microphone' },
  { family: 'fas', code: 'motorcycle', label: 'motorcycle' },
  { family: 'fas', code: 'music', label: 'music' },
  { family: 'fas', code: 'paw', label: 'paw' },
  { family: 'fas', code: 'paw-alt', label: 'paw-alt' },
  { family: 'fas', code: 'pig', label: 'pig' },
  { family: 'fas', code: 'piggy-bank', label: 'piggy-bank' },
  { family: 'fas', code: 'place-of-worship', label: 'place-of-worship' },
  { family: 'fas', code: 'plane', label: 'plane' },
  { family: 'fas', code: 'plug', label: 'plug' },
  { family: 'fas', code: 'ribbon', label: 'ribbon' },
  { family: 'fas', code: 'running', label: 'running' },
  { family: 'fas', code: 'search', label: 'search' },
  { family: 'fas', code: 'search-dollar', label: 'search-dollar' },
  { family: 'fas', code: 'seedling', label: 'seedling' },
  { family: 'fas', code: 'shield-alt', label: 'shield-alt' },
  { family: 'fas', code: 'shield-check', label: 'shield-check' },
  { family: 'fas', code: 'shopping-bag', label: 'shopping-bag' },
  { family: 'fas', code: 'shopping-basket', label: 'shopping-basket' },
  { family: 'fas', code: 'shopping-cart', label: 'shopping-cart' },
  { family: 'fas', code: 'shovel', label: 'shovel' },
  { family: 'fas', code: 'sign', label: 'sign' },
  { family: 'fas', code: 'siren-on', label: 'siren-on' },
  { family: 'fas', code: 'star', label: 'star' },
  { family: 'fas', code: 'stethoscope', label: 'stethoscope' },
  { family: 'fas', code: 'stopwatch', label: 'stopwatch' },
  { family: 'fas', code: 'store-alt', label: 'store-alt' },
  { family: 'fas', code: 'tags', label: 'tags' },
  { family: 'fas', code: 'teeth', label: 'teeth' },
  { family: 'fas', code: 'tennis-ball', label: 'tennis-ball' },
  { family: 'fas', code: 'toolbox', label: 'toolbox' },
  { family: 'fas', code: 'tools', label: 'tools' },
  { family: 'fas', code: 'tooth', label: 'tooth' },
  { family: 'fas', code: 'tractor', label: 'tractor' },
  { family: 'fas', code: 'tree', label: 'tree' },
  { family: 'fas', code: 'tree-alt', label: 'tree-alt' },
  { family: 'fas', code: 'truck', label: 'truck' },
  { family: 'fas', code: 'truck-loading', label: 'truck-loading' },
  { family: 'fas', code: 'truck-ramp', label: 'truck-ramp' },
  { family: 'fas', code: 'university', label: 'university' },
  { family: 'fas', code: 'user', label: 'user' },
  { family: 'fas', code: 'user-chart', label: 'user-chart' },
  { family: 'fas', code: 'user-check', label: 'user-check' },
  { family: 'fas', code: 'user-graduate', label: 'user-graduate' },
  { family: 'fas', code: 'user-hard-hat', label: 'user-hard-hat' },
  { family: 'fas', code: 'user-injured', label: 'user-injured' },
  { family: 'fas', code: 'user-md', label: 'user-md' },
  { family: 'fas', code: 'user-secret', label: 'user-secret' },
  { family: 'fas', code: 'user-shield', label: 'user-shield' },
  { family: 'fas', code: 'user-tag', label: 'user-tag' },
  { family: 'fas', code: 'users', label: 'users' },
  { family: 'fas', code: 'users-cog', label: 'users-cog' },
  { family: 'fas', code: 'utensils', label: 'utensils' },
  { family: 'fas', code: 'utensils-alt', label: 'utensils-alt' },
  { family: 'fas', code: 'wine-glass-alt', label: 'wine-glass-alt' },
  { family: 'fas', code: 'wheat', label: 'wheat' },
  { family: 'fas', code: 'x-ray', label: 'x-ray' },
  { family: 'fal', code: 'comment-alt-dollar', label: 'comment-alt-dollar (fal)' },
  { family: 'fal', code: 'file-alt', label: 'file-alt (fal)' },
  { family: 'fal', code: 'file-invoice', label: 'file-invoice (fal)' },
  { family: 'fal', code: 'globe-americas', label: 'globe-americas (fal)' },
  { family: 'fal', code: 'hand-holding', label: 'hand-holding (fal)' },
  { family: 'fal', code: 'money-check-edit-alt', label: 'money-check-edit-alt (fal)' },
  { family: 'fal', code: 'search', label: 'search (fal)' },
  { family: 'fal', code: 'user-friends', label: 'user-friends (fal)' },
]
