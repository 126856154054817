<template>
  <div>
    <div class="flex -mx-4 mb-6">
      <div class="w-1/2 px-4">
        <label>Filter by title</label>
        <input type="text" class="w-full" v-model="filters.title.value" placeholder="Title..." />
      </div>

      <div class="w-1/4 px-4">
        <label>Filter by type</label>
        <select v-model="filters.type.value" class="w-full">
          <option value>All types</option>
          <option value="advantage">Advantage</option>
          <option value="in-the-loop">In The Loop</option>
        </select>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <div>
        <apollo-loading v-if="$apollo.loading" />
        <VTable
          v-else
          class="bare striped smart-table"
          :data="magazineIssues"
          :filters="filters"
          :selectionMode="selectionMode"
          @stateChanged="$emit('selection-changed', $event.selectedRows)"
        >
          >
          <template #head>
            <th></th>
            <VTh sortKey="slug" defaultSort="desc">Slug</VTh>
            <VTh sortKey="title">Issue Name</VTh>
            <VTh sortKey="published_at">Publish Date</VTh>
            <VTh sortKey="type">Type</VTh>
            <th v-if="viewable || deletable"></th>
          </template>
          <template #body="{ rows }">
            <VTr
              v-for="magazine in rows"
              :key="magazine.id"
              :row="magazine"
              :class="{ 'selectable' : selectable }"
            >
              <td class="w-16">
                <cloudinary-image 
                  v-if="magazine.teaser_image && magazine.teaser_image.cloudinaryId"
                  height="75"
                  crop="fill"
                  :public-id="magazine.teaser_image.cloudinaryId" />
              </td>
              <td>{{ magazine.slug }}</td>
              <td>{{ magazine.title }}</td>
              <td>{{ formatDate(magazine.published_at) }}</td>
              <td>{{ magazine.type }}</td>
              <td
                v-if="viewable || deletable "
                class="w-1 whitespace-no-wrap space-x-2"
              >
                <router-link
                  v-if="viewable"
                  class="btn btn-sm btn-go"
                  :to="{ 'name': 'IssueEdit', params: {'magazineID': magazine.id }}"
                >{{ editable ? 'Edit' : 'View' }}</router-link>
                <delete-item
                  v-if="deletable"
                  :mutation="deleteItem"
                  :id="magazine.id"
                  @item-deleted="refreshTable"
                />
              </td>
            </VTr>
          </template>
        </VTable>
      </div>
    </transition>
  </div>
</template>

<script>
import { MAGAZINE_ISSUE_LIST, DELETE_MAGAZINE_ISSUE } from '@/graphql/magazines'

export default {
  name: 'MagazineIssuesTable',
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    selectionMode: {
      type: String,
      default: 'single',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    viewable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteItem: DELETE_MAGAZINE_ISSUE,
      magazineIssues: [],
      filters: {
        title: { value: '', keys: ['title'] },
        type: { value: '', keys: ['type'] },
      },
    }
  },
  apollo: {
    magazineIssues: {
      query: MAGAZINE_ISSUE_LIST,
    },
  },
  methods: {
    refreshTable() {
      this.$apollo.queries.magazineIssues.refetch()
    },
  },
}
</script>