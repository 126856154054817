<template>
  <div>
    <draggable
      class="mt-4 mb-4"
      :list="allLinks"
      :group="{ name: 'linkList' }"
      handle=".item-handle"
      item-key="_id"
    >
      <template #item="{ element, index }">
        <div class="draggable-item mb-4 pb-4 flex items-start border-b border-gray-500 space-x-2">
          <fa-icon class="item-handle" icon="grip-vertical">Move</fa-icon>

          <div class="flex-1">
            <div class="space-y-2">
              <rw-text v-model="element.title" label="Link Title" />
              <rw-text v-model="element.url" label="Url" />
            </div>
          </div>
          <button @click.stop="removeChild(index)">
            <fa-icon icon="minus-circle">Trash</fa-icon>
          </button>
        </div>
      </template>
      <template #footer>
        <button class="btn btn-icon" @click="addLink">
          <fa-icon icon="plus" />Add Link
        </button>
      </template>
    </draggable>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import BlockMixin from '@/components/mixins/block'

export default {
  name: 'ExternalLinkList',
  mixins: [BlockMixin],
  props: {
    allLinks: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    }
  },
  
  methods: {
    addLink() {
      let link = { title: null, url: null }
      this.allLinks.push(link)
    },
    removeChild(idx) {
      this.allLinks.splice(idx, 1);
    }
  },
}
</script>