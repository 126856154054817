<template>
  <div>
    <select v-model="localValue.type">
      <option :value="null">None</option>
      <option value="cpa-charge">CPA Charge</option>
    </select>
    <rw-text 
      class="mt-4" 
      v-if="localValue.type" 
      v-model="localValue.label" 
      label="Button Text" 
      placeholder="Enter the button text (defaults to 'Pay Bill')" />
    <rw-text 
    class="mt-4" 
    v-if="localValue.type == 'cpa-charge'" 
    v-model="localValue.url" 
    label="CPA Charge URL" 
    placeholder="Enter the full url" />
  </div>
</template>

<script>

export default {
  name: 'BillConnector',
  props: [ 'connector' ],
  defaults() {
    return {
      'type': null, 'label': null, 'url': null
    }
  },
  data() {
    return {
      localValue: Object.assign( {}, this.$options.defaults() , this.connector )
    }
  },
  watch: {
    localValue: {
      deep: true,
      handler(newVal) {
        newVal = newVal.type ? newVal : this.$options.defaults()

        return this.$emit('update:connector', newVal)
      }
    }
  }
}
</script>

<style>

</style>