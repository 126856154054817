import { reactive } from 'vue'

export default {
  props: {
    modelValue: {
      type: Object
    },
    context: {
      type: String,
      default: 'preview',
    },
  },
  data() {
    return {
      block: reactive(this.modelValue),
    }
  },
  watch: {
    block: {
      deep: true,
      handler(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  methods: {
    removeChild(idx) {
      this.block.children.splice(idx, 1)
    },
  },
}
