<template>
  <div>
    <light-switch :disabled="!editable" v-model="localValue.noindex">
      <template #label><span class="mx-2 strong font-mono">noindex</span> Do not show this page in search results and do not show a 'Cached' link in search results.</template>
    </light-switch>
    <light-switch class="mt-2" :disabled="!editable" v-model="localValue.nofollow">
      <template #label><span class="mx-2 strong font-mono">nofollow</span> Do not follow the links on this page.</template>
    </light-switch>
    <light-switch class="mt-2" :disabled="!editable" v-model="localValue.noarchive">
      <template #label><span class="mx-2 strong font-mono">noarchive</span> Do not show a 'Cached' link in search results.</template>
    </light-switch>
    <light-switch class="mt-2" :disabled="!editable" v-model="localValue.nosnippet">
      <template #label><span class="mx-2 strong font-mono">nosnippet</span> Do not show a text snippet or video preview in the search results for this page. A static thumbnail (if available) will still be visible.</template>
    </light-switch>
    <light-switch class="mt-2" :disabled="!editable" v-model="localValue.notranslate">
      <template #label><span class="mx-2 strong font-mono">notranslate</span> Do not offer translation of this page in search results.</template>
    </light-switch>
    <light-switch class="mt-2" :disabled="!editable" v-model="localValue.noimageindex">
      <template #label><span class="mx-2 strong font-mono">noimageindex</span> Do not index images on this page.</template>
    </light-switch>
  </div>
</template>

<script>

export default {
  name: 'SeoRobots',
  props: [ 'modelValue', 'editable' ],
  data() {
    return {
      localValue: Object.assign( {}, this.modelValue )
    }
  },
  watch: {
    localValue: {
      deep: true,
      handler(newVal) {
        return this.$emit('update:modelValue', newVal)
      }
    }
  },
}
</script>

<style>

</style>