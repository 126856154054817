<template>
  <div v-if="context == 'preview'">
    <h2 class="text-center mb-12">Resource Library Placeholder</h2>
    <div class="flex flex-wrap -mx-4">
      <div v-for="(item, idx) in count(3)" :key="idx" class="w-1/3 px-4 mb-8">
        <div class="border">
          <img src="//placehold.it/512x256" />
          <div class="p-4">
            <p class="font-bold text-lg">Placeholder content</p>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit quos dignissimos ipsa corrupti ex laborum sed molestiae tempora autem maxime.</p>
            <a href="#">Read more</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <p>No current settings for this block</p>
    
  </div>
</template>

<script>
export default {
  name: 'ResourceLibrary',
  mixins: [BlockMixin],
  data() {
    return {
      elementTypes: {
        blog: 'Blog Entries',
        ebook: 'Ebooks',
        magazine: 'Magazine Articles',
        quickRead: 'Quick Reads',
        magazineIssue: 'Magazine Issues',
      },
    }
  },
  methods: {
    count(itemCount) {
      return itemCount == 'none' ? 9 : parseInt(itemCount)
    },
  },
}
</script>