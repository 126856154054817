<template>
  <div class="flex-1" v-if="context == 'preview'">
    <div class="mb-4" v-if="block.preTitle || block.title || block.subTitle">
      <p class="m-0 text-center" v-if="block.preTitle">{{ block.preTitle }}</p>
      <p class="m-0 text-xl font-bold text-center" v-if="block.title">{{ block.title }}</p>
      <p class="mb-8 text-center" v-if="block.subTitle">{{ block.subTitle }}</p>
    </div>

    <img v-if="block.image" :src="block.image.src" />

    <component
      v-for="(child, idx) in block.children"
      :key="child._id"
      v-model="block.children[idx]"
      :is="getType(child.blockType)"
    ></component>

    <p class="mb-8" v-if="block.footerText">{{ block.footerText }}</p>
  </div>

  <div v-else>
    <div class="space-y-2 mb-4">
      <rw-select
        v-model="block.blockStyle"
        :options="styles"
        label="Block Style"
        :includeNull="true"
      />

      <rw-select
        v-model="block.titleStyle"
        :options="options"
        label="Block Title Style"
        :includeNull="true"
      />

      <template v-if="block.titleStyle == 'title'">
        <rw-text v-model="block.preTitle" label="Card PreTitle" />
        <div class="flex items-center space-x-1">
          <div class="field">
            <label>Style</label>
            <select name="headingLevel" v-model="block.titleColor" class="mr-2 h-auto">
              <option value :selected="!block.titleColor || block.titleColor == null">Default</option>
              <option value="text-primary">Primary</option>
              <option value="text-accent">Accent</option>
            </select>
          </div>
          <rw-text class="w-full" v-model="block.title" label="Card Title" />
        </div>
        <rw-text
          v-model="block.subTitle"
          label="Card SubTitle"
          placholder="Add a card subtitle"
        />
      </template>

      <rw-image
        class="my-2"
        v-if="block.titleStyle == 'image'"
        v-model="block.image"
        :context="context"
        :tinyPreview="true"
      />

      <rw-text
        v-model="block.footerText"
        label="Footer Text"
        placholder="Add text for the footer"
      />
    </div>

    <draggable
      class="-mx-2"
      :list="block.children"
      itemKey="_id"
      :group="{ name: 'cardChildren', put: ['cardChildren', 'contentBlocks'] }"
      handle=".child-handle"
    >
      <template #item="{ element, index }">
        <draggable-item
          class="mb-4 dashed-border"
          handleClass="child-handle"
          v-model="block.children[index]"
          context="settings"
          @remove-child="removeChild(index)"
        />  
      </template>
      <template #footer>
        <button class="btn btn-icon ml-2" @click="addItemList">
          <fa-icon icon="plus" />Add Item List
        </button>
      </template>
    </draggable>
  </div>
</template>

<script>
let uniqid = require('uniqid');
import { blockStyles, listCard, itemList } from '@/settings/blocks'
import defaultsDeep from 'lodash/defaultsDeep'
import cloneDeep from 'lodash/cloneDeep'
import {reactive} from 'vue'

export default {
  name: 'ListCard',
  mixins: [BlockMixin],
  data() {
    return {
      options: {
        image: 'Image',
        title: 'Title',
      },
      styles: blockStyles.listCard,
      block: defaultsDeep(reactive(this.modelValue), cloneDeep(listCard)),
    }
  },
  mounted() {
    // Vue 3 draggable requires an item-key and won't allow the index to be it. 
    // This will help the move not jumble the data and assign a uuid to each block.
    // This will make it where existing blocks work.
    this.block.children = this.block.children.map((child) => {
      return Object.assign({}, child, {_id: uniqid()});
    })
  },
  methods: {
    addItemList() {
      let newContent = Object.assign({}, cloneDeep(itemList), {_id: uniqid()});
      this.block.children.push(newContent)
    },
  },
}
</script>