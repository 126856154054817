<template>
  <div>
    <div class="flex justify-between items-end mb-2">
      <div class="mr-5 w-1/3">
        <rw-select 
          v-model="site.siteSettings.footer_version" 
          label="Footer Version" 
          :includeNull="false" 
          :options="{1: '1.0', 2: '2.0'}"
          :disabled="!editable" />
      </div>
      <div class="text-right">
        <light-switch 
          v-model="site.siteSettings.rw_branding" 
          :disabled="!editable">
            <template #label>Include RW Branding?</template>
        </light-switch>
      </div>
    </div>
    <div class="flex justify-between mt-4 gap-x-4">
      <div :class="{'w-1/2': showNewFields}">
        <h4 class="-mb-3">Additional Information / Disclaimer</h4>
      
        <rw-tip-tap 
          v-model="site.siteSettings.disclaimer" 
          :hidden-options="['heading', 'ruler']"
          ref="tipDisclaimer" 
          class="border mb-2 mt-0 mx-0" />
          <light-switch 
            :disabled="!editable" 
            v-if="showNewFields"
            :field.sync="site.siteSettings.show_mobile_disclaimer">
            <template #label>Show Disclaimer on mobile</template>
          </light-switch>
      </div>

      <div class="w-1/2" v-if="showNewFields">
        <h4 class="mb-0">Extra Links</h4>
        
        <div>
          <external-link-list :all-links="extraLinks" />
        </div>
      </div>
    </div>
    <br />
    <div class="flex justify-between">
      <h4>Memberships</h4>
      <rw-select 
        v-if="showNewFields"
        class="mb-3"
        v-model="site.siteSettings.membership_align" 
        label="Membership Alignment" 
        :options="{'start': 'Left', 'center': 'Center', 'end': 'Right'}"
        :disabled="!editable" />
    </div>
    <membership-form :editable="editable" :memberships="site.memberships" :membershipsToDelete="membershipsToDelete" />
    <br/>
    <h4 v-if="showNewFields">Logo / Tagline</h4>
    <div class="flex justify-between gap-x-4" v-if="showNewFields">
      <div class="w-1/2">
        <light-switch 
          :disabled="!editable"
          v-model="site.siteSettings.include_footer_logo">
          <template #label>Include Footer Logo?</template>
        </light-switch>
        <div v-if="site.siteSettings.include_footer_logo">
          <p class="text-sm mt-2">If no logo is set, the logo in the header of the site will be used.</p>
          <rw-image v-model="site.siteSettings.footer_logo" context="inline-preview" />
        </div> 
      </div>
      <div class="w-1/2">
        <rw-text
          :disabled="!editable"
          v-model="site.siteSettings.tagline"
          label="Tagline (max of 95 characters)"
          placeholder="Quick tagline"
          :max-length="95"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ExternalLinkList from '../blocks/ExternalLinkList.vue';
export default {
  components: { ExternalLinkList },
  name: 'FooterForm',
  props: ['site', 'editable', 'membershipsToDelete'],
  data() {
    return {
      extraLinks: []
    }
  },
  watch: {
    extraLinks: {
      //deep: true,
      handler(newVal) {
        this.site.siteSettings.extra_links = newVal;
        //this.$emit('update:value', newVal)
      },
    },
  },
  mounted() {
    this.extraLinks = this.site.siteSettings.extra_links;
  },
  computed: {
    showNewFields() {
      if (this.site.siteSettings.footer_version > 1) {
        return true;
      }

      return false;
    }
  },
  methods: {
  },
}
</script>