<template>
  <div class="teaser flex-1" v-if="context == 'preview'">
    <p>{{block.preTitle}}</p>
    <h2>{{block.title}}</h2>
    <p>{{block.text}}</p>
    <rw-link :value="block.link" v-if="includeLink" />
  </div>

  <div v-else>
    <rw-text
      v-if="includePretitle"
      :disabled="!editable"
      v-model="block.preTitle"
      placeholder="Enter a pre title"
      label="PreTitle"
    />
    <rw-text :disabled="!editable" v-model="block.title" placeholder="Enter a title" label="Title" />
    <rw-textarea :disabled="!editable" v-model="block.text" placeholder="Enter a description..." label="Text" />
    <rw-link :disabled="!editable" :value="block.link" v-if="includeLink" />
  </div>
</template>

<script>
export default {
  name: 'Teaser',
  mixins: [BlockMixin],
  props: {
    includeLink: {
      type: Boolean,
      default: true,
    },
    includePretitle: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: false,
    }
  },
}
</script>