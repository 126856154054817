<template>
  <div class="editor -m-2" :class="{ 'mt-6': context == 'preview'}">
    <section :editor="editor">
      <div class="menubar border-b border-t bg-gray-100 px-2 py-1">
        <div class="toolbar">
          <span>
            <button class="menubar__button"  @click="editor.chain().focus().undo().run()">
              <fa-icon icon="undo" size="sm" />
            </button>
            <button class="menubar__button" @click="editor.chain().focus().redo().run()">
              <fa-icon icon="redo" size="sm" />
            </button>
          </span>
          <span class="border-left">
            <button class="menubar__button" 
              alt="Bold" title="Bold"
              v-if="!hiddenOptions.includes('bold')"
              :class="{ 'is-active': editor.isActive('bold') }"
              @click="editor.chain().focus().toggleBold().run()">
              <fa-icon icon="bold" size="sm" />
            </button>
            <button class="menubar__button" 
              alt="Italic" title="Italic"
              v-if="!hiddenOptions.includes('italic')"
              :class="{ 'is-active': editor.isActive('italic') }"
              @click="editor.chain().focus().toggleItalic().run()">
              <fa-icon icon="italic" size="sm" />
            </button>
            <button class="menubar__button" 
              alt="Underline" title="Underline"
              v-if="!hiddenOptions.includes('underline')"
              :class="{ 'is-active': editor.isActive('underline') }"
              @click="editor.chain().focus().toggleUnderline().run()">
              <fa-icon icon="underline" size="sm" />
            </button>
          </span>
          <span class="border-left">
            <button class="menubar__button" 
              alt="Heading 2" title="Heading 2"
              v-if="!hiddenOptions.includes('heading')"
              :class="{ 'is-active': editor.isActive('heading', {level: 2}) }"
              @click="editor.chain().focus().toggleHeading({level: 2}).run()">
              <fa-icon icon="h2" size="sm" />
            </button>
            <button class="menubar__button"
              alt="Heading 3" title="Heading 3" 
              v-if="!hiddenOptions.includes('heading')"
              :class="{ 'is-active': editor.isActive('heading', {level: 3}) }"
              @click="editor.chain().focus().toggleHeading({level: 3}).run()">
              <fa-icon icon="h3" size="sm" />
            </button>
          </span>
          <span class="border-left">
            <button class="menubar__button" 
              alt="Left Align" title="Left Align"
              v-if="!hiddenOptions.includes('leftAlign')"
              :class="{ 'is-active': editor.isActive({textAlign: 'left'}) }"
              @click="setTextAlignment('left')">
              <fa-icon icon="align-left" size="sm" />
            </button>
            <button class="menubar__button" 
              alt="Center Align" title="Center Align"
              v-if="!hiddenOptions.includes('centerAlign')"
              :class="{ 'is-active': editor.isActive({textAlign: 'center'}) }"
              @click="setTextAlignment('center')">
              <fa-icon icon="align-center" size="sm" />
            </button>
            <button class="menubar__button" 
              alt="Right Align" title="Right Align"
              v-if="!hiddenOptions.includes('rightAlign')"
              :class="{ 'is-active': editor.isActive({textAlign: 'right'}) }"
              @click="setTextAlignment('right')">
              <fa-icon icon="align-right" size="sm" />
            </button>
          </span>
          <span class="border-left">
            <button class="menubar__button" 
              alt="Link" title="Link"
              v-if="!hiddenOptions.includes('link')"
              :class="{ 'is-active': editor.isActive('link') }"
              @click="editLink">
              <fa-icon icon="link" size="sm" />
            </button>
            <button class="menubar__button" 
              alt="Bullet List" title="Bullet List"
              v-if="!hiddenOptions.includes('bulletList')"
              :class="{ 'is-active': editor.isActive('bulletList') }"
              @click="editor.chain().focus().toggleBulletList().run()">
              <fa-icon icon="list" size="sm" />
            </button>
            <button class="menubar__button" 
              alt="Numbered List" title="Numbered List"
              v-if="!hiddenOptions.includes('orderedList')"
              :class="{ 'is-active': editor.isActive('orderedList') }"
              @click="editor.chain().focus().toggleOrderedList().run()">
              <fa-icon icon="list-ol" size="sm" />
            </button>
            <button 
              class="menubar__button" 
              alt="Horizontal Rule" title="Horizontal Rule"
              v-if="!hiddenOptions.includes('ruler')"
              @click="editor.chain().focus().setHorizontalRule().run()">
              <fa-icon icon="horizontal-rule" size="sm" />
            </button>
          </span>
        </div>
      </div>
    </section>
    <editor-content :editor="editor" />

    <modal-dialog :show="showModal" @close="closeModal">
      <form id="create-item-form" class="space-y-2" @submit.prevent="setLinkUrl">
        <rw-text v-model="linkUrl" label="Link URL" placeholder="https://" />
      </form>

      <template #footer>
        <div slot="footer" class="flex justify-end">
          <button class="btn btn-stop mr-2" @click.prevent="removeLink">Remove Link</button>
          <button class="btn btn-go" @click.prevent="setLinkUrl">Save</button>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
// Import the basic building blocks
import { Editor, EditorContent } from '@tiptap/vue-3'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import ModalDialog from '@/components/utils/ModalDialog'
import TextAlign from '@tiptap/extension-text-align'

const extensions = [
  StarterKit,
  Underline,
  Link,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
    alignments: ['left', 'right', 'center'],
  }),
]

export default {
  name: 'TipTap',
  components: { EditorContent, ModalDialog },
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    context: {
      type: String,
      default: 'preview',
    },
    // Use this to show/hide options on the toolbar. Options are: 
    // 'bold', 'italic', 'underline', 'link', 'ruler' (horizontal ruler--divider), 'link', 
    // 'bulletList', 'orderedList', 'heading' (hides both options), 'leftAlign', 'rightAlign', 'centerAlign'
    hiddenOptions: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      block: this.modelValue,
      editor: new Editor({
        onUpdate: ({ getHTML, getJSON }) => {
          this.block.jsonContent = this.editor.getJSON();
          this.block.htmlContent = this.editor.getHTML();
          
          this.$emit('update:modelValue', this.block);
        },
        content: this.modelValue.htmlContent || null,
        extensions: extensions,
      }),
      linkUrl: null,
      showModal: false,
    }
  },
  watch: {
    block: {
      deep: true,
      handler(val) {
        this.$emit('update:modelValue', val)
      },
    },
  },
  beforeUnmounted() {
    if (this.editor) this.editor.destroy()
  },
  methods: {
    editLink() {
      // If there's no selection, and the link is active, turn it off.
      if (this.editor.state.selection.ranges[0].$from == this.editor.state.selection.ranges[0].$to &&
          this.editor.isActive('link')) {
            this.removeLink();
            return ;
      }
      
      this.linkUrl = this.editor.isActive('link') ? this.editor.getAttributes('link').href : null;

      this.showModal = true;
    },
    setLinkUrl() {
      const vm = this;
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: vm.linkUrl })
        .run();

      this.closeModal();
    },
    removeLink() {
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .run();
    },
    closeModal() {
      this.linkUrl = null;
      this.showModal = false;
    },
    setTextAlignment(align = left) {
      if (!this.editor.isActive({textAlign: align})) {
        this.editor.chain().focus().setTextAlign(align).run();
      } else {
        this.editor.chain().focus().unsetTextAlign().run();
      }
    }
  },
}
</script>

<style lang="scss">
.ProseMirror {
  white-space: pre-wrap;
}
.ProseMirror-selectednode {
  box-shadow: 0 0 0 2px lightblue;
}

.ProseMirror {
  background-color: #fff;
}

.ProseMirror,
.content-preview {
  padding: 0.75rem;

  a {
    pointer-events: none;
    color: theme('colors.blue.600');
  }

  p,
  ol,
  ul {
    // @apply mb-4;
  }

  ol {
    @apply list-decimal pl-4;
  }

  ul {
    @apply list-disc pl-4;
  }

  ol,
  ul {
    ol,
    ul {
      @apply mb-0;
    }
  }

  li > p:first-child {
    // display: inline;
  }

  blockquote {
    @apply border-l-4 border-gray-400 pl-2;
  }
}

.border-left {
  @apply border-l-2 border-gray-400 ml-2 pl-2;
}

.menubar__button {
  @apply px-2 py-0 m-2;

  &.is-active {
    @apply bg-gray-600 text-white;
  }
}

.menububble {
  display: flex;
  position: absolute;
  z-index: 20;
  background: #333;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  transform: translateX(-10%) translateY(100%);
  transition: opacity 0.2s, visibility 0.2s;

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  &__button {
    display: inline-flex;
    background: transparent;
    border: 0;
    color: #fff;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: rgba(#fff, 0.1);
    }

    &.is-active {
      background-color: rgba(#fff, 0.2);
    }
  }

  &__form {
    display: flex;
    align-items: center;
  }

  &__input {
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    
    > input {
      font: inherit;
      background: transparent;
      color: #fff;
    }
   }
}
</style>