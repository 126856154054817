<template>
  <button class="btn btn-stop btn-sm ml-2" @click="deleteItem">
    <fa-icon icon="trash" />
  </button>
</template>

<script>

export default {
  name: 'DeleteItem',
  props: ['mutation', 'id', 'confirmation', 'result'],
  computed: {
    message() {
      return this.confirmation || 'Are you sure you want to delete this item?'
    },
  },
  methods: {
    deleteItem() {
      if (confirm(`${this.message}`)) {
        let mutation = this.mutation
        let id = this.id
        this.$apollo
          .mutate({
            mutation,
            variables: {
              id,
            },
          })
          .then(() => {
            this.$toasted.success('Item Deleted!')
            this.$emit('item-deleted', { id })
          })
          .catch(error => {
            this.$acorenErrors(error);
          })
      }
    },
  },
}
</script>
