<template>
  <div class="loading h-64 flex justify-center items-center text-gray-300">
    <spinner class="large" />
  </div>
</template>

<script>
export default {
  name: 'ApolloLoading',
}
</script>