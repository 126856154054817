<template>
  <div v-if="context == 'preview'">
    <rw-textarea v-model="block.text" placeholder="Text" label="Text" />
  </div>

  <div v-else>
    <p>No special settings for this block.</p>
  </div>
</template>

<script>
export default {
  name: 'Content',
  mixins: [BlockMixin],
}
</script>