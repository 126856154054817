<template>
  <div v-if="context == 'preview'">
    <div class="placeholder">
      <img src="/images/form.png" alt="Example form" />
    </div>
  </div>

  <div v-else>
    <rw-text
      v-model="block.source"
      placeholder="Ex. 'contact' or 'ebook'"
      label="Prospect source"
    />

    <rw-select :options="resultOptions" v-model="block.result" label="Success option" />

    <div v-if="block.result == 'thankYouMessage'" class="mt-4">
      <rw-textarea
        v-model="block.thankYouMessage"
        placeholder="Add a thank you message to be shown on successful form submission"
        label="Thank You Message"
        help="You can use Markdown. Yay!"
      />

      <light-switch v-model="block.includeLink" class="my-4"><template #label>Include a link?</template></light-switch>
      <rw-download
        class="mb-4"
        v-model="block.downloadLink"
        context="settings"
        v-if="block.includeLink"
      />
    </div>

    <div v-if="block.result == 'redirect'">
      <rw-link 
        v-model="block.link" 
        context="settings" 
        :showClassInput=false
        :showTextInput=false
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormEmbed',
  mixins: [BlockMixin],
  data() {
    return {
      resultOptions: {
        redirect: 'Redirect',
        thankYouMessage: 'Inline Message',
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.placeholder::before {
  content: 'Form\APlaceholder';
}
</style>