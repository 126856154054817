<template>
  <div v-if="!$apollo.loading">
    <draggable
      :list="value"
      :group="{ name: 'memberships' }"
      handle=".membership-handle"
      @end="setOrder"
      item-key="index"
    >
      <template #item="{ element, index }">
        <div class="flex items-start py-4 border-t">
          <fa-icon class="membership-handle mr-2 mt-1" v-if="editable" icon="grip-vertical">Move</fa-icon>
          <div class="flex-none w-1/2 space-y-2">
            <v-select
              :disabled="!editable"
              class="bg-white"
              v-model="element.membership_id"
              :reduce="selected => selected.id"
              placeholder="Choose a membership"
              label="title"
              :options="memberOptions"
            />
            <rw-text v-model="element.url" label="Override URL" help="Include https://" />
          </div>
          <button class="ml-2" v-if="editable" @click.stop="deleteMembership(element, index)">
            <fa-icon icon="minus-circle">Trash</fa-icon>
          </button>
        </div>
      </template>
    </draggable>
    <div class="pt-4 border-t" v-if="editable">
      <button class="btn" @click="addMembership">Add Membership</button>
    </div>
  </div>
</template>

<script>
import { MEMBERSHIP_LIST } from '@/graphql/memberships'

export default {
  name: 'MembershipForm',
  props: ['memberships', 'membershipsToDelete', 'editable'],
  data() {
    return {
      memberOptions: {},
    }
  },
  computed: {
    value: {
      get() {
        return this.memberships || []
      },
      set(val) {
        this.$emit('update:memberships', newVal)
      },
    },
  },
  apollo: {
    memberOptions: {
      query: MEMBERSHIP_LIST,
      variables() {
          return {
            first: 10000,
            orderBy: [{ order: 'ASC', field: 'TITLE'}]
          };
        },
      update: data => data.memberships.data,
    },
  },
  methods: {
    addMembership() {
      const membership = Object.assign({}, { order: this.memberships.length, url: null })
      this.value.push(membership)
    },
    deleteMembership(membership, idx) {
      membership.id ? this.membershipsToDelete.push(membership.id) : null
      this.value.splice(idx, 1)
    },
    setOrder(evt) {
      this.value.forEach((element, idx) => {
        element.order = idx
      })
    },
  },
}
</script>

<style>
</style>