import gql from 'graphql-tag'

let PeopleDetails = gql`
  fragment PeopleDetails on Person {
    id
    personable_id
    personable_type
    order
    first_name
    last_name
    prefix
    suffix
    job_title
    short_bio
    full_bio
    image
    social_media
    contact
    group_id
    link
    include_link
  }
`

export const PEOPLE_DETAILS_FRAGMENT = PeopleDetails

export const PERSON_DETAILS = gql`
  query PersonDetails($id: ID!) {
    person(id: $id) {
      ...PeopleDetails
    }
  }
  ${PeopleDetails}
`

export const UPSERT_PERSON = gql`
  mutation UpsertPerson($input: UpsertPersonInput!) {
    upsertPerson(input: $input) {
      ...PeopleDetails
    }
  }
  ${PeopleDetails}
`