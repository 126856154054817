import { createApolloProvider } from '@vue/apollo-option'
import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink, concat } from "apollo-link";

import localState from '@/graphql/local/state.js'
import localSchema from '@/graphql/local/schema.js'
import localResolvers from '@/graphql/local/resolvers.js'

export const AUTH_TOKEN = 'apollo-token'
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:3000/graphql'

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: httpEndpoint
})

// Cache implementation
const cache = new InMemoryCache();

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
  const token = localStorage.getItem(AUTH_TOKEN);
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });
    return forward(operation);
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: cache,
  resolvers: localResolvers, 
  typeDefs: localSchema,
  connectToDevTools: true,
  onCacheInit: cache => {
    const data = localState
    cache.writeData({ data })
  },
})

export function createProvider(options = {}) {
  const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      )
    },
  })

  return apolloProvider
}

export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token)
  }

  //if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

export async function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN)
  }
  //if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}

import ME_QUERY from '@/graphql/queries/Me.gql'
import USER_QUERY from '@/graphql/queries/GetUserByEmail.gql'

export async function isLoggedIn(apolloClient) {
  if (typeof localStorage === 'undefined' || localStorage.getItem(AUTH_TOKEN) === null) return false
  const { data } = await apolloClient.query({
    query: ME_QUERY,
  })
  return data !== null && data.me !== null
}

export async function authenticatedUser(apolloClient) {
  if (typeof localStorage === 'undefined' || localStorage.getItem(AUTH_TOKEN) === null) return null
  
  const { data } = await apolloClient.query({
    query: ME_QUERY
  });

  if (data === null || data.me === null) return null
  return data.me
}

export async function getUserPermissions(apolloClient, userEmail) {
  let returnData = {
    permissions: [],
    roles: []
  };
  
  if (!userEmail) return;

  await apolloClient.query({
    query: USER_QUERY,
    variables: {
      email: userEmail
    },
  }).then(({ data: { userByEmail } }) => {
    const permissions = userByEmail.permissions.map(perm => perm.name);
    const roles = userByEmail.roles.map(role => role.name);
    
    returnData = {
      permissions: permissions,
      roles: roles,
    };
  });

  return returnData;
}