<template>
  <div>
    <div class="tab-controls my-4">
      <button
        :class="{ 'active': activeTab == 'optimisation' }"
        @click="activeTab = 'optimisation'"
      >Optimisation</button>
      <button :class="{ 'active': activeTab == 'social' }" @click="activeTab = 'social'">Social</button>
      <button
        :class="{ 'active': activeTab == 'advanced' }"
        @click="activeTab = 'advanced'"
      >Advanced</button>
    </div>

    <div v-if="activeTab == 'optimisation'">
      <h4 class="mb-0">Snippet</h4>
      <p
        class="text-sm"
      >This is how the page might look in Google. Click the text to edit. The title should be 50-60 characters. The description should be &lt; 160 characters.</p>

      <label>
        <input type="checkbox" v-model="localValue.removeSiteName" /> Remove Sitename?
      </label>

      <div class="social-preview mx-auto my-8 border border-gray-400 p-4">
        <div class="font-bold m-0 flex items-center text-blue-600 text-xl">
          <rw-editable
            v-model="localValue.title"
            placeholder="Entry Title"
            class="py-0 px-2 bg-gray-200"
          />
          <span v-if="!localValue.removeSiteName" class="mx-2 self-start">|</span>
          <span v-if="!localValue.removeSiteName">SiteName.com</span>
        </div>
        <p
          class="my-1 text-green-700"
        >https://sitename.com/{{ entry.__typename == 'Page' ? entry.uri : 'base-url/' + entry.slug }}</p>
        <rw-limited-text v-model="localValue.description" :limit="160" class="bg-gray-200" />
      </div>

      <h4 class="mb-0">Keywords</h4>
      <p class="text-sm">
        This meta tag is actually ignored by Google. But, we could use this to check our scores via some API or do research
        <a
          href="https://ahrefs.com/blog/google-keyword-planner/"
          targe="_blank"
        >with Google</a> or
        <a href="https://ahrefs.com/blog/keyword-research/" target="_blank">Ahrefs</a>.
      </p>
      <div class="social-preview mx-auto my-8 border border-gray-400 p-0">
        <rw-editable
          v-model="localValue.keywords"
          placeholder="Enter each keyword separated by comma"
          class="py-1 px-2 bg-gray-200"
        />
      </div>
    </div>

    <div v-if="activeTab == 'social'">
      <h4 class="mb-0">Twitter</h4>
      <p class="text-sm">
        This is how the page will look when shared on Twitter. Descriptions will be truncated to 1 line on web, 2 lines on mobile.
        <a
          href="https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/summary-card-with-large-image"
          target="_blank"
        >More info.</a>
      </p>
      <div class="bg-gray-100 border border-gray-400 rounded relative">
        <fa-icon size="3x" :icon="['fab', 'twitter']" class="absolute top-0 right-0 mt-4 mr-4" />
        <div class="social-preview twitter mx-auto bg-white border border-gray-400 p-4 my-8">
          <seo-image v-model="localValue.twitter.image" edit-context="teaser" />
          <div class="font-bold m-0 flex items-center border-t pt-4 mt-4 mb-2 -mx-4 px-4">
            <rw-editable
              v-model="localValue.twitter.title"
              placeholder="Entry Title"
              class="py-0 px-2 bg-gray-200"
            />
          </div>
          <rw-limited-text
            v-model="localValue.twitter.description"
            :limit="160"
            class="bg-gray-200"
          />
          <p class="my-1 text-gray-600">https://sitename.com/</p>
        </div>
      </div>

      <h4 class="mb-0">Facebook</h4>
      <p class="text-sm">This is how the page will look when shared on Facebook.</p>
      <div class="bg-gray-100 border border-gray-400 rounded relative">
        <fa-icon size="3x" :icon="['fab', 'facebook']" class="absolute top-0 right-0 mt-4 mr-4" />
        <div class="social-preview mx-auto bg-white border border-gray-400 p-4 my-8">
          <seo-image v-model="localValue.fb.image" />
          <div class="border-t pt-2 mt-4 mb-2 -mx-4 px-4">
            <p class="text-gray-600">sitename.com</p>
            <div class="font-bold m-0 flex items-center">
              <rw-editable
                v-model="localValue.fb.title"
                placeholder="Entry Title"
                class="py-0 px-2 bg-gray-200"
              />
            </div>
          </div>
          <rw-limited-text v-model="localValue.fb.description" :limit="160" class="bg-gray-200" />
        </div>
      </div>
    </div>

    <div v-if="activeTab == 'advanced'">
      <h4 class="mb-0">Robots</h4>
      <p class="text-sm">
        Control how this page is indexed by crawlers (some of these are Google specific). If this element as an expiry date the unavailable_after directive will be set automatically.
      </p>

      <seo-robots :editable="editable" v-model="localValue.robots" />

      <h4 class="mb-0">Canonical</h4>
      <p class="text-sm">
        If this is not the canonical version of this page, add the canonical URL here. The current URL of the page will be used if this is left blank.
      </p>
      <rw-text
        v-model="localValue.canonical"
        label="Canonical URL"
        placeholder="This must be a valid, absolute URL. i.e. https://mysite.com"
      />
    </div>
  </div>
</template>

<script>
import defaultsDeep from 'lodash/defaultsDeep'

export default {
  name: 'SeoForm',
  props: {
    modelValue: {
      type: Object,
    },
    entry: {
      type: Object,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    localValue: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.$emit('update:modelValue', newVal)
      },
    },
  },
  data() {
    return {
      activeTab: 'optimisation',
      localValue: defaultsDeep(this.modelValue, this.seoDefaults()),
    }
  },
  methods: {
    seoDefaults() {
      let localEntry = Object.assign({}, this.entry)

      let title = localEntry.title || ''
      let description = localEntry.teaser ? localEntry.teaser.text : ''
      let image = localEntry.teaser_image || {}

      return {
        title: title,
        description: description,
        keywords: null,
        canonical: null,
        fb: {
          title: title,
          description: description,
          image: image,
        },
        twitter: {
          title: title,
          description: description,
          image: image,
        },
        robots: {
          noindex: false,
          nofollow: false,
          noarchive: false,
          nosnippet: false,
          notranslate: false,
          noimageindex: false,
        },
      }
    },
  },
}
</script>

<style lang="scss">
.social-preview {
  padding: 1rem;
  width: calc(600px + 2rem);

  &.twitter {
    width: calc(550px + 2rem);
  }
}

[data-icon='facebook'] {
  color: #4267b2;
}

[data-icon='twitter'] {
  color: #1da1f2;
}
</style>