import Echo from "laravel-echo"
// window.io = require('socket.io-client');
import io from 'socket.io-client'

const bearerToken = localStorage.getItem('apollo-token')
const socketConfig = {
  auth: {
    headers: {
      Authorization: 'Bearer ' + bearerToken,
    },
  },
  broadcaster: process.env.VUE_APP_SOCKET_BROADCASTER,
  host: process.env.VUE_APP_SOCKET_HOST,
  client: io,
  transports: ['websocket'],
  // transports:   ['websocket', 'polling', 'flashsocket']
}

window.Echo = null

if (process.env.VUE_APP_ENABLE_SOCKETS) {
  window.Echo = new Echo(socketConfig)
}