<template>
  <div v-if="canCreate">
    <button :class="btnClass" @click.prevent="showModal = true">{{ btnText }}</button>
    <modal-dialog :show="showModal" @close="closeModal">
      <template #header><h4 slot="header">Create Content Set</h4></template>

      <alert-message v-bind="alert" />

      <form id="create-item-form" class="space-y-2" @keyup.enter="createItem" @submit.prevent>
        <rw-text v-model="title" label="Title" placeholder="Enter the title..." />
        <rw-select v-model="type" label="Type" :options="contentSetTypes" />
        <div v-if="siteID" class="field checkbox">
          <label>
            <input type="checkbox" v-model="assignToSite" class="mr-2" />
            Assign Content Set to site ID: {{ siteID }}
          </label>
        </div>
      </form>

      <template #footer>
        <div class="flex justify-end">
          <button class="btn btn-go" @click.prevent="createItem">Create Content Set</button>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from '@/components/utils/ModalDialog'
import { CREATE_CONTENT_SET } from '@/graphql/content-sets'

export default {
  name: 'ContentSetCreate',
  props: {
    siteID: String,
    btnText: {
      type: String,
      default: 'Create Content Set',
    },
    btnClass: {
      type: String,
      default: 'btn btn-go btn-sm',
    },
    canCreate: {
      type: Boolean,
      default: false,
    }
  },
  components: { ModalDialog },
  data() {
    return {
      assignToSite: this.siteID ? true : false,
      contentSetTypes: {
        // location: 'Locations',
        page: 'Pages',
        people: 'People',
        product: 'Products',
        section: 'Section',
      },
      showModal: false,
      title: '',
      type: null,
      alert: {
        type: null,
        message: null,
      },
    }
  },
  methods: {
    reset() {
      this.title = ''
      this.type = null
    },
    closeModal() {
      this.reset()
      this.alert.type = null
      this.alert.message = null
      this.showModal = false
    },
    createItem() {
      this.error = null
      this.success = null
      this.$apollo
        .mutate({
          mutation: CREATE_CONTENT_SET,
          variables: {
            input: {
              title: this.title,
              type: this.type,
              site_id: this.assignToSite && this.siteID ? this.siteID : null,
            },
          },
        })
        .then(({ data: { createContentSet } }) => {
          this.alert.type = 'success'
          this.alert.message = `Content set created with the title: ${createContentSet.title}`
          this.reset()
          this.$emit('item-created')
          document.querySelector('#create-item-form input[type="text"]').focus()
        })
        .catch((error) => {
          this.alert.type = 'error'
          this.alert.message = error.message
        })
    },
  },
}
</script>

<style lang="scss">
</style>