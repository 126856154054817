<template>
  <div>
    <div class="flex items-end">
      <select v-model="selected">
        <option :value="null">Choose a content set to import</option>
        <option v-for="option in contentSets" :key="option.id" :value="option.id">{{ option.title }}</option>
      </select>
      <button class="btn btn-go ml-2" v-if="selected" @click="importContentSet">Import</button>
    </div>
  </div>
</template>

<script>
import { GLOBAL_CONTENT_SETS } from '@/graphql/content-sets'

export default {
  name: 'ContentSetImport',
  props: {
    contentSetID: String,
    allowedTypes: {
      type: Array,
      default() {
        return ['product']
      },
    },
  },
  data() {
    return {
      contentSets: [],
      selected: null,
    }
  },
  apollo: {
    contentSets: {
      fetchPolicy: 'no-cache',
      query: GLOBAL_CONTENT_SETS,
      variables() {
        return {
          types: this.allowedTypes,
        }
      },
    },
  },
  methods: {
    importContentSet() {
      if (confirm('Importing a content set will overwrite the current set. Are you sure?')) {
        this.state = 'loading'
        this.$api
          .post('content-sets', { content_set_id: this.contentSetID, action: 'importItems', from_set: this.selected })
          .then(({ data }) => {
            this.$emit('imported', data.items)
          })
      }
    },
  },
}
</script>

<style>
</style>