<template>
  <div class="secondary-nav-form">
    <draggable :list="navItems" item-key="_id" :group="{ name: 'navItems' }" handle=".item-handle">
      <template #item="{ element, index }">
        <div class="flex items-start mb-4 pb-4 border-b">
          <fa-icon class="item-handle mr-4 mt-1" icon="grip-vertical">Move</fa-icon>
          <div class="flex-1">
            <rw-link
              class="w-1/2"
              v-model="navItems[index]"
              context="settings"
              :types="['entry', 'custom', 'form']"
            />
          </div>
          <button class="ml-2" @click.stop="navItems.splice(index, 1)">
            <fa-icon icon="minus-circle">Trash</fa-icon>
          </button>
        </div>
      </template>
    </draggable>
    <button class="btn" @click="addItem">Add Menu Item</button>
  </div>
</template>

<script>
import { link } from '@/settings/blocks'
import makeUnique from '@/components/mixins/makeUnique'

export default {
  name: 'SecondaryNav',
  props: ['modelValue'],
  mixins: [makeUnique],
  data() {
    return {
      navItems: [],
    }
  },
  created() {
    this.navItems = this.modelValue.length ? this.makeUnique(this.modelValue) : []
  },
  watch: {
    navItems: {
      deep: true,
      handler(val) {
        val = this.makeUnique(val)
        this.$emit('update:modelValue', val)
      },
    },
  },
  methods: {
    addItem() {
      let navItem = Object.assign({}, link)
      this.navItems.push(navItem)
    },
  },
}
</script>

<style>
</style>