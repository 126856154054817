import gql from 'graphql-tag'

let RoleDetails = gql`
  fragment RoleDetails on Role {
    id
    name
    permissions {
        name
        page_name
    }
  }
`

let PermissionDetails = gql`
  fragment PermissionDetails on Permission {
    id
    name
    page_name
    section
  }
`

let PermissionListFragment = gql`
  fragment PermissionListFragment on PermissionList {
    key 
    permissions {
        name
        page_name
    }
  }
`

let RoleDetailsInput = gql`
  fragment RoleDetailsInput on Role {
    id
    name
    permissions {
        name
    }
  }
`

export const ROLES_FRAGMENT = RoleDetails;

export const ALL_ROLES = gql`
  query Roles {
    roles {
        ...RoleDetails
    }
  }
  ${RoleDetails}
`

export const ALL_PERMISSIONS = gql`
  query Permissions {
    permissions {
        ...PermissionDetails
    }
  }
  ${PermissionDetails}
`

export const PERMISSIONS_LIST = gql`
  query PermissionsList {
    permissionsList {
        ...PermissionListFragment
    }
  }
  ${PermissionListFragment}
`

export const DELETE_ROLE = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id) {
      id
    }
  }
`

export const CREATE_ROLE = gql`
  mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      ...RoleDetails
    }
  }
  ${RoleDetails}
`

export const UPDATE_ROLE = gql`
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      ...RoleDetailsInput
    }
  }
  ${RoleDetailsInput}
`

export const ROLE = gql`
  query Role($id: ID!) {
    role(id: $id) {
      ...RoleDetails
    }
  }
  ${RoleDetails}
`