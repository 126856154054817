<template>
  <div v-if="$can('SiteEditPage', 'create')">
    <button :class="btnClass" @click.prevent="openModal">{{btnText}}</button>
    <modal-dialog :show="showModal" @close="closeModal">
      <template #header><h3 slot="header">Create New Page</h3></template>
      <div v-if="error" class="inline-alert error" role="alert">
        <strong class="font-bold">Something bad happened:</strong>
        <span class="block sm:inline">{{ error }}</span>
      </div>

      <div v-if="success" class="inline-alert success" role="alert">
        <strong class="font-bold mr-1">Success!</strong>
        <span class="block sm:inline">{{ success }}</span>
      </div>

      <form ref="createForm" class="space-y-2" @keyup.enter="createPage">
        <rw-text v-model="pageTitle" label="Page Title" placeholder="Title" />
        <rw-text v-model="pageSlug" label="Page Slug" placeholder="Slug" />
        <rw-select v-model="pageType" :options="pageOptions" label="Page Type" />
      </form>

      <template #footer>
        <div class="flex justify-end">
          <button class="btn btn-go" @click.prevent="createPage">Create Page</button>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from '@/components/utils/ModalDialog'
import slugify from '@sindresorhus/slugify'
import { CREATE_PAGE } from '@/graphql/pages.js'

export default {
  name: 'PageCreate',
  props: {
    siteID: String,
    btnText: {
      type: String,
      default: 'Create Page',
    },
    btnClass: {
      type: String,
      default: 'btn btn-go btn-sm',
    },
  },
  components: { ModalDialog },
  data() {
    return {
      showModal: false,
      pageTitle: '',
      pageType: 'default',
      pageOptions: {
        default: 'Default',
        redirect: 'Redirect',
      },
      error: null,
      success: null,
    }
  },
  computed: {
    pageSlug: {
      get() {
        return slugify(this.pageTitle)
      },
      set(val) {
        return val
      },
    },
  },
  methods: {
    openModal() {
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
      this.success = null
      this.error = null
    },
    createPage() {
      this.error = null
      this.$apollo
        .mutate({
          mutation: CREATE_PAGE,
          variables: {
            input: {
              site_id: this.siteID,
              title: this.pageTitle,
              slug: this.pageSlug,
              type: this.pageType,
            },
          },
        })
        .then(({ data: { createPage } }) => {
          this.success = `Page created with the title: ${createPage.title}`
          this.$emit('page-created')
          // this.$router.push('/sites/' + this.siteID + '/pages/' + createPage.id )
          this.pageTitle = ''
          this.pageSlug = ''
          this.$refs.createForm.querySelector('input[type="text"]').focus()
        })
        .catch(error => {
          this.$acorenErrors(error.message);
          this.error = error.message
        })
    },
  },
}
</script>