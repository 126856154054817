<template>
  <div class="field">
    <label v-if="label" :for="'text-' + $.uid" class="capitalize">{{ label }}</label>
    <p v-if="help" class="mb-2 text-sm" v-html="help"></p>
    <textarea
      :id="'text-' + $.uid"
      :value="modelValue"
      :placeholder="holder"
      rows="4"
      :class="{ 'mb-0': help }"
      :disabled="disabled"
      @input="$emit('update:modelValue', $event.target.value)"
      @keydown="pressEvent($event)"
    ></textarea>
    <small class="d-block ml-auto" v-if="maxlength !== -1 || modelValue !== null">
        {{ remainingCharacters }} / {{ maxlength }}
    </small>
  </div>
</template>

<script>
export default {
  name: 'RWTextarea',
  props: {
    modelValue: {
      required: true,
    },
    label: {
      required: false,
    },
    placeholder: {
      required: false,
    },
    help: {
      required: false,
    },
    maxlength: {
      type: Number,
      required: false,
      default: -1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabSupport: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    remainingCharacters() {
      if (this.maxlength !== -1) {
        if (this.modelValue == null) {
          return this.maxlength;
        }
        return this.maxlength - this.modelValue.length
      }
    },
    holder() {
      return this.placeholder || 'Enter ' + this.label + '...'
    },
  },
  methods: {
    // This will stop tabs from going on if the tabSupport is true.
    // Thanks Stack: https://stackoverflow.com/questions/68068940/tab-inside-textarea-element-in-typescript-angular
    // Everyone had a dang jQuery version. -_- Get with the times people!
    pressEvent($event) {
      if (this.tabSupport && $event.key == 'Tab') {
        $event.preventDefault();
        const start = $event.target.selectionStart;
        const end = $event.target.selectionEnd;
        $event.target.value = $event.target.value.substring(0, start) + '\t' + $event.target.value.substring(end);
        $event.target.selectionStart = $event.target.selectionEnd = start + 1;
      }
    }
  }
}
</script>