<template>
  <div v-if="context == 'preview'">
    <div v-if="block.variant == 'logos'" class="flex justify-around items-center">
      <div class="w-1/12" v-for="child in displayedVendors" :key="child._id">
        <img v-if="child.logo" :src="child.logo.src" />
      </div>
    </div>

    <div v-if="block.variant == 'goMobile'" class>
      <div class="my-8" v-for="child in displayedVendors" :key="child._id">
        <h4>{{ child.title }}</h4>
        <p>App Store logo | Google Store logo</p>
      </div>
    </div>
  </div>

  <div v-else>
    <rw-select class="mb-4" v-model="block.variant" :options="options" label="Variant" />
    <draggable
      class="-mx-2"
      :list="block.children"
      :group="{ name: 'logos', put: ['logos'] }"
      handle=".item-handle"
      item-key="_id"
    >
      <template #item="{ element, index }">
        <div class="item-row items-center flex mb-2 pb-2 border-b-2">
          <fa-icon class="item-handle mr-4" icon="grip-vertical">Move</fa-icon>
          <div class="mr-2">
            <select v-model="element.id" @change="setChild($event, index)">
              <option v-for="vendor in vendors" :key="vendor.id" :value="vendor.id">{{vendor.title}}</option>
            </select>
          </div>
          <button class="ml-auto" @click.stop="removeChild(index)">
            <fa-icon icon="minus-circle">Trash</fa-icon>
          </button>
        </div>
      </template>
      <template #footer>
        <button class="btn btn-icon" @click.stop="addVendor">
          <fa-icon icon="plus" />Add Vendor
        </button>
      </template>
    </draggable>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import { VENDOR_LIST } from '@/graphql/vendors'
import { variants } from '@/settings/blocks'

export default {
  name: 'Vendors',
  mixins: [BlockMixin],
  data() {
    return {
      vendors: {},
      options: variants.vendors,
    }
  },
  computed: {
    displayedVendors() {
      let scope = this
      return this.block.children.map(item => {
        if (!item.id || scope.$apollo.loading) {
          return {}
        }
        let vendor = scope.vendors.find(x => x.id === item.id)
        return {
          _id: item._id,
          id: vendor.id,
          title: vendor.title,
          logo: vendor.large_logo,
        }
      })
    },
  },
  apollo: {
    vendors: {
      query: VENDOR_LIST,
    },
  },
  methods: {
    addVendor() {
      const newVendor = {}
      this.block.children.push(newVendor)
    },
    setChild($event, idx) {
      let vendor = this.vendors.filter(item => {
        return $event.target.value == item.id
      })[0]
      let clonedItem = omit(vendor, 'large_logo', 'small_logo')
      this.block.children[idx] = cloneDeep(clonedItem)
    },
  },
}
</script>