import gql from 'graphql-tag'

let MembershipDetails = gql`
  fragment MembershipDetails on Membership {
    id
    title
    acronym
    slug
    url
    logo
  }
`

export const MEMBERSHIP_DETAILS_FRAGMENT = MembershipDetails;

export const MEMBERSHIP_LIST = gql`
  query Memberships(
    $first: Int!,
    $page: Int,
    $title: String,
    $acronym: String,
    $orderBy: [OrderByClause!]) {
    memberships(
      first: $first,
      page: $page,
      title: $title,
      acronym: $acronym
      orderBy: $orderBy) {
      data {
        ...MembershipDetails
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
  ${MembershipDetails}
`

export const MEMBERSHIP = gql`
  query Membership($id: ID!) {
    membership(id: $id) {
      ...MembershipDetails
    }
  }
  ${MembershipDetails}
`


export const UPSERT_MEMBERSHIP = gql`
  mutation UpsertMembership($input: MembershipUpsert!) {
    upsertMembership(input: $input) {
      ...MembershipDetails
    }
  }
  ${MembershipDetails}
`

export const DELETE_MEMBERSHIP = gql`
  mutation DeleteMembership($id: ID!) {
    deleteMembership(id: $id) {
      id
    }
  }
`