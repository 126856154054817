const SiteRoutes = [
  {
    path: '/sites/:siteID/',
    name: '',
    props: true,
    component: () => import(/* webpackChunkName: "siteWrapper" */ '@/views/sites/SiteWrapper.vue'),
    children: [
      {
        path: '',
        name: 'SiteDashboard',
        props: true,
        component: () => import(/* webpackChunkName: "siteDashboard" */ '@/views/sites/SiteDashboard.vue'),
      },
      {
        path: 'actions',
        name: 'SiteActions',
        props: true,
        component: () => import(/* webpackChunkName: "siteActions" */ '@/views/sites/SiteActions.vue'),
      },
      {
        path: 'blog',
        name: 'SiteBlog',
        props: true,
        component: () => import(/* webpackChunkName: "siteBlog" */ '@/views/sites/SiteBlog.vue'),
      },
      // Contact Package page.
      {
        path: 'content-settings',
        name: 'SiteContentSettings',
        props: true,
        component: () => import(/* webpackChunkName: "siteSettings" */ '@/views/sites/SiteContentSettings.vue'),
      },
      {
        path: 'content-set/',
        props: true,
        children: [
          {
            path: ':contentSetID',
            props: true,
            children: [
              {
                path: '',
                name: 'SiteContentSet',
                props: true,
                component: () => import(/* webpackChunkName: "ContentSet" */ '@/views/content-sets/ContentSetEdit.vue'),
              },
              {
                path: ':itemID',
                name: 'SiteContentSetItem',
                props: true,
                component: () => import(/* webpackChunkName: "ContentSet" */ '@/views/content-sets/ContentSetItem.vue'),
              },
            ],
          }
        ]
      },
      // Just in case any sites have a location still. 
      {
        path: 'location-set/',
        props: true,
        redirect: to => {
          return { name: 'SiteDashboard' };
        }
      },
      {
        path: 'people-set/',
        props: true,
        children: [
          {
            path: ':contentSetID',
            props: true,
            children: [
              {
                path: '',
                name: 'SitePeopleSet',
                props: true,
                component: () => import(/* webpackChunkName: "PeopleSet" */ '@/views/content-sets/PeopleSetEdit.vue'),
              },
              {
                path: ':itemID',
                name: 'SitePeopleSetItem',
                props: true,
                component: () => import(/* webpackChunkName: "PeopleSet" */ '@/views/content-sets/PeopleSetItem.vue'),
              },
            ],
          },
        ]
      },
      {
        path: 'elements',
        children: [
          {
            path: '',
            name: 'SiteElements',
            props: true,
            component: () => import(/* webpackChunkName: "siteElements" */ '@/views/sites/SiteElements.vue'),
          },
          {
            path: ':elementID',
            name: 'SiteElementEdit',
            props: true,
            component: () => import(/* webpackChunkName: "elementEdit" */ '@/views/admin/ElementEdit.vue'),
          },
        ],
      },
      {
        // SiteEntryEdit is in entry-routes
        path: 'entries',
        name: 'SiteEntries',
        props: true,
        component: () => import(/* webpackChunkName: "siteEntries" */ '@/views/sites/SiteEntries.vue'),
      },
      {
        path: 'globals',
        name: 'SiteGlobals',
        props: true,
        component: () => import(/* webpackChunkName: "siteGlobals" */ '@/views/sites/SiteGlobals.vue'),
      },
      {
        path: 'pages',
        children: [
          {
            path: '',
            name: 'SitePages',
            props: true,
            component: () => import(/* webpackChunkName: "sitePages" */ '@/views/sites/SitePages.vue'),
          },
          {
            path: ':pageID',
            name: 'SiteEditPage',
            props: true,
            meta: {
              breadcrumbs: [{ name: 'Pages', link: 'SitePages' }],
            },
            component: () => import(/* webpackChunkName: "siteEditPage" */ '@/views/sites/EditPage.vue'),
          },
        ],
      },
      {
        path: 'server',
        name: 'SiteServer',
        props: true,
        component: () => import(/* webpackChunkName: "siteServer" */ '@/views/sites/SiteServer.vue'),
      },
      {
        path: 'serp',
        name: 'SiteSerp',
        props: true,
        component: () => import(/* webpackChunkName: "siteSeo" */ '@/views/sites/SiteSerp.vue'),
      },
      {
        path: 'integrations',
        name: 'SiteIntegrations',
        props: true,
        component: () => import(/* webpackChunkName: "siteIntegrations" */ '@/views/sites/SiteIntegrations.vue'),
      },
      {
        path: 'redirects',
        name: 'SiteRedirects',
        props: true,
        component: () => import(/* webpackChunkName: "siteRedirects" */ '@/views/sites/SiteRedirects.vue'),
      },
    ],
  },
]

export default SiteRoutes
