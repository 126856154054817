<template>
  <div>
    <div class="flex items-end" v-if="!standalone">
      <rw-text class="flex-1" v-model="office.title" label="Office Name" placeholder="Enter the office name..." />
      <div class="field checkbox ml-2 mb-1 text-sm">
        <label :id="'checkbox-' + $.uid">
          <input type="checkbox" :disabled="!editable" :id="'checkbox-' + $.uid" class="mr-2" v-model="office.is_primary" />
          Primary?
        </label>
        <label>
          <input type="checkbox" :disabled="!editable" class="mr-2" v-model="office.hide_name" />
          Hide Name
        </label>
      </div>
    </div>

    <div class="tab-controls my-4">
      <button :class="{ active: currentTab == 'address' }" @click="currentTab = 'address'">Address</button>
      <button :class="{ active: currentTab == 'contact' }" @click="currentTab = 'contact'">Contact</button>
      <button :class="{ active: currentTab == 'hours' }" @click="currentTab = 'hours'">Hours</button>
      <button
        class="ml-auto"
        :class="{ active: currentTab == 'display' }"
        @click="currentTab = 'display'"
        v-if="!standalone"
      >
        Display
      </button>
    </div>

    <div v-if="currentTab == 'address'" class="address space-y-2">
      <div class="space-x-4" v-if="!standalone">
        <label>
          <input
            type="checkbox"
            class="mr-2"
            v-model="office.show_map"
            :true-value="true"
            :false-value="false"
            :disabled="!editable"
          />
          Show map on website
        </label>
        <label v-if="!office.show_map">
          <input
            type="checkbox"
            class="mr-2"
            v-model="office.show_directions_link"
            :true-value="true"
            :false-value="false"
            :disabled="!editable"
          />
          Show "Directions" link?
        </label>
      </div>
      <rw-text
        v-model="office.business_name"
        label="Business name"
        placeholder="Enter the street..."
        help="This adds a label to Google Maps and can help with finding a location."
      />
      <rw-text v-model="office.address" label="Street 1" placeholder="Enter the street..." />
      <rw-text v-model="office.address2" label="Address Note (displayed on contact page but not part of the directions)" placeholder="Enter the street..." />

      <div class="flex items-end">
        <rw-text class="mr-2" v-model="office.city" label="City" placeholder="Enter the city..." />
        <rw-text class="mr-2" v-model="office.state" label="State" placeholder="Enter the state..." />
        <rw-text v-model="office.zip" label="Zip" placeholder="Enter the zip..." />

        <button class="ml-auto btn btn-primary" @click="showModal = true">Check Map</button>
      </div>
      <rw-text v-model="office.google_business" label="Google My Business Link" placeholder="Link to Google My Business..." />
    </div>

    <div v-if="currentTab == 'phone'" class="phone-email space-y-2">
      <rw-text v-model="office.phone" label="Phone" placeholder="Enter the phone number..." />
      <rw-text v-model="office.fax" label="Fax" placeholder="Enter the fax..." />
      <rw-text v-model="office.email" label="Email" placeholder="Enter the email..." />
    </div>

    <div v-if="currentTab == 'contact'" class="space-y-2">
      <draggable 
        :list="office.contact" 
        :group="{ name: 'contactMethods' }" 
        item-key="_id"
        handle=".item-handle">
        <template #item="{ element, index }">
          <div class="flex items-center mb-4 pb-4 border-b">
            <fa-icon v-if="editable" class="item-handle mr-4 mt-1" icon="grip-vertical">Move</fa-icon>
            <div class="flex items-center space-x-2">
              <rw-select
                v-model="element.type"
                :disabled="!editable"
                :options="{ phone: 'Phone', email: 'Email', fax: 'Fax', text: 'Text' }"
                label="Type"
              />
              <rw-text v-model="element.value" label="Value" />
            </div>
            <button v-if="editable" class="ml-2" @click.stop="office.contact.splice(index, 1)">
              <fa-icon icon="minus-circle">Trash</fa-icon>
            </button>
          </div>
        </template>
      </draggable>
      <button class="btn" v-if="editable" @click="addContactMethod">Add Contact Method</button>
    </div>

    <div v-if="currentTab == 'hours'" class="hours">
      <p>Leave the "opens" field blank to indicate the office is closed.</p>
      <draggable :list="office.hours" item-key="hour_id" :group="{ name: 'hours' }" handle=".hours-handle">
        <template #item="{ element, index }">
          <div class="item-row flex mb-1 pb-3 border-b items-end">
            <fa-icon class="hours-handle mr-4 mb-2" v-if="editable" icon="grip-vertical">Move</fa-icon>
            <div class="mr-4 field">
              <label class="text-sm">Day</label>
              <select v-model="element.day" :disabled="!editable" class="mr-4">
                <option v-for="(name, idx) in days" :value="name" :key="idx">{{ name }}</option>
              </select>
            </div>

            <div class="mr-4 field">
              <label class="text-sm">Opens:</label>
              <input type="text" :disabled="!editable" class="w-full" v-model="element.opens" />
            </div>

            <div class="mr-4 field">
              <label class="text-sm">Closes:</label>
              <input type="text" :disabled="!editable" class="w-full" v-model="element.closes" @keyup.enter="addHours" />
            </div>

            <div class="mr-4 mb-2 whitespace-no-wrap">
              <label>
                <input type="checkbox" v-model="element.by_appointment" :disabled="!editable" @keyup.enter="addHours" />&nbsp;By Appointment
              </label>
            </div>

            <button v-if="editable" class="ml-auto mb-2" @click.stop="office.hours.splice(index, 1)">
              <fa-icon icon="minus-circle">Trash</fa-icon>
            </button>
          </div>
        </template>

        <template #footer>
          <div class="flex items-center justify-between mt-4">
            <button class="btn btn-icon" v-if="editable" @click="addHours"><fa-icon icon="plus" />Add Hours</button>
            <button class="btn" v-if="editable" @click="fillHours">Fill with default</button>
          </div>
        </template>
      </draggable>
    </div>

    <div v-if="currentTab == 'display'" class="display">
      <div class="ml-4 mb-2">
        <label id="display-footer">
          <input type="checkbox" id="display-footer" class="mr-2" :disabled="!editable" v-model="office.display" value="footer" /> Footer
        </label>
      </div>

      <div class="ml-4 mb-2">
        <label id="display-block">
          <input type="checkbox" id="display-block" class="mr-2" :disabled="!editable" v-model="office.display" value="block" /> Locations
          block
        </label>
      </div>
    </div>

    <modal-dialog :show="showModal" @close="showModal = false">
      <h3 slot="header">Google Map</h3>
      <div class="responsive-map">
        <iframe
          width="600"
          height="300"
          id="gmap_canvas"
          :src="mapQuery"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
// https://developers.google.com/search/docs/data-types/local-business
import makeUnique from '@/components/mixins/makeUnique'

export default {
  name: 'Office',
  mixins: [makeUnique],
  props: { 
    modelValue: Object, 
    standalone: { 
      type: Boolean, 
      default: false 
    }, 
    editable: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      currentTab: 'address',
      days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      showModal: false,
    }
  },
  computed: {
    office() {
      return this.modelValue
    },
    mapQuery() {
      let address = [
        this.office.business_name,
        this.office.address,
        this.office.city,
        this.office.state,
        this.office.zip,
      ].join(',')
      // $address = 'Root Advisors Bloomington, IN';
      let params = {
        q: address,
        z: '13',
        ie: 'UTF8',
        output: 'embed',
      }
      let query = Object.keys(params)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&')
      return 'https://maps.google.com/maps?' + query
    },
  },
  watch: {
    office: {
      deep: true,
      handler(newVal, oldVal) {
        this.makeUnique(newVal.hours, 'hour_id', false)
        this.makeUnique(newVal.contact)
        this.$emit('update:modelValue', newVal)
      },
    },
  },
  methods: {
    addContactMethod() {
      let contactMethod = { type: null, value: null }
      this.office.contact.push(Object.assign({}, contactMethod))
    },
    fillHours() {
      let hours = [
        { day: 'Monday', opens: '08:30', closes: '17:00' },
        { day: 'Tuesday', opens: '08:30', closes: '17:00' },
        { day: 'Wednesday', opens: '08:30', closes: '17:00' },
        { day: 'Thursday', opens: '08:30', closes: '17:00' },
        { day: 'Friday', opens: '08:30', closes: '17:00' },
        { day: 'Saturday', opens: '', closes: '' },
        { day: 'Sunday', opens: '', closes: '' },
      ]
      this.office.hours = hours
    },
    addHours() {
      let day = { day: '', opens: '08:30', closes: '17:00' }
      this.office.hours.push(Object.assign({}, day))
    },
  },
}
</script>

<style>
.responsive-map {
  position: relative;
  padding-bottom: 66.6666%;
  height: 0;
  overflow: hidden;
}
.responsive-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
</style>