<template>
  <div v-if="context == 'preview'">
    <h2 class="text-center">This block is deprecated. Use the embed block instead.</h2>
  </div>

  <div v-else>
    <p>This block is deprecated. Use the embed block instead.</p>
  </div>
</template>

<script>
export default {
  name: 'MagazinePromo',
  mixins: [BlockMixin],
}
</script>

<style>
</style>