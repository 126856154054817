<template>
  <div v-if="context == 'preview' ">
    <rw-content-embed v-model="block" context="preview" />
  </div>

  <div v-else class="section-title mb-4">
    <rw-select
      class="mb-4"
      :options="availabelBgStyles"
      v-model="block.bgStyle"
      :includeNull="true"
      label="Background Style"
    />
    <rw-select
      v-if="block.bgStyle == 'bg-primary'"
      class="mb-4"
      :options="availableBgPositions"
      v-model="block.bgPosition"
      :includeNull="true"
      label="Background Position"
    />
    <rw-select
      class="mb-4"
      :options="titleColors"
      v-model="block.titleColor"
      :includeNull="true"
      label="Title Color"
    />
    <rw-content-embed v-model="block" context="settings" />
  </div>
</template>

<script>
import { titleColors } from '@/settings/enums'

export default {
  name: 'Embed',
  mixins: [BlockMixin],
  data() {
    return {
      block: this.modelValue,
      showModal: false,
      titleColors: titleColors,
    }
  },
  computed: {
    availabelBgStyles() {
      return {
        'bg-subtle': 'Background - Subtle',
        'bg-primary': 'Background - Brand Color',
      }
    },
    availableBgPositions() {
      if (this.block.bgStyle == 'bg-primary') {
        return {
          'bg-offset': 'Background Offset - Right',
          'bg-offset-left': 'Background Offset - Left',
        }
      }
    },
  },
}
</script>