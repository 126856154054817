import Draggable from 'vuedraggable';
import { reactive } from 'vue';

export function setupBuilder(app) {
  app.component('draggable', Draggable)
  app.component('draggable-item', require('../components/builder/DraggableItem').default)

  app.component('block-saver', require('../components/builder/BlockSaver').default)
  app.component('block-settings', require('../components/builder/BlockSettings').default)
  app.component('page-builder', require('../components/builder/PageBuilder').default)
  app.component('page-block-picker', require('../components/builder/PageBlockPicker').default)
  app.component('content-builder', require('../components/builder/ContentBuilder').default)
  app.component('content-builder-item', require('../components/builder/ContentBuilderItem').default)
  app.component('content-builder-preview', require('../components/builder/ContentBuilderPreview').default)
  app.component('content-block-picker', require('../components/builder/ContentBlockPicker').default)

  app.config.globalProperties.$pageBuilder = reactive({
    // allows for global reactivivity
    bus: {
      data: {
        activeTab: 'settings',
      },
    },

    offset(el) {
      let rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop
      return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    },

    showTab(tab) {
      this.removeActiveClasses()
      this.state.activeTab = tab
    },

    removeActiveClasses() {
      let items = document.querySelectorAll('.draggable-item')
      items.forEach(item => {
        item.classList.remove('active')
      })
    },

    get state() {
      return this.bus.data
    },
  });
};