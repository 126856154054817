<template>
  <div class="space-y-4">
    <div class="flex space-x-3">
      <div class="w-1/2">
        <rw-image v-model="person.image" context="tinyPreview" class="mb-3" />
        <br/>
        <p class="font-bold">Link Details</p>
        <light-switch 
          :disabled="!editable" 
          v-model="person.include_link" 
          class="my-4">
          <template #label>Link person?</template>
        </light-switch>
        <rw-link
          v-show="person.include_link"
          v-model="person.link"
          label="Link to person?"
          context="settings"
          :types="['entry']"
          :showTextInput="false"
          :showClassInput="false"
        />
      </div>

      <div class="w-1/2 space-y-2">
        <p>ID: <code>{{ person.id }}</code></p>
        <rw-text v-model="person.first_name" label="First name" />
        <rw-text v-model="person.last_name" label="Last name" />
        <rw-text v-model="person.job_title" label="Job Title" />
        <rw-text v-model="person.suffix" label="Credentials (CPA, CFA, CMA)" />
      </div>
    </div>

    <!-- <rw-textarea
      v-model="person.short_bio"
      label="Short bio (you can use markdown)"
      placeholder="Short bio"
    />-->
  </div>
</template>

<script>
export default {
  name: 'Person',
  props: ['modelValue', 'editable'],
  computed: {
    person() {
      return this.modelValue
    },
  },
}
</script>

<style>
</style>