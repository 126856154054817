<template>
  <portal to="modal" v-if="show">
    <div class="modal">
      <div class="bg" @click="dismiss"></div>
      <div class="flex h-full w-full items-center justify-center">
        <div class="modal--container flex flex-col fade-in" :class="`width-${size} ${height}`">
          <button class="modal-close" @click="dismiss">
            <fa-icon icon="times-circle">Close</fa-icon>
          </button>
          <div class="modal--header" v-if="hasSlot('header')">
            <slot name="header"></slot>
          </div>
          <div class="modal--content flex-1">
            <slot></slot>
          </div>
          <div class="modal--footer" v-if="hasSlot('footer')">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import EventBus from '@/bootstrap/EventBus'

export default {
  name: 'ModalDialog',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
    },
    height: {
      type: String,
      default: 'h-auto',
    },
  },
  methods: {
    dismiss() {
      this.$emit('close')
    },
    hasSlot(name = 'default') {
      return !!this.$slots[name]
    },
  },
  watch: {
    show: {
      immediate: true,
      handler: show => {
        if (show) {
          document.body.style.setProperty('overflow', 'hidden')
        } else {
          document.body.style.removeProperty('overflow')
        }
      },
    },
  },
  created() {
    const escapeHandler = e => {
      if (e.key === 'Escape' && this.show) {
        this.dismiss()
      }
    }

    document.addEventListener('keydown', escapeHandler)
    EventBus.off('hook:unmounted', () => {
      document.removeEventListener('keydown', escapeHandler)
    })
  },
}
</script>


<style lang="scss">
.fade-in {
  opacity: 1;
  transform: translateY(0);
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.13s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal {
  @apply h-screen w-screen top-0 fixed;
  display: block;
  z-index: 10000;

  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .bg {
    @apply absolute w-full h-full;
    background-color: rgba(0, 0, 0, 0.15);
  }

  &--container {
    @apply bg-white rounded relative border-gray-400 shadow-2xl flex flex-col;
    max-height: 90vh;
    z-index: 21;
  }

  &--container.width-large {
    width: 90vw;
    max-width: 1280px;
  }

  &--container.width-medium {
    width: 50vw;
    min-width: 768px;
    max-width: 1024px;
  }

  &--container.width-small {
    width: 35vw;
    // max-width: 768px;
    min-width: 512px;
  }

  &--header {
    @apply px-4 py-2 border-b;

    h1,
    h2,
    h3,
    h4 {
      margin: 0;
    }
  }

  &--content {
    @apply p-4 overflow-y-auto;
  }

  &--footer {
    @apply p-4 border-t;
  }
}
</style>