let uniqid = require('uniqid')

export default {
  methods: {
    blockDefaults(data) {
      let recursiveDefaults = function (data) {
        if (!Array.isArray(data)) {
          data = [data]
      }

        data.forEach(function (item) {
          item._id = item._id ? item._id : uniqid()

          // we don't need to store blockIcons or blockDescriptions in content
          delete item.blockIcon
          delete item.faIcon
          delete item.blockDescription

          // run this recursively
          if (item.children) recursiveDefaults(item.children)
        })
        return data
      }
      return recursiveDefaults(data)
    },
  },
}
