import gql from 'graphql-tag'

let VendorDetails = gql`
  fragment VendorDetails on Vendor {
    id
    title
    slug
    small_logo
    large_logo
    login_url
    app_store_url
    google_play_url
  }
`

export const VENDOR_DETAILS_FRAGMENT = VendorDetails

export const VENDOR_LIST = gql`
  query Vendors($orderBy: [OrderByClause!]) {
    vendors(orderBy: $orderBy) {
      ...VendorDetails
    }
  }
  ${VendorDetails}
`

export const VENDOR = gql`
  query Vendor($id: ID!) {
    vendor(id: $id) {
      ...VendorDetails
    }
  }
  ${VendorDetails}
`

export const CREATE_VENDOR = gql`
  mutation CreateVendor($input: VendorInput!) {
    createVendor(input: $input) {
      id
      title
    }
  }
`

export const UPDATE_VENDOR = gql`
  mutation UpdateVendor($input: VendorUpdateInput!) {
    updateVendor(input: $input) {
      ...VendorDetails
    }
  }
  ${VendorDetails}
`

export const DELETE_VENDOR = gql`
  mutation DeleteVendor($id: ID!) {
    deleteVendor(id: $id) {
      id
    }
  }
`
