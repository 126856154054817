<template>
  <div class="field select-field" :class="{ 'field-inline': layout == 'inline' }">
    <label :for="'select-' + $.uid">{{ label }}</label>
    <select name :id="'select-' + $.uid" v-model="localValue">
      <option :value="null">Choose a page</option>
      <option
        v-for="page in pages"
        :key="page.id"
        :value="page.id"
        :disabled="page.id == $route.params.pageID"
        v-html="optionLabel(page)"
      ></option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'PagePicker',
  props: ['modelValue', 'label', 'layout', 'siteID'],
  data() {
    return {
      pages: [],
    }
  },
  async mounted() {
    await this.fetchPages()
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        return this.$emit('update:modelValue', newValue)
      },
    },
  },
  methods: {
    async fetchPages() {
      this.state = 'loading'
      this.$api.post('site-pages', { action: 'picker', site_id: this.siteID }).then(({ data }) => {
        this.pages = data.items
      })
    },
    optionLabel(item) {
      return `${'-'.repeat(item.depth)} ${item.title}`
    },
  },
}
</script>