<template>
  <div v-if="context == 'preview'">
    <div v-if="hasTitles" class="mb-12">
      <h3 v-show="block.title" v-html="block.title"></h3>
      <p v-show="block.text" v-html="block.text"></p>
    </div>
    
    <p v-if="!block.children.length" class="m-0">Drag a block here to get started</p>
    <draggable
      :class="classes.container"
      :list="block.children"
      :group="{ name: 'blocks', put: ['contentBlocks', 'blocks'] }"
      handle=".block-handle"
      item-key="_id"
    >
      <template #item="{ element, index }">
        <draggable-item
          :class="`${classes.item.join(' ')} ${element.blockWidth ? element.blockWidth : ''}`"
          handleClass="block-handle"
          v-model="block.children[index]"
          :ref="'childBlock:' + index"
          context="preview"
          @update:value="updateBlock($event, index)"
          @insert-preset="insertPreset($event, index)"
          @remove-child="removeChild(index)"
        />
      </template>
    </draggable>
  </div>

  <div v-else class="space-y-2">
    <rw-text v-model="block.title" placeholder="Enter the title" label="Title" />
    <rw-textarea v-model="block.text" placeholder="Enter the text" label="Text" />

    <div class="field field-inline">
      <label>Desktop Width</label>
      <select v-model="block.desktopWidth">
        <option :value="null">Select a width</option>
        <option value="full">Full</option>
      </select>
      <!-- <p class="help w-full">This is only needed when section titles are offset</p> -->
    </div>

    <div class="field field-inline">
      <label>Layout</label>
      <select v-model="block.blockLayout" @change="resetBlock">
        <option :value="null">Default</option>
        <option v-for="(name, value) in layouts" :value="value" :key="value">{{name}}</option>
      </select>
    </div>

    <div class="field field-inline" v-if="showStyles">
      <label>Style</label>
      <select v-model="block.blockStyle">
        <option :value="null">None</option>
        <option v-for="(name, value) in styles" :value="value" :key="value">{{name}}</option>
      </select>
    </div>

    <div class="field field-inline" v-if="block.blockLayout == 'grid'">
      <label>Child Item Widths</label>
      <select v-model="block.childDesktopWidth">
        <option :value="null">Select a width</option>
        <option value="quarters">Quarters</option>
        <option value="halves">Halves</option>
        <option value="thirds">Thirds</option>
        <option value="fifths">Fifths</option>
      </select>
    </div>

    <div
      class="field field-inline"
      v-if="block.blockLayout == 'columns' || block.blockLayout == 'columnsCustom'"
    >
      <label>V Alignment</label>
      <select v-model="block.alignment">
        <option :value="null">Default</option>
        <option value="items-start">Items Top</option>
        <option value="items-center">Items Center</option>
        <option value="items-stretch">Equal Heights</option>
      </select>
    </div>

    <div v-if="block.blockLayout == 'columnsCustom'" class="mt-5">
      <button @click.stop="addColumn" class="btn btn-icon">
        <fa-icon icon="plus" />Add Column
      </button>
    </div>

    <div v-if="block.blockLayout == 'gallery'" class="mt-5">
      <button @click.stop="addGalleryItem" class="btn btn-icon">
        <fa-icon icon="plus" />Add Gallery Item
      </button>
    </div>
  </div>
</template>

<script>
import { blockLayouts, blockStyles, galleryItem } from '@/settings/blocks'

export default {
  name: 'Container',
  mixins: [BlockMixin, InsertPreset],
  inject: ['getSection'],
  data() {
    return {
      layouts: blockLayouts.container,
      styles: blockStyles.container,
    }
  },
  computed: {
    sectionLayout() {
      const section = this.getSection;

      return section.blockLayout
    },
    hasTitles() {
      return this.block.preTitle || this.block.title || this.block.subTitle
    },
    showStyles() {
      return this.block.blockLayout == 'grid' || this.block.blockLayout == 'columns'
    },
    groupObject() {
      if (this.block.blockLayout == 'columns') {
        return { name: 'columns', put: ['column'] }
      }
      return { name: 'blocks', put: ['contentBlocks', 'blocks'] }
    },
    classes() {
      let classes = { container: ['-mx-2'], item: ['px-3'] }

      classes.container.push(this.block.alignment)

      if (this.block.blockLayout == 'grid') {
        classes.container.push('flex')
        classes.container.push('flex-wrap')
        let itemWidth =
          this.block.childDesktopWidth == 'quarters'
            ? 'md:w-1/4'
            : this.block.childDesktopWidth == 'halves'
            ? 'md:w-1/2'
            : this.block.childDesktopWidth == 'thirds'
            ? 'md:w-1/3'
            : this.block.childDesktopWidth == 'fifths'
            ? 'md:w-1/5'
            : 'md:w-full'
        classes.item.push(itemWidth)
        classes.item.push('flex-none')
      }

      if (this.block.blockLayout == 'columns') {
        classes.container.push('flex')
        classes.item.push('flex-1')
      }

      if (this.block.blockLayout == 'columnsCustom') {
        classes.container.push('flex flex-wrap')
        classes.item.push('flex-none')
      }

      !this.block.children.length ? classes.container.push('py-8 px-3 border border-gray-600 border-dashed') : null

      return classes
    },
  },
  methods: {
    updateBlock($event, idx) {
      this.block['children'][idx] = $event;
      //this.$set(this.block.children, idx, $event)
    },
    // sometimes things like style and alignment can bleed over between layouts
    resetBlock() {
      this.block.blockStyle = null
      this.block.alignment = null
      this.block.childDesktopWidth = null
    },
    addColumn() {
      let column = Object.assign(
        {},
        {
          blockType: 'column',
          blockName: 'Column',
          blockLabel: null,
          blockWidth: 'w-1/3',
          children: [],
        }
      )
      this.block.children.push(column)
    },
    addGalleryItem() {
      let newGalleryItem = Object.assign({}, galleryItem)
      this.block.children.push(newGalleryItem)
    },
  },
}
</script>