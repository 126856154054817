import Toast, { TYPE, useToast } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

// For all options: https://github.com/Maronato/vue-toastification
export function setupToast(app) {

  const acorenErrors = (errors) => {
    let { graphQLErrors } = errors
    let finalError = null;

    if (
      graphQLErrors &&
      graphQLErrors[0] &&
      graphQLErrors[0].extensions &&
      graphQLErrors[0].extensions.category === 'validation'
    ) {
      finalError = 'Graphql issue: ' + graphQLErrors[0].message
    } else {
      finalError = errors.message;
    }
    
    app.config.globalProperties.$toasted.error(finalError);
  }

  const options = {
    shareAppContext: true,
    position: 'top-center',
    //pauseOnFocusLoss: false,
    closeOnClick: false,
    toastDefaults: {
        [TYPE.ERROR]: {
            autoClose: 8000,
        },
        [TYPE.SUCCESS]: {
            timeout: 3000,
            hideProgressBar: false,
        }    
    }
  };


  app.use(Toast, options);
  const toast = useToast();
  app.config.globalProperties.$toasted = toast;  
  app.config.globalProperties.$acorenErrors = acorenErrors;
};