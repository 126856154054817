// Blocks
const importedComponents = {
  blocks: require.context('../components/blocks/', true, /\.(vue)$/i),
  sections: require.context('../components/blocks/sections/', true, /\.(vue)$/i),
  // This is purely organizational
  deprecated: require.context('../components/blocks/deprecated/', true, /\.(vue)$/i),
}

export function setupBlocks(app) {
  Object.keys(importedComponents).forEach(name => {
    let imports = importedComponents[name]
  
    imports.keys().map(block => {
      let component = imports(block).default
  
      const blockName = block
        .match(/\w+/)[0]
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\s+/g, '-')
        .toLowerCase()
  
      app.component('rw-' + blockName, component)
    })
  })
};