import BlockMixin from '../components/mixins/block'
import InsertPreset from '../components/mixins/insertPreset'
import moment from 'moment'

window.BlockMixin = BlockMixin
window.InsertPreset = InsertPreset

export function setupMixins(app) {
  app.mixin({
  methods: {
    getType(blockType) {
      let blockName = blockType
        .match(/\w+/)[0]
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/\s+/g, '-')
        .toLowerCase()
      return 'rw-' + blockName
    },
    formatDate: (value, format = 'YYYY-MM-DD') => {
      if (value) {
        return moment(String(value)).format(format)
      }
    }
  },
})
}