<template>
  <div class="popover-container" :class="{'popover-open': isOpen}" v-click-away="close">
    <div
      @click.prevent="toggle"
      class="trigger"
      ref="trigger"
      aria-haspopup="true"
      :aria-expanded="isOpen"
      v-if="$slots.default"
    >
      <slot name="trigger"></slot>
    </div>
    <div ref="popover" class="popover" v-if="!disabled">
      <div class="popover-content bg-white shadow-lg border rounded-md">
        <slot :close="close" />
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue3-click-away";
//import { mixin as clickaway } from 'vue-clickaway'
import { createPopper } from '@popperjs/core'
import EventBus from '@/bootstrap/EventBus'

export default {
  name: 'Popover',
  mixins: [clickaway],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom-start',
    },
    offset: {
      type: Array,
      default: () => [0, 10],
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  mounted() {
    if (!this.disabled) this.bindPopper()
  },
  created() {
    const escapeHandler = e => {
      if (e.key === 'Escape' && this.show) {
        this.close()
      }
    }
    document.addEventListener('keydown', escapeHandler)
    EventBus.emit('hook:unmounted', () => {
      document.removeEventListener('keydown', escapeHandler)
    })
  },
  methods: {
    bindPopper() {
      createPopper(this.$refs.trigger, this.$refs.popover, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: this.offset,
            },
          },
        ],
      })
    },
    toggle() {
      this.isOpen ? this.close() : this.open()
    },
    open() {
      this.isOpen = true
      // this.$keys.bind("esc", e => this.close());
    },
    close() {
      this.isOpen = false
    },
  },
}
</script>