<template>
  <div v-if="context == 'preview' " class="relative">
    <content-picker
      contentType="elements"
      text="Prepend Elements"
      mode="multiple"
      :allowedTypes="['page']"
      @insert-items="insertElements($event, 'prepend')"
    />

    <draggable
      v-if="block.prepend && !$apollo.queries.elements.loading"
      class="my-8 space-y-4"
      :list="block.prepend"
      :group="{ name: 'prependElements' }"
      handle=".handle"
      item-key="idx"
    >
      <template #item="{ element, index }">
        <div :key="element" class="flex items-start">
          <fa-icon class="handle mr-4 mt-1" icon="grip-vertical">Move</fa-icon>
          <p class="mb-0">
            <strong>Label:</strong>
            <span v-html="elementTitle(element)"></span>
            <br />
            <strong>Slug:</strong>
            <span v-html="elementSlug(element)"></span>
          </p>
          <button @click="block.prepend.splice(index, 1)" class="ml-2 mt-0">
            <fa-icon icon="minus-circle" />
          </button>
        </div>
      </template>
    </draggable>

    <div v-if="contentSet">
      <h3>Current Items in: {{ contentSet.title }}</h3>

      <ul class="my-8 space-y-4">
        <template v-for="item in contentSet.items">
          <li v-if="item.menu_label" :key="item.id">
            <strong>Label:</strong>
            {{ item.menu_label }}
            <br />
            <strong>Slug:</strong>
            {{ item.menu_slug }}
          </li>
          <li v-else :key="item.id">
            <strong>HEADS UP:</strong>
            <!-- You need to set a menu label for {{ item.element.content.title }} -->
            <strong>Label:</strong>
            {{ item.element.menu_label }}
            <br />
            <strong>Slug:</strong>
            {{ item.element.menu_slug }}
          </li>
        </template>
      </ul>
    </div>
    <h3 v-else>Content set will display here</h3>

    <draggable
      v-if="block.append && !$apollo.queries.elements.loading"
      class="my-8 space-y-4"
      :list="block.append"
      :group="{ name: 'appendElements' }"
      handle=".handle"
      item-key="idx"
    >
      <template #item="{ element, index }">
        <div :key="element" class="flex items-start">
          <fa-icon class="handle mr-4 mt-1" icon="grip-vertical">Move</fa-icon>
          <p class="mb-0">
            <strong>Label:</strong>
            <span v-html="elementTitle(element)"></span>
            <br />
            <strong>Slug:</strong>
            <span v-html="elementSlug(element)"></span>
          </p>
          <button @click="block.append.splice(index, 1)" class="ml-2 mt-0">
            <fa-icon icon="minus-circle" />
          </button>
        </div>
      </template>
    </draggable>

    <content-picker
      contentType="elements"
      text="Append Elements"
      mode="multiple"
      :allowedTypes="['page']"
      @insert-items="insertElements($event, 'append')"
    />
  </div>

  <div v-else>
    <content-set-picker v-model="block.contentSetId" class="mb-4" :allowedTypes="['page']" />
  </div>
</template>

<script>
import { CONTENT_SET } from '@/graphql/content-sets'
import { ALL_ELEMENTS } from '@/graphql/elements'

export default {
  name: 'ContentSet',
  mixins: [BlockMixin],
  data() {
    return {
      contentSet: {},
      elements: [],
    }
  },
  watch: {
    'block.contentSetId': {
      handler(val) {
        if (!val) {
          this.contentSet = {}
        }
      },
    },
  },
  apollo: {
    contentSet: {
      fetchPolicy: 'no-cache',
      query: CONTENT_SET,
      variables() {
        return {
          id: this.block.contentSetId,
        }
      },
      skip() {
        return !this.block.contentSetId
      },
    },
    elements: {
      query: ALL_ELEMENTS,
      variables() {
        return {
          ids: this.block.prepend.concat(this.block.append),
        }
      },
    },
  },
  methods: {
    elementTitle(elementId) {
      return this.elements.find(item => {
        return item.id === elementId
      }).menu_label
    },
    elementSlug(elementId) {
      return this.elements.find(item => {
        return item.id === elementId
      }).menu_slug
    },
    // updateOrder(position) {
    //   let scope = this
    //   this[`${position}Elements`].forEach((element, idx) => {
    //     scope.$set(this.block[position], idx, element.id)
    //   })
    // },
    insertElements($event, position) {
      let ids = $event.data.map(item => {
        return item.id
      })
      this.block[position].push(...ids)
    },
  },
}
</script>