<template>
  <div class="cta flex items-center" v-if="context == 'preview'">
    <cloudinary-image 
      class="w-1/3"
      :class="{ 'order-0': block.imagePosition == 'left', 'order-2': block.imagePosition == 'right' }"
      v-if="block.includeImage && block.image.cloudinaryId"
      width="256"
      crop="fill"
      :public-id="block.cloudinaryId" />

    <div class="px-3 flex-1 flex flex-col" :class="{ 'order-1': block.imagePosition == 'right' }">
      <p v-if="block.preTitle" v-html="block.preTitle"></p>
      <h2 v-if="block.title" v-html="block.title"></h2>
      <p v-if="block.subTitle" class="h3 mt-0 mb-6" v-html="block.subTitle"></p>
      <p v-if="block.text" v-html="block.text"></p>
      <rw-link v-if="block.includeLink" v-model="block.link" context="preview" />
    </div>
  </div>

  <div v-else class="space-y-4 divide-y-2 divide-gray-300">
    <div class>
      <rw-select v-model="block.blockVariant" :includeNull="true" :options="variants" label="Variant" />
      <rw-text v-model="block.preTitle" placeholder="PreTitle" label="PreTitle" />
      <rw-text v-model="block.title" placeholder="Title" label="Title" />
      <rw-text v-model="block.subTitle" placeholder="SubTitle" label="SubTitle" />
      <rw-textarea
        v-model="block.text"
        placeholder="Text"
        label="Text"
        help="Content will be wrapped in <code class='monospace'>p</code> tag."
      />
    </div>

    <div class="pt-4 space-y-2">
      <light-switch v-model="block.includeImage"><template #label>Include Image?</template></light-switch>
      <rw-select
        v-show="block.includeImage"
        v-model="block.imagePosition"
        :options="{ left: 'Image Left', right: 'Image Right' }"
        label="Image Position"
      />
      <rw-image v-show="block.includeImage" v-model="block.image" context="tinyPreview" />
    </div>

    <div class="pt-4 space-y-2">
      <light-switch v-model="block.includeLink"><template #label>Include Link?</template></light-switch>
      <rw-link v-show="block.includeLink" v-model="block.link" context="settings" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'CTA',
  mixins: [BlockMixin],
  data() {
    return {
      variants: { 'split-with-image': 'Split with Image', 'with-media': 'With Media' },
    }
  },
}
</script>