<template>
  <transition name="fade" mode="out-in">
    <div>
      <apollo-loading v-if="$apollo.loading" />
      <VTable
        v-else
        class="bare striped smart-table"
        :data="items"
        :filters="filters"
        :selectionMode="selectionMode"
        @stateChanged="selectionChanged"
      >
        <template #head>
          <th></th>
          <VTh sortKey="content.title" defaultSort="asc">Title</VTh>
          <VTh sortKey="type">Type</VTh>
          <VTh :customSort="createdSort">Created</VTh>
          <th v-if="editable || viewable || deletable"></th>
        </template>
        <template #body="{ rows }">
          <VTr
            v-for="element in rows"
            :key="element.id"
            :class="{ 'selectable' : selectable }"
            :row="element"
          >
            <td class="w-1">
              <content-preview :content="element" />
            </td>
            <td class="w-full">{{ element.content.title }}</td>
            <td>{{ element.type }}</td>
            <td>{{ formatDate(element.created_at) }}</td>
            <td
              v-if="editable || viewable || deletable"
              class="w-1 whitespace-no-wrap space-x-2"
            >
              <router-link
                v-if="viewable"
                class="btn btn-go btn-sm"
                :to="editLink(element.id)"
              >{{ editable ? 'Edit' : 'View' }}</router-link>
              <delete-item
                v-if="deletable"
                :mutation="deleteElement"
                :id="element.id"
                @item-deleted="refreshTable"
              />
            </td>
          </VTr>
        </template>
      </VTable>
    </div>
  </transition>
</template>

<script>
import ContentPreview from '@/components/modals/ContentPreview'
import { GLOBAL_ELEMENTS, SITE_ELEMENTS, DELETE_ELEMENT } from '@/graphql/elements'

export default {
  name: 'ElementsTable',
  components: { ContentPreview },
  props: {
    scope: {
      type: String,
      default: 'global',
    },
    siteID: String,
    selectable: {
      type: Boolean,
      default: false,
    },
    selectionMode: {
      type: String,
      default: 'single',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    viewable: {
      type: Boolean,
      default: true,
    },
    filters: Object,
  },
  data() {
    return {
      deleteElement: DELETE_ELEMENT,
      items: [],
      firstRun: true,
    }
  },
  apollo: {
    items: {
      query() {
        return this.scope == 'global' ? GLOBAL_ELEMENTS : SITE_ELEMENTS
      },
      variables() {
        return {
          siteID: this.siteID ? this.siteID : null,
        }
      },
      update: (data) => data.elements,
    },
  },
  methods: {
    refreshTable() {
      this.$apollo.queries.items.refetch()
    },
    editLink(id) {
      return {
        name: this.siteID ? 'SiteElementEdit' : 'ElementEdit',
        params: {
          siteID: this.siteID ? this.siteID : null,
          elementID: id,
        },
      }
    },
    selectionChanged($event) {
      if (this.firstRun) {
        this.firstRun = false
        return
      }
      
      this.$emit('selection-changed', $event.selectedRows)
    },
    createdSort(a, b, sortOrder) {
      let date1 = new Date(a.created_at).getTime()
      let date2 = new Date(b.created_at).getTime()

      return (date1 - date2) * sortOrder
    },
    publishedSort(a, b, sortOrder) {
      let date2 = new Date(b.published_at).getTime()
      let date1 = new Date(a.published_at).getTime()
      
      return (date1 - date2) * sortOrder
    },
  },
}
</script>