import gql from 'graphql-tag'

let OfficeDetails = gql`
  fragment OfficeDetails on Office {
    id
    officeable_id
    officeable_type
    title
    is_primary
    hide_name
    display
    show_map
    show_directions_link
    order
    business_name
    address
    address2
    city
    state
    zip
    contact
    phone
    email
    fax
    hours
    google_business
  }
`

export const OFFICE_DETAILS_FRAGMENT = OfficeDetails