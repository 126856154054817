<template>
  <div class="field" :class="{ 'field-inline': layout == 'inline' }">
    <label :for="`select-${$.uid}`">{{ label }}</label>
    <select 
      name :id="`select-${$.uid}`" 
      v-model="localValue"
      :disabled="disabled"
      
      >
      <option v-if="includeNull" :value="null" :selected="!modelValue">None</option>
      <option :value="value" v-for="(name, value) in options" :key="value">{{name}}</option>
    </select>
  </div>
</template>

<script>
import { reactive } from 'vue'
export default {
  name: 'RWSelect',
  props: {
    modelValue: (String | Number),
    label: String,
    options: Object,
    includeNull: Boolean,
    layout: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue === undefined ? null : this.modelValue;
      },
      set(newVal) {
        this.$emit('update:modelValue', newVal);
      }
    }
  },
}
</script>

<style>
</style>