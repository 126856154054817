<template>
  <div class="field" :class=" { 'field-inline': layout == 'inline' }">
    <label>{{ label }}</label>
    <VueDatePicker
      v-model="date"
      type="datetime"
      :disabled="disabled"
      value-type="format"
      format="yyyy-MM-dd HH:mm:ss"
      :show-time-panel="showTimePanel"
    >
      <template #footer>
        <button
          class="mx-btn mx-btn-text"
          @click="toggleTimePanel"
        >{{ showTimePanel ? 'select date' : 'select time' }}</button>
      </template>
    </VueDatePicker>
  </div>
</template>

<script>
// https://vue3datepicker.com/
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import moment from 'moment';
import { reactive } from 'vue';

export default {
  name: 'DateField',
  components: { VueDatePicker },
  props: ['modelValue', 'label', 'layout', 'disabled'],
  data() {
    return {
      showTimePanel: false,
    }
  },
  computed: {
    date: {
      get() {
        return reactive(this.modelValue);
      }, 
      set(newVal) {
        this.$emit('update:modelValue', moment(newVal).format('YYYY-MM-DD HH:mm:ss'));
      }
    }
  },
  methods: {
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel
    },
  },
}
</script>

<style lang="scss">
.mx-table {
  th,
  td {
    border: none;
    padding: 0;
  }

  th {
    text-align: center;
  }
}
</style>