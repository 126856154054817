import gql from 'graphql-tag'

let MagazineDetails = gql`
  fragment MagazineDetails on MagazineIssue {
    id
    title
    slug
    type
    is_latest_issue
    published_at
    teaser
    teaser_image
    hero
    seo
    social_content
    entries {
      id
      content {
        id
        title
        status
      }
    }
  }
`

export const MAGAZINE_ISSUE_FRAGMENT = MagazineDetails

export const MAGAZINE_ISSUE = gql`
  query Magazine($magazineID: ID!) {
    magazine(id: $magazineID) {
      ...MagazineDetails
    }
  }
  ${MagazineDetails}
`

export const MAGAZINE_ISSUE_PREVIEW = gql`
  query MagazinePreview($magazineID: ID!) {
    magazine(id: $magazineID) {
      id
      title
      slug
      type
      is_latest_issue
      published_at
      teaser
      teaser_image
      entry_teasers {
        id
        content {
          id
          title
          teaser
          status
        }
      }
    }
  }
`

export const MAGAZINE_ISSUE_LIST = gql`
  query MagazineIssues($orderBy: [OrderByClause!]) {
    magazineIssues(orderBy: $orderBy) {
      id
      title
      slug
      type
      teaser_image
      is_latest_issue
      published_at
      created_at
    }
  }
`

export const CREATE_MAGAZINE_ISSUE = gql`
  mutation CreateMagazineIssue($input: CreateMagazineInput!) {
    createMagazineIssue(input: $input) {
      id
      title
      created_at
    }
  }
`

export const UPDATE_MAGAZINE_ISSUE = gql`
  mutation UpdateMagazineIssue($input: UpdateMagazineInput!) {
    updateMagazineIssue(input: $input) {
      ...MagazineDetails
    }
  }
  ${MagazineDetails}
`

export const DELETE_MAGAZINE_ISSUE = gql`
  mutation DeleteMagazineIssue($id: ID!) {
    deleteMagazineIssue(id: $id) {
      id
    }
  }
`
