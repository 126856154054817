<template>
  <div>
    <div v-if="editable">
      <dropdown-list v-if="$route.params.siteID" class="btn btn-go inline-flex p-0 self-center">
        <template #label><button slot="label" @click.prevent="openCloudinary" class="px-2 py-1 font-bold">{{ localText }}</button></template>
        <template #trigger>
          <button class="toggle py-1 px-2 border-l border-green-500">
          <fa-icon icon="angle-down" />
        </button>
        </template>
        <button @click.prevent="openCloudinary('firm')">Open firm folder</button>
        <button @click.prevent="openCloudinary('default')">Open root</button>
      </dropdown-list>
      <button v-else @click.prevent="openCloudinary" class="btn btn-go">{{ localText }}</button>
    </div>
    
  </div>
</template>

<script>
let cloudinaryOptions = {}

export default {
  name: 'Cloudinary',
  props: ['fieldUid', 'hasImage', 'btnText'],
  data() {
    return {
      editable: true,
    };
  },
  computed: {
    localText() {
      if (!this.btnText || this.btnText == undefined) {
        return this.hasImage ? 'Change Image' : 'Choose Image'
      }
      return this.btnText
    },
  },
  mounted() {
    const perm = this.$route.name;
    this.editable = this.$can(perm, 'update');
  },
  methods: {
    openCloudinary(target = null) {
      window.ml.field = this.fieldUid
      
      let options = {}
      if (target === 'firm' && this.$route.params.siteID && this.$globals.site.key) {
        let path = `firms/${this.$globals.site.key}`
        options.folder = { path: path }
      }
      if (target === 'default') {
        options.folder = { path: '' }
      }
      window.ml.show(options)
    },
  },
}
</script>