<template>
  <div>
    <button @click.prevent="updateSite" :class="btnClass" :disabled="loading">
      <span class="flex items-center">
        {{ text }}
        <spinner class="ml-2" v-if="loading" />
      </span>
    </button>
  </div>
</template>

<script>
import EventBus from '@/bootstrap/EventBus'

export default {
  name: 'SiteUpdate',
  props: {
    site: Object,
    siteID: String,
    text: String,
    action: String,
    data: Object,
    btnClass: {
      type: String,
      default: 'link mr-2',
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  created() {
    let self = this

    Echo.private('site.' + this.siteID)
      .listenForWhisper('updating', (evt) => {
        self.loading = true
      })
      .listen('.update.success', (evt) => {
        EventBus.emit('site-updated', self.siteID)
        self.loading = false
      })
      .listen('.update.failed', (evt) => {
        this.$acorenErrors(evt.message);
        self.loading = false
      })
  },
  methods: {
    updateSite(action) {
      if (confirm('Are you sure you want to do that?')) {
        let params = {
          site_id: this.siteID,
          action: this.action,
          ...this.data,
        }

        // console.log('Params: ' + params)

        Echo.private('site.' + this.siteID).whisper('updating')
        this.loading = true

        this.$api
          .post(`/update-server/`, params)
          .then((response) => {
            console.log(response)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
  },
}
</script>