/**
 * For some stupid reason, JS doesn't have a number range return. It's been proposed but not
 * approved. Here's my stupid method to do it. 
 * 
 * @param Number start 
 * @param Number end 
 * 
 * @returns Array
 */
export function numberRange(start, end) {
    if (isNaN(start) || isNaN(end)) {
        console.error('Start or end is NaN');

        return [];
    }

    let ranger = [];
    for (let i = start; i <= end; i++) {
        ranger.push(i);
    }
    
    return ranger;
}

/**
 * I've had to copy and paste this stupid sort too many times. JS sort handles numbers as strings. 
 * This should work for dates too. Haven't tested it.
 * 
 * @param Array  data
 * 
 * @return Array
 */
export function numberSort(data) {
    return data.sort(function(a, b){return a - b});
}