<template>
    <button @click="publishMagazine" :disabled="loading">
        <span class="flex items-center">
            {{btnText}}
            <spinner v-if="loading" class="ml-2" />
        </span>
    </button>
</template>

<script>
export default {
    name: "MagazineLatest",
    props: [ 'text', 'issueID' ],
    data() {
        return {
            loading: false,
            btnText: this.text
        }
    },
    created() {
        let self = this;
        Echo.private('magazine.' + this.issueID)
        .listenForWhisper('publishing', (e) => {
            self.loading = true;
        })
        .listen('.latest.success', function(evt) {
            self.$emit('issues-updated')
            return self.loading = false
        })
        .listen('.latest.failed', function(evt) {
            return self.loading = false
        })
    },
    methods: {
        publishMagazine() {
            this.loading = true;
            Echo.private('magazine.' + this.issueID).whisper('publishing');
            this.$api.post("/magazines/set-latest-issue", {
                issue_id: this.issueID
            })
            .then(function(response) {
                console.log('Publish button clicked');
                console.log(response);
            })
            .catch(function(error) {
                console.log(error);
            });
        }
    }
};
</script>

<style scoped lang="scss">
</style>