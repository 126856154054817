<template>
  <div v-if="$can('MembershipsIndex', 'create')">
    <button :class="btnClass" @click.prevent="showModal = true">{{btnText}}</button>
    <modal-dialog :show="showModal" @close="closeModal">
      <template #header><h3>Create Membership</h3></template>

      <alert-message v-bind="alert" />

      <form id="create-item-form" @keyup.enter="createItem" class="space-y-2">
        <rw-text v-model="title" label="Name" placeholder="Enter the organization name..." />
        <rw-text v-model="acronym" label="Acronym" placeholder="Enter the organization acronym..." />
        <rw-text v-model="slug" label="Slug" placeholder="Enter the organization slug..." />
      </form>

      <template #footer>
        <div class="flex justify-end">
          <button class="btn btn-go" :disabled="isSaving" @click.prevent="createItem">Create Organization</button>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from '@/components/utils/ModalDialog'
import slugify from '@sindresorhus/slugify'
import { UPSERT_MEMBERSHIP } from '@/graphql/memberships'

export default {
  name: 'MembershipCreate',
  props: {
    btnText: {
      type: String,
      default: 'Create Membership',
    },
    btnClass: {
      type: String,
      default: 'btn btn-go btn-sm',
    },
  },
  components: { ModalDialog },
  data() {
    return {
      showModal: false,
      title: '',
      acronym: '',
      isSaving: false,
      alert: {
        type: null,
        message: null,
      },
    }
  },
  computed: {
    slug: {
      get() {
        return slugify(this.title)
      },
      set(val) {
        return val
      },
    },
  },
  methods: {
    closeModal() {
      this.title = ''
      this.acronym = ''
      this.alert.type = null
      this.alert.message = null
      this.showModal = false
    },
    createItem() {
      this.alert.type = null
      this.alert.message = null
      this.isSaving = true;
      this.$apollo
        .mutate({
          mutation: UPSERT_MEMBERSHIP,
          variables: {
            input: {
              title: this.title,
              acronym: this.acronym,
              slug: this.slug,
            },
          },
        })
        .then(({ data: { upsertMembership } }) => {
          this.alert.type = 'success'
          this.alert.message = `Membership created with the name: ${upsertMembership.title}`
          this.title = ''
          this.acronym = '';
          this.isSaving = false;
          this.$emit('item-created')
          document.querySelector('#create-item-form input[type="text"]').focus()
        })
        .catch(error => {
          this.alert.type = 'error'
          this.isSaving = false;
          this.alert.message = error.message
        })
    },
  },
}
</script>