<template>
  <div>
    <multiselect
      v-if="!$apollo.loading"
      :value="selected"
      @select="onSelection"
      :options="allEntries"
      :multiple="false"
      :custom-label=" el => el.content.title "
      group-values="entries"
      group-label="source"
      :group-select="false"
      placeholder="Type to search"
      track-by="id"
    >
      <template #option slot-scope="{ option }">
        <span v-if="option.$groupLabel" class="option__title" v-html="option.$groupLabel"></span>
        <span v-else class="option__title" v-html="option.content.title"></span>
      </template>
      <template #noResult>Oops! No elements found. Consider changing the search query.</template>
    </multiselect>
  </div>
</template>

<script>
// Not sure if this is getting used anywhere. 
import { ALL_SITE_ENTRIES } from '@/graphql/entries.js'
import Multiselect from 'vue-multiselect'

export default {
  name: 'EntrySelect',
  props: ['field', 'type', 'siteID'],
  components: { Multiselect },
  data() {
    return {
      siteEntries: [],
      globalEntries: [],
    }
  },
  computed: {
    allEntries() {
      return [
        {
          source: 'Site Entries',
          entries: this.siteEntries,
        },
        {
          source: 'Global Entries',
          entries: this.globalEntries,
        },
      ]
    },
    selected() {
      let scope = this
      let selected = null
      this.allEntries.forEach(group => {
        selected = group.entries.filter(el => {
          console.log(el)
          return scope.field === el.id
        })
      })
      return selected
    },
  },
  apollo: {
    siteEntries: {
      query: ALL_SITE_ENTRIES,
      variables() {
        return {
          type: this.type ? this.type : null,
          site_id: this.siteID,
        }
      },
      update: data => data.entries,
    },
    globalEntries: {
      query: ALL_SITE_ENTRIES,
      variables() {
        return {
          type: this.type ? this.type : null,
          site_id: null,
        }
      },
      update: data => data.entries,
    },
  },
  methods: {
    onSelection(selectedOption) {
      this.$emit('update:field', selectedOption.id)
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
.multiselect input[type='text'] {
  border: 0;
  border-radius: 0;
}

#nav-bar {
  .multiselect {
    width: 25rem;
    min-height: 28px;
  }

  .multiselect__select {
    padding-top: 0;
    padding-bottom: 0;
    height: 28px;
  }

  .multiselect__tags {
    min-height: 28px;
    line-height: 28px;
    padding-top: 0;
  }

  .multiselect__placeholder {
    margin-bottom: 0;
    padding-top: 0;
  }

  .multiselect__single {
    padding-left: 0;
    margin-bottom: 0;
    line-height: 28px;
  }

  .multiselect__input {
    margin-bottom: 0;
    line-height: 28px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>