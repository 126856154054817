<template>
  <div v-show="block" class="settings-card settings-block">
    <div class="mb-4 flex items-center justify-between">
      <p class="text-xl font-bold mb-0">{{ block.blockName }}</p>
      <block-saver :block="block" @load-block="loadBlock" />
    </div>

    <div v-if="block.blockType != 'galleryItem'">
      <rw-text
        v-model="block.blockLabel"
        layout="inline"
        placeholder="Enter the admin label"
        label="Admin Label"
      />
      <rw-text
        v-if="block.blockBaseName"
        v-model="block.blockBaseName"
        disabled
        label="Base Block Name"
      />
      <rw-text
        v-if="block.blockBaseId"
        v-model="block.blockBaseId"
        disabled
        label="Base Block ID"
      />

      <div class="mt-2" v-if="block.blockBaseId">
        <label for="overrideBase">
          <input id="overrideBase" type="checkbox" v-model="block.override" class="mb-1" /> Override?
        </label>
      </div>
    </div>

    <hr class="divider" />
    
    <component
      :is="getType(block.blockType)"
      v-model="block"
      context="settings"
      v-if="showEditForm && block.blockType !== 'tipTap'"
    />
  </div>
</template>

<script>

export default {
  name: 'BlockSettings',
  mixins: [BlockMixin],
  computed: {
    showEditForm() {
      return !this.block.blockBaseId || this.block.override
    },
  },
  methods: {
    loadBlock(blockPreset) {
      console.log('BlockSettings.vue loadBlock')
      this.$emit('insert-preset', blockPreset)
      // this.block = blockPreset
    },
  },
}
</script>

<style scoped>
#overrideBase {
  vertical-align: 2px;
}
</style>