<template>
  <div>
    <p class="mt-2 mb-8">
      <strong>Heads up:</strong> This content will be shown in RW.com as "suggested content" for sharing. This does
      <em>not</em> effect what Facebook or Twitter show when someone shares a link to this entry. Use the SERP tab for that.
    </p>
    <div class="flex items-start mb-4 pb-4 border-b" v-for="(row, idx) in content" :key="idx">
      <div class="flex-1">
        <rw-select
          :disabled="!isEditable"
          :options="channelOptions"
          v-model="row.channel"
          label="Channel"
          class="w-1/4"
        />
        <template v-if="row.channel == 'email'">
          <div class="mt-2">
            <p class="font-bold m-0">Intro Text</p>
            <rw-tip-tap v-model="row.intro_text" class="border my-2 mx-0" />
          </div>
          <div>
            <p class="font-bold m-0">Outro Text</p>
            <rw-tip-tap v-model="row.outro_text" class="border my-2 mx-0" />
          </div>
        </template>

        <template v-else>
          <rw-limited-text
            v-model="row.text"
            class="border p-1 my-2"
            :limit="getLimit(row.channel)"
          />
        </template>
        <rw-image v-model="row.image" :editable="isEditable" context="inline-preview" />
      </div>
      <button v-if="isEditable" class="ml-3 mt-4" @click.prevent="content.splice(idx, 1)">
        <fa-icon icon="minus-circle" />
      </button>
    </div>

    <div v-if="isEditable">
      <button class="btn mt-2" @click.prevent="addChannel">Add</button>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
export default {
  name: 'SocialContent',
  props: ['modelValue', 'editable'],
  data() {
    return {
      content: reactive(this.modelValue) || [],
      channelOptions: {
        default: 'Default',
        linkedIn: 'LinkedIn',
        twitter: 'Twitter',
        facebook: 'Facebook',
        instagram: 'Instagram',
        email: 'Email',
      },
    }
  },
  watch: {
    content: {
      deep: true,
      handler(newVal) {
        return this.$emit('update:modelValue', newVal)
      },
    },
  },
  computed: {
    isEditable() {
      return this.editable || this.editable == undefined ? true : false;
    },
  },
  methods: {
    addChannel() {
      this.content.push({
        channel: null,
        text: null,
        image: null,
        intro_text: {
          htmlContent: null,
          jsonContent: null,
        },
        outro_text: {
          htmlContent: null,
          jsonContent: null,
        },
      })
    },
    getLimit(channel) {
      let limits = {
        instagram: 425,
      }
      return limits[channel] ? limits[channel] : 300
    },
  },
}
</script>

<style>
</style>