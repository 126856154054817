<template>
  <div>
    <button class="btn btn-icon border-dashed" @click.prevent="showModal = true">
      <fa-icon icon="plus" />
      {{ text }}
    </button>

    <div v-if="contentType == 'magazine'">
      <modal-dialog :show="showModal" @close="closeModal" size="medium">
        <template #header><h3>Magazine Issues</h3></template>
        <magazine-issues-table
          @selection-changed="selectedItems = $event"
          :selectionMode="insertMode"
          :selectable="true"
        />
        <template #footer>
          <button
            @click="insertItems('magazines')"
            class="btn btn-go"
            slot="footer"
          >{{ insertLabel }}</button>
        </template>
      </modal-dialog>
    </div>

    <div v-if="contentType == 'elements'">
      <modal-dialog :show="showModal" @close="closeModal" size="medium">
        <template #header><h3>Elements</h3></template>
        <element-picker-table
          @selection-changed="selectedItems = $event"
          :selectionMode="insertMode"
          :allowedTypes="allowedTypes"
        />
        <template #footer>
          <button
            @click="insertItems('elements')"
            class="btn btn-go"
          >{{ insertLabel }}</button>
        </template>
      </modal-dialog>
    </div>

    <div v-if="contentType == 'entries'">
      <modal-dialog :show="showModal" @close="closeModal" size="medium">
        <template #header><h3>Entries</h3></template>
        <entry-picker
          @selection-changed="selectedItems = $event"
          :selectable="true"
          :selectionMode="insertMode"
          :allowedTypes="allowedTypes"
        />
        <template #footer>
          <button @click="insertItems('entries')" class="btn btn-go">{{ insertLabel }}</button>
        </template>
      </modal-dialog>
    </div>
  </div>
</template>

<script>
import ModalDialog from '@/components/utils/ModalDialog'
import MagazineIssuesTable from '@/components/tables/MagazineIssuesTable'
import EntryPicker from '@/components/tables/EntryPicker'
import ElementPickerTable from '@/components/tables/ElementPickerTable'

import { CREATE_CONTENT_PACKAGE } from '@/graphql/content'

export default {
  name: 'ContentPicker',
  props: ['text', 'classes', 'contentType', 'btnLabel', 'mode', 'limitByType', 'allowedTypes'],
  components: { ModalDialog, MagazineIssuesTable, EntryPicker, ElementPickerTable },
  data() {
    return {
      selectedItems: [],
      showModal: false,
      insertLabel: this.btnLabel ? this.btnLabel : 'Insert Element',
      entryType: this.limitByType ? this.limitByType : '',
    }
  },
  computed: {
    insertMode() {
      return this.mode ? this.mode : 'single'
    },
  },
  methods: {
    insertItems(type) {
      this.$emit('insert-items', {
        type: type,
        data: this.selectedItems,
      })
      this.showModal = false
    },
    closeModal() {
      this.showModal = false
    },
  },
}
</script>