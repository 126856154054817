import gql from 'graphql-tag'

let PageDetails = gql`
  fragment PageDetails on Page {
    id
    site_id
    title
    slug
    anchor
    type
    section_type
    content_set_id
    content_set {
      hero_override
    }
    variant
    intro_title
    intro_text
    redirect_type
    redirects_to
    uri
    is_live
    enabled
    parent_id
    order
    uri
    show_in_menu
    created_at
  }
`

export const PAGE_FRAGMENT = PageDetails

let PageContentDetails = gql`
  fragment PageContentDetails on PageContent {
    id
    page_id
    status
    content
    user {
      email
      id
    }
    seo
    hero
    created_at
    updated_at
  }
`

export const PAGE_CONTENT_FRAGMENT = PageContentDetails

export const PAGE_LIST = gql`
  query pages($siteID: ID!, $orderBy: [OrderByClause!]) {
    pages(site_id: $siteID, orderBy: $orderBy) {
      ...PageDetails
    }
  }
  ${PageDetails}
`

export const CREATE_PAGE = gql`
  mutation CreatePage($input: CreatePageInput!) {
    createPage(input: $input) {
      ...PageDetails
    }
  }
  ${PageDetails}
`

export const UPDATE_PAGE = gql`
  mutation UpdatePage($input: UpdatePageInput!) {
    updatePage(input: $input) {
      ...PageDetails
      content {
        ...PageContentDetails
      }
      content_set {
        hero_override
      }
      versions {
        id
        created_at
        updated_at
      }
    }
  }
  ${PageDetails}
  ${PageContentDetails}
`

export const DELETE_PAGE = gql`
  mutation DeletePage($id: ID!) {
    deletePage(id: $id) {
      id
    }
  }
`

export const DELETE_PREVIEW = gql`
  mutation DeletePreview($id: ID!) {
    deletePreview(id: $id) {
      id
    }
  }
`

export const PAGE = gql`
  query Page($page_id: ID!) {
    page(id: $page_id) {
      ...PageDetails
      content {
        ...PageContentDetails
      }
      versions {
        id
        created_at
        updated_at
      }
    }
  }
  ${PageDetails}
  ${PageContentDetails}
`

export const PAGE_CONTENT = gql`
  query PageContent($version_id: ID!, $page_id: ID!) {
    pageContent(id: $version_id, page_id: $page_id) {
      ...PageContentDetails
    }
  }
  ${PageContentDetails}
`

export const PAGE_VERSIONS = gql`
  query PageVersions($page_id: ID!, $orderBy: [OrderByClause!]) {
    pageVersions(page_id: $page_id, orderBy: $orderBy) {
      id
      updated_at
      created_at
    }
  }
`

let PageTypeFragment = gql`
  fragment PageType on Page {
    id
    uri
    section_type
    title
    anchor
    enabled
  }
`

export const PAGE_TYPES = gql`
    query PageTypeBySite($site_id: ID, $type: String, $enabled: Boolean) {
        pageTypes(site_id: $site_id, type: $type, enabled: $enabled) {
            ...PageType
        }
    }
    ${PageTypeFragment}
`