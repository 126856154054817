<template>
  <div class="mb-4" v-if="context == 'preview'">
    <h2 class="h3" v-html="block.title"></h2>
    <p v-if="block.text" v-html="block.text"></p>

    <div v-show="block.children.length" class="flex flex-wrap -mx-4">
      <div class="w-1/3 px-4" v-for="(link, idx) in block.children" :key="idx">
        <div class="border border-t-4 p-4 mb-4" v-if="link.linkType == 'global'">
          <template v-if="link.linkId">
            <h3 class="h4 mt-0">{{ getLink(link).title }}</h3>
            <p>{{ getLink(link).text }}</p>
            <a href="#" v-if="getLink(link).linkLabel" v-html="getLink(link).linkLabel"></a>
          </template>
          <p v-else>Choose a global link.</p>
        </div>
        <div class="border border-t-4 p-4 mb-8" v-else>
          <h3>{{ link.title }}</h3>
          <p>{{ link.text }}</p>
          <div v-if="hasLink(link)">
            <a href="#" v-if="link.linkLabel" v-html="link.linkLabel"></a>
            <rw-link v-if="link.link" v-model="link.link" context="preview" />
          </div>
        </div>
        
      </div>
    </div>
  </div>

  <div v-else @click.stop>
    <rw-text v-model="block.title" label="Title" placeholder="Enter the title..." />
    <rw-textarea v-model="block.text" label="Text" placeholder="Enter the text..." />

    <draggable
      class="mt-4 mb-4"
      :list="block.children"
      :group="{ name: 'linkList', put: true }"
      handle=".item-handle"
      item-key="_id"
    >
      <template #item="{ element, index }">
        <div class="draggable-item mb-4 pb-4 flex items-start border-b border-gray-500 space-x-2">
          <fa-icon class="item-handle" icon="grip-vertical">Move</fa-icon>

          <div class="flex-1">
            <rw-select
              class="mb-2"
              v-model="block.children[index].linkType"
              :options="{'local': 'Local', 'global': 'Global'}"
              label="Link Type"
              layout="inline"
            />

            <div v-if="block.children[index].linkType == 'global'" class="field field-inline">
              <label>Global Link</label>
              <select v-model="block.children[index].linkId" class="w-auto">
                <optgroup v-for="(options, label) in linkOptions" :key="label" :label="label">
                  <option
                    v-for="(option, index) in options"
                    :key="index"
                    :value="option.linkId"
                  >{{ option.title }}</option>
                </optgroup>
              </select>
            </div>

            <div v-else class="space-y-2">
              <rw-text v-model="block.children[index].title" label="Title" />
              <rw-text v-model="block.children[index].text" label="Description" />
              <rw-link
                v-model="block.children[index].link"
                @update:value="updateLink($event, index)"
                context="settings"
              />
            </div>
          </div>
          <button @click.stop="removeChild(index)">
            <fa-icon icon="minus-circle">Trash</fa-icon>
          </button>
        </div>
      </template>

      <template #footer>
        <button class="btn btn-icon" @click="addItem">
          <fa-icon icon="plus" />Add Link
        </button>
    </template>
    </draggable>
  </div>
</template>

<script>
let uniqid = require('uniqid');

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

const groupByLinkGroup = groupBy('linkGroup')

import { link } from '@/settings/blocks'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'LinkList',
  mixins: [BlockMixin],
  data() {
    return {
      links: require('@/settings/globalLinks'),
    }
  },
  computed: {
    linkOptions() {
      return groupByLinkGroup(this.links)
    },
  },
  mounted() {
    // Vue 3 draggable requires an item-key and won't allow the index to be it. 
    // This will help the move not jumble the data and assign a uuid to each block.
    // This will make it where existing blocks work.
    this.block.children = this.block.children.map((child) => {
      return Object.assign({}, child, {_id: uniqid()});
    })
  },
  methods: {
    hasLink(link) {
      if ((link.link.type == 'custom' || link.link.type == 'download') && link.link.url != null) {
        return true;
      } else if (link.link.type == 'entry' && link.link.entryId != null) {
        return true;
      }

      return false;
    },
    getLink(link) {
      let match = this.links.find(item => item.linkId == link.linkId)
      return match
    },
    addItem() {
      let linkObj = { _id: uniqid(), linkType: 'local', linkId: null, title: null, text: null, link: cloneDeep(link) }
      this.block.children.push(linkObj)
    },
    updateLink($event, idx) {
      this.block.children[idx].link = $event;
    },

  },
}
</script>