<template>
  <div>
    <div class="flex -mx-4 mb-6">
      <div class="w-1/2 px-4">
        <label>Filter by title</label>
        <input 
          type="text" 
          class="w-full" 
          v-model="filters.title.value" 
          placeholder="Title..." />
      </div>

      <div class="w-1/4 px-4">
        <label>Filter by type</label>
        <select v-model="filters.type.value" class="w-full">
          <option value>All types</option>
          <option v-for="(name, value) in entryTypes" :key="value" :value="value">{{ name }}</option>
        </select>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <div>
        <apollo-loading v-if="isLoading" />
        <div v-else> 
          <VTable
            class="bare striped smart-table"
            :data="entries"
            ref="smarty"
            :selectionMode="selectionMode"
            @stateChanged="selectionChanged"
          >
            <template #head>
              <th></th>
              <th>Title</th>
              <VTh sortKey="type">Type</VTh>
              <VTh sortKey="created_at">Created</VTh>
              <VTh sortKey="published_at">Published</VTh>
              <th v-if="viewable || deletable"></th>
            </template>
            <template #body="{ rows }">
              <VTr
                v-for="entry in rows"
                :key="entry.id"
                :class="{ 'selectable' : selectable }"
                :row="entry"
              >
                <td class="pl-1 pr-0 w-1">
                  <span class="status-dot" :class="[ entry.content.status ]"></span>
                </td>
                <td>
                  {{ entry.content.title }}
                  <template v-if="entry.admin_note">
                    <br />
                    <span class="text-sm italic">{{entry.admin_note}}</span>
                  </template>
                </td>
                <td>{{ entry.type }}</td>
                <td>{{ formatDate(entry.created_at) }}</td>
                <td>{{ formatDate(entry.published_at) }}</td>
                <td
                  v-if="viewable || deletable"
                  class="whitespace-no-wrap w-1 space-x-2"
                >
                  <router-link
                    v-if="viewable"
                    class="btn btn-go btn-sm"
                    :to="editLink(entry.id)"
                  >{{ editable ? 'Edit' : 'View' }}</router-link>
                  <delete-item
                    v-if="deletable"
                    :mutation="deleteItem"
                    :id="entry.id"
                    @item-deleted="refreshTable"
                  />
                </td>
              </VTr>
            </template>
          </VTable>
          <VTPagination
            v-model:currentPage="selectedPage"
            :totalPages="lastPageNumber"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ALL_SITE_ENTRIES, DELETE_ENTRY } from '@/graphql/entries'
import { entryTypes } from '@/settings/enums'


export default {
  name: 'EntriesTable',
  props: {
    siteID: String,
    scope: {
      type: String,
      default: 'global',
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectionMode: {
      type: String,
      default: 'single',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    viewable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      entryTypes,
      deleteItem: DELETE_ENTRY,
      entries: [],
      firstRun: true,
      selectedPage: 1,
      lastPageNumber: 1,
      total: 0,
      isLoading: true,
      orderBy: { field: 'PUBLISHED_AT', order: 'DESC' },
      filters: {
        title: { value: '', keys: ['content.title'] },
        type: { value: this.allowedTypes || null, keys: ['type'] },
        status: { value: '', keys: ['content.status'] },
      },
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.selectedPage = 1;
        this.isLoading = true;
      },
      deep: true,
    },
    selectedPage: {
      handler: function() {
        this.fetchEntries();
      },
    },
  },
  mounted() {
    this.fetchEntries();
  },
  methods: {
    reSort() {
      const table = this.$refs.smarty;
      
      // No ordering!
      if (table.state.sortOrder == 0) {
        this.orderBy = null;
      } else {
        let newOrder = {};

        if (table.state.sortOrder == 1) {
          newOrder.order = 'ASC';
        } else if (table.state.sortOrder == -1) {
          newOrder.order = 'DESC';
        }
        newOrder.field = table.state.sortKey.toUpperCase();

        this.orderBy = newOrder;
      }

      this.fetchEntries();
    },
    fetchEntries() {
      this.isLoading = true;
      this.$apollo.addSmartQuery('entries', {
        query: ALL_SITE_ENTRIES,
        variables() {
          let input = {
            site_id: this.siteID ? this.siteID : null,
            first: 25,
            page: this.selectedPage,
            title: null,
          };
          
          if (this.filters.type.value) {
            input.type = this.filters.type.value;
          }

          if (this.filters.title.value) {
            input.title = `%${this.filters.title.value}%`;
          }

          if (this.orderBy) {
            input.orderBy = [this.orderBy];
          }

          return input;
        },
        result({ data: {entries} }) {
          this.entries = entries.data;
          this.total = entries.paginatorInfo.total;
          this.lastPageNumber = entries.paginatorInfo.lastPage;
          this.isLoading = false;
        },
      });
    },
    refreshTable() {
      this.$apollo.queries.entries.refetch()
    },
    editLink(id) {
      if (this.$route.params.siteID) {
        return { name: 'SiteEntryEdit', params: { siteID: this.siteID, entryID: id } }
      }

      return { name: 'EntryEdit', params: { entryID: id } }
    },
    selectionChanged($event) {
      if (this.firstRun) {
        this.firstRun = false
        return
      }
      this.$emit('selection-changed', $event.selectedRows)
    },
  },
}
</script>