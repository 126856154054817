<template>
  <div v-if="context == 'preview'">
    <div v-if="block.variant == 'ratings'" class="text-center">
      <h2 class="sub-heading">{{ block.title }}</h2>
      <p>{{ block.subTitle }}</p>
      <div class="mb-4">
        <fa-icon icon="star" />
        <fa-icon icon="star" />
        <fa-icon icon="star" />
        <fa-icon icon="star" />
        <fa-icon icon="star" />
      </div>
      <p>
        <a class="btn" href="#">{{ block.btnLabel }}</a>
      </p>
    </div>
    <div v-if="block.variant == 'testimonials'" class="placeholder" data-placeholder="Testimonials">
      <img src="/images/gradeus-reviews.png" alt="Example reviews" />
    </div>
  </div>

  <div v-else>
    <rw-text
      v-model="block.gradeUsKey"
      placeholder="Enter the url key"
      label="URL Key"
      help="Grade.us uses a URL to load the data. We only want the part after https://grade.us/"
    />
    <rw-select class="mb-2" v-model="block.variant" label="Output Type" :options="variants" />

    <div v-if="block.variant == 'ratings'">
      <rw-text v-model="block.title" placeholder="Title" label="Title" />
      <rw-text v-model="block.subTitle" placeholder="Sub Title" label="Sub Title" />
      <rw-text v-model="block.btnLabel" placeholder="Button label" label="Button label" />
    </div>

    <div v-if="block.variant == 'testimonials'">
      <rw-text
        v-model="block.reviewLimit"
        placeholder="Review Limit"
        label="Review Limit"
        help="Enter a number. Like: 10"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GradeUs',
  mixins: [BlockMixin],
  data() {
    return {
      variants: {
        ratings: 'Review/Ratings',
        testimonials: 'Testimonials',
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.placeholder::before {
  content: attr(data-placeholder) '\APlaceholder';
}
</style>