import { createApp, h, reactive } from 'vue';
import App from './App.vue';
import router from './routes';
import PortalVue, { createWormhole } from 'portal-vue';
import SmartTable from 'vuejs-smart-table';
import VuePlyr from 'vue-plyr';
import EventBus from './bootstrap/EventBus'
import { createProvider, authenticatedUser, onLogout, getUserPermissions, AUTH_TOKEN } from './vue-apollo'
import { setupApi } from './bootstrap/api'

// require('./bootstrap/builder')
// require('./bootstrap/event-bus')

require('./bootstrap/websockets')

// Todo: in the future and less exhausted Unicorn lady:
// Some of these chunks can be moved back to imported files. When I finally got to this
// point, there was no easy way/documentation to import with the App. Thanks to Stack, 
// someone gave me an idea but I already yanked some imports in here. 
// Thanks for understanding, Magical Unicorn Lady

const apolloProvider = createProvider()

const app = createApp({
  render: () => h(App),
})

setupApi(app);

// Me = rootworks user. Acoren_user = AcoreN user entry.
const permissions = {
  me: null,
  acoren_user: null,
}

app.config.globalProperties.$permissions = permissions
app.config.globalProperties.$globals = reactive({ site: {} });

app.config.globalProperties.$can = function(page, action = 'view') {
  // The ME QUERY hasn't run yet. Just accept. 
  if (this.$permissions.acoren_user == null) return true;

  return this.$permissions.acoren_user.permissions.includes(`${page}.${action}`);
}

const loadScript = function (id, uri) {
  return new Promise((resolve, reject) => {
    if (!document.getElementById(id)) {
      const tag = document.createElement('script')
      tag.setAttribute('id', id)
      tag.src = uri
      tag.async = true
      tag.onload = () => {
        resolve()
      }
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    }
  })
}

const Cloudinary = {
  credentials: {},

  async install(app, options) {
      let scope = this
      let bus = app;
      app.config.globalProperties.$cloudinary = bus

      scope.credentials = await this.fetchCredentials()

      await this.loadCloudinary().then(function () {
          window.ml = cloudinary.createMediaLibrary(scope.credentials, {
              insertHandler: function (data) {
                  let asset = data.assets[0]
                  console.log('Cloudinary.js insertHandler', asset)

                  EventBus.emit('asset-chosen', { asset: asset, uid: window.ml.field })
              },
          })
          window.mlExpires = Date.now() + 60 * 60 * 1000
      })

  },

  active() {
      return window.mlExpires && window.mlExpires > Date.now()
  },

  async fetchCredentials() {
    return await app.config.globalProperties.$api.post('/cloudinary/fetch-credentials').then(response => {
        return response.data.credentials
    }).catch(function (error) {
        console.error(error);
    });
  },

  async loadCloudinary() {
      return loadScript(
          'cloudinary-script',
          '//media-library.cloudinary.com/global/all.js'
      )
  },
}

import '@/assets/css/tailwind.scss'
import 'vue-select/dist/vue-select.css'
import PageBreadcrumbs from '@/components/utils/PageBreadcrumbs';
import ModalDialog from '@/components/utils/ModalDialog';
import Popper from "vue3-popper";

import axios from 'axios'
import { setupComponents } from '@/bootstrap/components'
import { setupToast } from '@/bootstrap/notifications'
import { setupIcons } from '@/bootstrap/fonts'
import { setupMixins } from '@/bootstrap/mixins'
import { setupBlocks } from '@/bootstrap/blocks'
import { setupBuilder } from '@/bootstrap/builder'

app.component('page-breadcrumbs', PageBreadcrumbs);
app.component('modal-dialog', ModalDialog);
app.component('Popper', Popper);

app.use(router);
app.use(apolloProvider);
app.use(PortalVue, {
  wormhole: createWormhole()
})

app.use(SmartTable)
app.use(VuePlyr, {
  plyr: {}
})

app.use(Cloudinary);

setupComponents(app);
setupIcons(app);
setupToast(app);
setupMixins(app);
setupBlocks(app);
setupBuilder(app);

router.beforeEach(async (to, from, next) => {
  let user;
  let permissions;
  
  try {
    user = await authenticatedUser(apolloProvider.defaultClient);
  } catch (exp) {
    onLogout(apolloProvider.defaultClient);
  }

  // Clears all the read only warnings. 
  app.config.globalProperties.$toasted.clear();

  // Double negative? It's easier to declare public routes vs. everything else
  const requiresAuth = !to.matched.some(record => record.meta.public)
  if (!user && requiresAuth) {
    return router.push({ name: `login` });
  }

  // Public pages be open!!
  if (!requiresAuth) {
    next()
  }
  
  permissions = await getUserPermissions(apolloProvider.defaultClient, user.email);
  console.log(`Seeking permissions to: ${to.name}`)

  if (!permissions.permissions.includes(to.name+'.view') && requiresAuth) {
    console.log(`Denied permission to: ${to.name}`)
    return router.push({ name: `forbidden` });
  } else {
    // Throw the read only if they don't have update permissions.
    const check = permissions.permissions.includes(to.name+'.update') || to.name == 'forbidden' ? true : false;
    if (!check) {
      app.config.globalProperties.$toasted.error("You only have read only permissions for this page.")
    }
    next();
  }

})

app.mount('#app');