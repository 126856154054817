<template>
  <div>
    <draggable :list="localVal" item-key="block_id" :group="{ name: 'channels' }" handle=".channel-handle">
      <template #item="{ element, index }">
        <div class="flex items-start mb-4 pb-4 border-b">
          <fa-icon class="channel-handle mr-4 mt-1" v-if="editable" icon="grip-vertical">Move</fa-icon>
          <div class="flex flex-1 mr-2">
            <div class="w-1/2 mr-4 space-y-2">
              <rw-select v-model="element.channel" label="Channel" :options="channelOptions" />
              <rw-text v-model="element.url" label="URL" placeholder="Enter the url..." />
              <rw-text v-model="element.label" label="Label" placeholder="Enter the label..." />
            </div>
          </div>
          <button class="ml-2" v-if="editable" @click.stop="localVal.splice(index, 1)">
            <fa-icon icon="minus-circle">Trash</fa-icon>
          </button>
        </div>
      </template>
    </draggable>
    <button class="btn" v-if="editable" @click="addChannel">Add Social Media Channel</button>
  </div>
</template>

<script>
import makeUnique from '@/components/mixins/makeUnique'

export default {
  name: 'SocialMedia',
  mixins: [makeUnique],
  props: ['modelValue', 'editable'],
  data() {
    return {
      localVal: this.modelValue || [],
      channelOptions: {
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
        tiktok: 'TikTok',
        twitter: 'Twitter',
        youtube: 'YouTube',
        yelp: 'Yelp',
      },
    }
  },
  watch: {
    localVal: {
      deep: true,
      handler(newVal) {
        this.$emit('update:modelValue', newVal)
      },
    },
  },
  methods: {
    addChannel() {
      let channel = {
        channel: '',
        url: '',
        label: '',
      }
      this.localVal.push(Object.assign({}, channel))
      this.makeUnique(this.localVal, 'block_id')
    },
  },
}
</script>