<template>
  <div v-if="context == 'preview' " class="relative">
    <h2>This block is deprecated. Use page settings instead.</h2>
  </div>

  <div v-else>
    <p class="font-bold mt-4">Product Settings</p>
  </div>
</template>

<script>
export default {
  name: 'Products',
  mixins: [BlockMixin],
}
</script>