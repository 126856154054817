<template>
  <div v-if="canCreate">
    <button :class="btnClass" @click="showModal = true">{{btnText}}</button>
    <modal-dialog :show="showModal" @close="closeModal">
      <template #header><h3 slot="header">Create Magazine Issue</h3></template>

      <alert-message v-bind="alert" />

      <form ref="create-item-form" @keyup.enter="createIssue" class="space-y-2">
        <rw-text
          v-model="title"
          label="Issue Title"
          placeholder="Ex: September - October, 2019"
        />
        <rw-text
          v-model="slug"
          label="Slug"
          placeholder="Ex: 2019-09-10"
          @keyup.enter.prevent="createIssue"
        />
        <rw-select :options="magazineTypes" v-model="type" label="Magazine type" />
      </form>
      <template #footer>template>
        <div class="flex justify-end">
          <button class="btn btn-go" @click.prevent="createIssue">Add Issue</button>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from '@/components/utils/ModalDialog'
import { CREATE_MAGAZINE_ISSUE } from '@/graphql/magazines'
import { magazineTypes } from '@/settings/enums'

export default {
  name: 'MagazineCreateIssue',
  props: {
    btnText: {
      type: String,
      default: 'Create Magazine Issue',
    },
    btnClass: {
      type: String,
      default: 'btn btn-go btn-sm',
    },
    issueType: {
      type: String,
      default: 'in-the-loop',
    },
    canCreate: {
      type: Boolean,
      default: false,
    }
  },
  components: { ModalDialog },
  data() {
    return {
      magazineTypes,
      showModal: false,
      title: '',
      slug: '',
      type: this.issueType,
      alert: {
        type: null,
        message: null,
      },
    }
  },
  methods: {
    reset() {
      this.title = ''
      this.slug = ''
    },
    closeModal() {
      this.alert.type = null
      this.alert.message = null
      this.reset()
      this.showModal = false
    },
    createIssue() {
      this.alert.type = null
      this.alert.message = null
      this.$apollo
        .mutate({
          mutation: CREATE_MAGAZINE_ISSUE,
          variables: {
            input: {
              title: this.title,
              slug: this.slug,
              type: this.type,
            },
          },
        })
        .then(({ data: { createMagazineIssue } }) => {
          this.alert.type = 'success'
          this.alert.message = `Issue created with the title: ${createMagazineIssue.title}`
          this.reset()
          this.$refs['create-item-form'].querySelector('input[type="text"]').focus()
          this.$emit('item-created')
        })
        .catch(error => {
          this.alert.type = 'error'
          this.alert.message = error.message
        })
    },
  },
}
</script>

<style lang="scss">
</style>