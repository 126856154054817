<template>
  <div v-if="context == 'preview'" class="gallery-item text-center">
    <cld-image
      class="mb-4"
      v-if="block.image && block.image.cloudinaryId"
      :publicId="block.image.cloudinaryId"
    >
      <cld-transformation width="384" />
    </cld-image>
    <h2 class="h4 mb-0">{{ block.title}}</h2>
    <p>{{block.text}}</p>
  </div>

  <div v-else>
    <div class="mb-2">
      <rw-image v-model="block.image" context="settings" />
    </div>

    <rw-text v-model="block.title" placeholder="Name" label="Name" />
    <rw-text v-model="block.text" placeholder="Role" label="Role" />

    <light-switch 
      v-model="block.includeLink" 
      class="my-4">
      <template #label>Link image?</template>
    </light-switch>
    <rw-link
      v-show="block.includeLink"
      v-model="block.link"
      label="Link to Page?"
      context="settings"
    />
  </div>
</template>

<script>
export default {
  name: 'GalleryItem',
  mixins: [BlockMixin],
}
</script>