export default {
  Mutation: {
    setConnectedMutation: (root, { value }, { cache }) => {
      const data = {
        connected: value
      }
      cache.writeData({ data })
    },
    // ... more resolver mutations
  }
}