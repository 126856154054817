<template>
  <div v-if="context == 'preview'">
    <anchored-heading :level="block.level" class="heading">
      <template #default>{{ block.text }}</template>
    </anchored-heading>
  </div>
  <div v-else>
    <div v-if="block.blockType == 'heading'" class="flex">
      <select name="headingLevel" v-model="block.level" class="mr-2 h-auto">
        <option value="h2">H2</option>
        <option value="h3">H3</option>
        <option value="h4">H4</option>
      </select>
      <input type="text" v-model="block.text" class="w-full" />
    </div>
  </div>
</template>

<script>
import AnchoredHeading from '@/components/utils/AnchoredHeading'

export default {
  name: 'Heading',
  components: {AnchoredHeading},
  mixins: [BlockMixin],
}
</script>