<template>
  <div v-if="!$apollo.loading">
    <div class="flex -mx-4 mb-8">
      <div class="w-1/2 px-4 mb-2">
        <label class="block">Filter elements by title:</label>
        <input class="border p-1 w-full" v-model="filters.title.value" />
      </div>

      <div class="w-1/2 px-4" v-if="!allowedTypes">
        <label class="block">Filter by Type:</label>
        <select v-model="filters.type.value" class="capitalize">
          <option :value="null">All</option>
          <option v-for="(name, val) in elementTypes" :key="val" :value="val">{{ name }}</option>
        </select>
      </div>
    </div>

    <div class="tab-controls mb-4" v-if="$route.params.siteID">
      <button
        :class="{ 'active': activeTab == 'siteContent' }"
        @click="activeTab = 'siteContent'"
      >Site Content</button>
      <button
        :class="{ 'active': activeTab == 'globalContent' }"
        @click="activeTab = 'globalContent'"
      >Global Content</button>
    </div>

    <elements-table
      v-if="$route.params.siteID && activeTab == 'siteContent'"
      scope="site"
      :siteID="$route.params.siteID"
      :filters="filters"
      :selectable="true"
      :selectionMode="selectionMode"
      :editable="$can('SiteElementEdit', 'update')"
      :viewable="$can('SiteElementEdit')"
      :deleteable="$can('SiteElementEdit', 'delete')"
      @selection-changed="$emit('selection-changed', $event)"
    />

    <!-- Use v-if so that we can force Vue to load this component -->
    <elements-table
      v-if="activeTab == 'globalContent'"
      scope="global"
      :filters="filters"
      :selectable="true"
      :selectionMode="selectionMode"
      :editable="$can('ElementEdit', 'update')"
      :viewable="$can('ElementEdit')"
      :deleteable="$can('ElementEdit', 'delete')"
      @selection-changed="$emit('selection-changed', $event)"
    />
  </div>
</template>

<script>
import ElementsTable from '@/components/tables/ElementsTable'
import { elementTypes } from '@/settings/enums'

export default {
  name: 'ElementPickerTable',
  props: ['selectionMode', 'allowedTypes'],
  components: { ElementsTable },
  data() {
    return {
      elementTypes,
      activeTab: 'globalContent',
      filters: {
        title: { value: '', keys: ['content.title'] },
        type: { value: this.allowedTypes || null, keys: ['type'] },
      },
    }
  },
  methods: {
    itemSelected($event) {
      console.log($event)
    },
  },
}
</script>

<style scoped>
.vt-selected {
  background-color: theme('colors.gray.600') !important;
  color: #fff;
}
</style>