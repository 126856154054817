<template>
  <button @click="runAction" :disabled="loading">
    <span class="flex items-center">
      {{btnText}}
      <spinner v-if="loading" class="ml-2" />
    </span>
  </button>
</template>

<script>
export default {
  name: "ActionTemplate",
  props: ["text", "siteID"],
  data() {
    return {
      loading: false,
      btnText: this.text
    };
  },
  created() {
    let self = this;
    Echo.private("site." + this.siteID)
      .listenForWhisper("publishing", evt => {
        self.loading = true;
      })
      .listen(".update.success", evt => {
        self.loading = false
      })
      .listen(".update.failed", evt => {
        self.loading = false
      });
  },
  methods: {
    runAction() {
      this.loading = true;
      Echo.private("site." + this.siteID).whisper("publishing");

      this.$api.post("", {})
        .then( response => {
          console.log("Publish button clicked");
        })
        .catch( error => {
          console.log(error);
        });
    }
  }
};
</script>