<template>
  <div v-if="context == 'preview'">
    <h2>{{block.title}}</h2>
    <div v-for="(child, idx) in block.children" :key="idx" class="mb-4">
      <h3 class="h3 font-bold" v-html="child.title"></h3>
      <p>{{child.text}}</p>
    </div>
  </div>

  <div v-else>
    <rw-text class="mb-4" v-model="block.title" label="Title" placeholder="Enter a title..." />
    <draggable
      :list="block.children"
      :group="{ name: 'contentListItems', put: ['contentListItems'] }"
      handle=".content-list-item"
      item-key="_id"
    >
      <template #item="{ element, index }">
        <div
          class="mb-2 pb-2 items-start list-item dashed-border"
          :key="element._id"
          :value="element"
        >
          <div class="flex justify-end items-center">
            <fa-icon class="content-list-item ml-4" icon="grip-vertical" />
            <button @click.stop="removeChild(index)" class="ml-2">
              <fa-icon icon="trash-alt" />
            </button>
          </div>
          <div class="flex-1 flex-grow">
            <rw-text v-model="child.title" placeholder="Enter the title" label="Title"></rw-text>
            <rw-textarea v-model="child.text" placeholder="Enter the description" label="Text"></rw-textarea>
          </div>
        </div>
      </template>
    </draggable>

    <button class="btn btn-icon" @click="addListItem">
      <fa-icon icon="plus" class="mr-2 text-sm" />Add List Item
    </button>
  </div>
</template>

<script>
export default {
  name: 'ContentList',
  mixins: [BlockMixin],
  data() {
    return {
      block: this.modelValue,
    }
  },
  methods: {
    addListItem() {
      const newContent = {
        title: '',
        text: '',
      }

      this.block.children.push(Object.assign({}, newContent))
    },
  },
}
</script>