<template>
  <div>
    <draggable 
      :list="contact" 
      :group="{ name: 'contactMethods' }" 
      item-key="_id"
      handle=".item-handle">
      <template #item="{ element, index }">
        <div class="flex items-center mb-4 pb-4 border-b">
          <fa-icon class="item-handle mr-4 mt-1" v-if="editable" icon="grip-vertical">Move</fa-icon>
          <div class="flex items-center space-x-2">
            <rw-select v-model="element.type" :options="contactOptions" label="Type" />
            <rw-text v-model="element.value" label="Value" />
          </div>
          <button class="ml-2" v-if="editable" @click.stop="contact.splice(index, 1)">
            <fa-icon icon="minus-circle">Trash</fa-icon>
          </button>
        </div>
      </template>
    </draggable>
    <button class="btn" v-if="editable" @click="addContactMethod">Add Contact Method</button>
  </div>
</template>

<script>
import makeUnique from '@/components/mixins/makeUnique'

export default {
  name: 'Contact',
  mixins: [makeUnique],
  props: ['modelValue', 'editable'],
  data() {
    return {
      contact: this.modelValue || [],
      contactOptions: { phone: 'Phone', email: 'Email', fax: 'Fax', text: 'Text' },
    }
  },
  watch: {
    contact: {
      deep: true,
      handler(newVal) {
        this.makeUnique(newVal)
        this.$emit('update:modelValue', newVal)
      },
    },
  },
  methods: {
    addContactMethod() {
      let contactMethod = { type: null, value: null }
      this.contact.push(Object.assign({}, contactMethod))
    },
  },
}
</script>

<style>
</style>