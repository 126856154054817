// Third party

// import ToggleButton from 'vue-js-toggle-button'
// Vue.use(ToggleButton)

// import vSelect from 'vue-select'
// app.component('v-select', vSelect)

// Import these renderless components that are '.js' files
import vSelect from 'vue-select'
import CloudinaryImage from '@/components/utils/CloudinaryImage'

const importedComponents = {
  actions: require.context('../components/actions/', true, /\.(vue)$/i),
  inputs: require.context('../components/inputs/', true, /\.(vue)$/i),
  fields: require.context('../components/fields/', true, /\.(vue)$/i),
  forms: require.context('../components/forms/', true, /\.(vue)$/i),
  utils: require.context('../components/utils/', true, /\.(vue)$/i),
}

export function setupComponents(app) {
  app.component('CloudinaryImage', CloudinaryImage);
  app.component('v-select', vSelect);
  
  Object.keys(importedComponents).forEach(name => {
    let imports = importedComponents[name]
  
    imports.keys().map(component => {
      let loadedComponent = imports(component).default
  
      if (name == 'inputs') {
        const blockName = component
          .match(/\w+/)[0]
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/\s+/g, '-')
          .toLowerCase()
          
        app.component('rw-' + blockName, loadedComponent)
      }
  
      app.component(loadedComponent.name, loadedComponent)
    })
  })
};
