import {h} from 'vue';

export default {
  name: 'AnchoredHeading',
  props: {
    level: {
      type: String,
      required: true,
    },
  },
  render() {
    return h(
      this.level, // tag name
      this.$slots.default() // array of children
    )
  },
}
