<template>
  <div class="settings-card">
    <p class="font-bold">Sections</p>
    <draggable
      class="block-list"
      :list="sections"
      :group="{ name: 'sectionBlocks', pull: 'clone', put: false }"
      :sort="false"
      item-key="index"
      :clone="cloneCallback"
    >
      <template #item="{ element, index }">
        <div
          class="list-item"
          :class="element.blockType"
          v-html="sidebarLabel(element)"
        ></div>
      </template>
    </draggable>

    <p class="font-bold mt-4">Containers</p>
    <draggable
      class="block-list"
      :list="containers"
      :group="{ name: 'container', pull: 'clone', put: false }"
      :sort="false"
      item-key="getKey"
      :clone="cloneCallback"
    >
      <template #item="{ element, index }">
        <div
          class="list-item"
          :class="element.blockType"
          :key="index"
          v-html="sidebarLabel(element)"
        ></div>
      </template>
    </draggable>

    <!-- <p class="font-bold mt-4">Section + Container Templates</p>
    <draggable
      class="block-list"
      :list="sectionTemplates"
      :group="{ name: 'sectionTemplates', pull: 'clone', put: false }"
      :sort="false"
      :clone="cloneCallback"
      item-key="index"
    >
      <template #item="{ element, index }">
        <div
          class="list-item"
          :class="element.blockType"
          v-html="sidebarLabel(element)"
        ></div>
      </template>
    </draggable> -->

    <p class="font-bold mt-4">Blocks</p>
    <draggable
      class="block-list"
      :list="blocks"
      :group="{ name: 'contentBlocks', pull: 'clone', put: false }"
      :sort="false"
      item-key="index"
      :clone="cloneCallback"
    >
      <template #item="{ element, index }">
        <div
          class="list-item flex"
          :class="element.blockType"
        >
          <fa-icon v-if="element.faIcon" :icon="element.faIcon" size="2x" class="m-2 mr-3" />
          <img v-if="element.blockIcon && !element.faIcon" :src="element.blockIcon" class="h-12 w-12 mr-2" />
          <div>
            <p class="font-bold mb-0" v-html="sidebarLabel(element)"></p>
            <p class="m-0" v-html="element.blockDescription"></p>
          </div>
        </div>
      </template>
    </draggable>

    <p class="font-bold mt-4">Custom Blocks</p>
    <draggable
      class="block-list"
      :list="customBlocks"
      :group="{ name: 'contentBlocks', pull: 'clone', put: false }"
      :sort="false"
      :clone="cloneCallback"
      item-key="idx"
    >
      <template #item="{ element, index }">
        <div
          class="list-item"
          :class="element.blockType"
          v-html="sidebarLabel(element)"
        ></div>
      </template>
    </draggable>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import * as settings from '@/settings/blocks'
let uniqid = require('uniqid')

export default {
  name: 'PageBlockPicker',
  props: ['modelValue'],
  data() {
    return {
      sections: settings.sections,
      containers: settings.containers,
      //sectionTemplates: settings.sectionTemplates,
      blocks: settings.blocks,
      customBlocks: settings.customBlocks,
    }
  },
  methods: {
    sidebarLabel(block) {
      return block.blockLabel ? `${block.blockName}: ${block.blockLabel}` : block.blockName
    },
    cloneCallback: function (block) {
      let newBlock = Object.assign({}, { _id: uniqid() }, cloneDeep(block))
      return newBlock
    },
  },
}
</script>

<style>
</style>